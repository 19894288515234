import {ButtonPrimary} from "../../../../../../Components/Button";
import React from "react";
import {COMMISSION_VALUE, PRICE_TOTAL, PRICE_VAT} from "../FinalCommissionValuesModal";
let AddNewLine = ({change, setCommissionItems, commissionItems}) => {

    let addNewLine = () => {
        let customCommissionItem = commissionItems.filter(item => item.type === 'custom').sort((a, b) => b.id - a.id)[0];
        if(typeof customCommissionItem != 'undefined' && customCommissionItem) {
            setCommissionItems([...commissionItems, {id: parseInt(customCommissionItem.id) + 1, disabled: [], type: 'custom', readonly: [PRICE_VAT, COMMISSION_VALUE, PRICE_TOTAL]}]);
        }else{
            setCommissionItems([...commissionItems, {id: commissionItems.length, disabled: [], type: 'custom', readonly: [PRICE_VAT, COMMISSION_VALUE, PRICE_TOTAL]}]);
        }
        change('type_custom_' + commissionItems.length, 'custom');
        change('applied_on_custom_' + commissionItems.length, 'Net');
        change('id_custom_' + commissionItems.length, commissionItems.length);
        change('vat_custom_' + commissionItems.length, 41);
        change('commission_value_vat_custom_' + commissionItems.length, 0);
    }

    return (
        <ButtonPrimary onClick={() => {addNewLine()}} classes={`bg-brand`}>
            {"Add New Line"}
        </ButtonPrimary>
    )
}

export default AddNewLine