import React, { useRef, Fragment, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { change, Field, getFormValues, reduxForm, FieldArray } from "redux-form";
import { Date as DateInput, Textarea, Toggle } from "Components/Form";
import { stage3 as v } from "./validate";
import RfpSubmit from "./RfpSubmit";
import Dropzone from "react-dropzone";
import { ButtonSpan } from "../../Components/Button";
import {
  companyObject,
  corporateAccountObject,
  hasActiveCorporateAccount,
  isCompanyUser,
  isRoleTrinity,
  isRoleTrinityEdgeRFP,
  isAgencyUser,
  isAgencyAdmin, isRolePlatformAdmin
} from "../../modules/permissions";
import AgencySpecificEnquiryData from "./AgencySpecificEnquiryData";
import CompanyFormModal from "../../Components/corporate/NewCompanyModal";
import {createCompany, createUser, fetchBookingDesks, fetchCompany, fetchSelectedAgencies} from "../../redux/actions";
import {
  isInternal,
  token,
  user,
  isAgency,
  isCorporate,
  isAgencyApproved,
  isSuperAgent,
  userAgencyObject
} from "../../modules/auth";
import MinimalUserModalForm from "../../Components/Users/MinimalUserModalForm";
import { fetchIndustries } from "../../modules/industries";
import classnames from "classnames";
import CorporateSpecificEnquiryData from "./CorporateSpecificEnquiryData";
import CorporateSpecificQuestions from "./CorporateSpecificQuestions";
import { ROCHE_COMPANY_CODE, USER_TYPES } from "../../constants";
import CompanyQuestions from "./CompanyQuestions";
import AuthorisationTriggeredBanner from "./AuthorisationTriggeredBanner";
import RepeatableSyndicateRoom from "../../Components/Rfp/RepeatableSyndicateRoom";
import useService from "../../hooks/useService";
import { getRoomStyles } from "../../services/rfpService";
import Loading from "../../Components/Loading";

const mapStateToProps = state => {
  const loadingStates = [
    state.corporateBookingDesks.isFetchingBookingDesks,
    state.company.isFetchingCompany,
    state.selectedAgencies.isFetchingSelectedAgencies,
  ];
  const loadingFromApi = loadingStates.some(state => state === true);
  return {
    ...state.industries,
    ...state.selectedAgencies,
    ...state.agencyBookingDesks,
    companyObject: companyObject(state.auth),
    isRoleTrinityEdgeRFP: isRoleTrinityEdgeRFP(state.auth),
    isSuperAgent: isSuperAgent(state),
    isAgencyUser: isAgencyUser(state.auth),
    isAgencyAdmin: isAgencyAdmin(state.auth),
    isAgency: isAgency(state),
    isCompanyUser: isCompanyUser(state),
    isRoleTrinity: isRoleTrinity(state.auth),
    isInternal: isInternal(state),
    isPlatformAdmin: isRolePlatformAdmin(state.auth),
    token: token(state),
    corporateAccount: corporateAccountObject(state.auth),
    hasActiveCorporateAccount: hasActiveCorporateAccount(state.auth),
    isCorporate: isCorporate(state),
    userAgency: state.auth.agency,
    userAgencyObject: userAgencyObject(state),
    isAgencyApproved: isAgencyApproved(state),
    loadingFromApi
  };
};

const EDGE_AGENCY_CODE = "edge";
const TRINITY_AGENCY_CODE = "trin";

const mapDispatchToProps = {
  change,
  createCompany,
  fetchCompany,
  createUser,
  fetchIndustries,
  fetchSelectedAgencies,
  fetchBookingDesks
};

const Stage3 = ({
                  form,
                  handleSubmit,
                  prevPage,
                  attachments,
                  setAttachments,
                  change,
                  isRoleTrinityEdgeRFP,
                  isSuperAgent,
                  isAgencyAdmin,
                  isAgencyUser,
                  token,
                  createCompany,
                  createUser,
                  industries,
                  fetchIndustries,
                  corporateAccount,
                  hasVenues,
                  editEnquiry,
                  setSubmitToAgent,
                  setEnquiryToSubmitted,
                  fetchCompany,
                  isInternal,
                  isAgency,
                  setSubmitToDraft,
                  enquiry,
                  hasActiveCorporateAccount,
                  isCompanyUser,
                  isPlatformAdmin,
                  company,
                  setCompany,
                  offerRfpXpress,
                  companyObject,
                  requiresAuthorising,
                  setRequiresAuthorising,
                  fetchSelectedAgencies,
                  selectedAgencies,
                  loadingFromApi,
                  userAgency,
                  isAgencyApproved,
                  userAgencyObject,
                  fetchBookingDesks,
                  agencyBookingDesks
                }) => {
  const values = useSelector(getFormValues(form));
  const maxDelegatesRef = useRef(null);
  const fileTypesString = ".xlsx,.xls,.csv,.jpg,.jpeg,.png,.bmp,.doc,.docx,.pdf,.txt,.eml,.msg";
  const [showNewCompany, setShowNewCompany] = useState(null);
  const [newCompanyModal, setNewCompanyModal] = useState(null);
  const [showNewUser, setShowNewUser] = useState(null);
  const [newUserModal, setNewUserModal] = useState(null);
  const [clientName, setClientName] = useState(values?.client);
  const [shouldClearSearches, setShouldClearSearches] = useState(false);
  const [suppressUserNotifications, setSuppressUserNotifications] = useState(false);
  const [showNulledMaxDelegates, setShowNulledMaxDelegates] = useState(false);
  const [agencyCode, setAgencyCode] = useState(null);
  const [agency, setAgency] = useState(userAgencyObject);
  const [enquiryTypes, setEnquiryTypes] = useState([]);
  const [awaitingRequests, setAwaitingRequests] = useState(loadingFromApi);
  const [fetchAccommodationOnly, setFetchAccommodationOnly] = useState({ fetchAccommodationOnly: true });
  let allowedAgencySpecificUserTypes = [
    USER_TYPES.CORPORATE,
    USER_TYPES.AGENCY,
    USER_TYPES.VENUE,
  ];

  useEffect(() => {
    setAwaitingRequests(loadingFromApi);
  }, [loadingFromApi]);

  useEffect(() => {
    if (values && values.maximum_number_of_delegates && company && company.code === ROCHE_COMPANY_CODE) {
      maxDelegatesRef.current = values.maximum_number_of_delegates;
      change(form, 'maximum_number_of_delegates', null);
      setShowNulledMaxDelegates(true);
    } else if (company && company.code !== ROCHE_COMPANY_CODE) {
      if (maxDelegatesRef.current !== null) {
        change(form, 'maximum_number_of_delegates', maxDelegatesRef.current); // Revert to the saved value
      }
      setShowNulledMaxDelegates(false);
    }
  }, [company])

  const { loading: roomStylesLoading, data: roomStyleOptions, error } = useService({
    service: getRoomStyles,
    trigger: true,
    params: fetchAccommodationOnly,
  });
  useEffect(() => {
    fetchIndustries(token);
    fetchBookingDesks(userAgencyObject.id, token)
    if (values.date_decision_estimate) {
      change(form, "date_decision", 1);
    }
    if (isSuperAgent) {
      fetchSelectedAgencies(token, !editEnquiry);
    }
    if (values.company_id || companyObject) {
      let companyId = values.company_id || companyObject && companyObject.id;
      if (companyId) {
        fetchCompany(companyId, token, {minimal: true}).then(response => {
          if (response.data) {
            setCompany(response.data.data);
          }
        });
      }
    }
  }, []);

  useEffect(() => {
    if (isSuperAgent) {
      let enquiryTypesArray = [];
      selectedAgencies.length > 0 && selectedAgencies.map(superAgency => {
        enquiryTypesArray.push({
          "name": superAgency.name,
          "code": superAgency.id,
          "type": "is_" + superAgency.id + "_enquiry",
        });
      });
      setEnquiryTypes(enquiryTypesArray);
    }
  }, [selectedAgencies]);

  useEffect(() => {
    if (!editEnquiry) { // can't change company when editing enquiry
      change(form, "booking_desk_id", null);
      if (company) {
        change("company_id", company.id);
        if (values.company_id) {
          change(form, "agency_booking_desk_id", company.default_booking_desk_id);
        }
        // if a company has a sso_method default the suppress_notification checkboxes to true
        if (company.sso_method !== null) {
          setSuppressUserNotifications(true);
        }
      }
    }
  }, [company, values.company_id]);

  useEffect(() => {
    if ( (isAgencyUser || isAgencyAdmin) && company) {
      if (company.default_booking_desk_id == values.agency_booking_desk_id) {
        change(form, "booking_desk_id", values.agency_booking_desk_id);
      }
    }
  }, [isAgencyAdmin, isAgencyUser, company]);

  useEffect(() => {
    if (!values.syndicate_room) {
      change(form, "syndicate_room_notes", null);
      change(form, "syndicate_rooms", null);
    } else if (values.syndicate_room && editEnquiry) {
      change(form, "syndicate_rooms", enquiry.syndicate_rooms);
    }
    if (!values.exhibition_space) {
      change(form, "exhibition_space_notes", null);
    }
    if (!values.any_equipment) {
      change(form, "any_equipment_notes", null);
    }
  }, [
    values.syndicate_room,
    values.exhibition_space,
    values.any_equipment,
    values.private_dining,
  ]);

  useEffect(() => {
    if (values.super_agency_code === null &&  userAgency && !editEnquiry) {
      change(form, "super_agency_code", userAgency.id);
      setAgencyCode(userAgency.id);
    }
  }, [(values.super_agency_code === null)]);

  const handleDrop = async files => {
    const file = files[0];
    if (!file) {
      alert("Please ensure you have selected a file from the following types: " + fileTypesString);
      return;
    }
    if (file.size > 10 * 1024 * 1024) {
      alert("File must be under 10MB");
      return;
    }
    setAttachments(oldFiles => ([...oldFiles, ...files]));
  };

  const handleDelete = async attachment_name => {
    try {
      setAttachments(oldFiles => ([...oldFiles.filter(file => file.name !== attachment_name)]));
    } catch (err) {
      alert("Sorry there was a problem deleting the attachment");
    }
  };

  const onEnquiryTypeToggleChange = (event) => {
    setCompany(null);
    setClientName(null);
    setShouldClearSearches(true);
    change(form, "super_agency_code", event.code);
    setAgencyCode(event.code);
    fetchBookingDesks(event.code, token)
    setAgency(event)
    enquiryTypes.forEach(enquiryType => {
      if (enquiryType.type !== event.type) {
        change(form, enquiryType.type, 0);
      }
    });
  };

  const handleCreateCompany = formValues => {
    return createCompany(formValues, token).then(response => {
      setNewCompanyModal(false);
      setShowNewCompany(false);
      alert("The company was created successfully. Please search for it by name to associate it with your enquiry.");
    });
  };

  const handleCreateUser = formValues => {
    formValues.append("user_type_id", 1);
    formValues.append("company_id", company.id);
    return createUser(token, formValues).then(() => {
      setNewUserModal(false);
      setShowNewUser(false);
    });
  };

  const addCompany = () => {
    if (company) {
      change(form, "company_id", company.id);
    }
  };

  const addClient = () => {
    if (clientName) {
      change(form, "client_id", clientName.id);
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="row">
          {!values.is_accommodation_only ? (
            <>
              <div className="col col-w-full xl:col-w-1/2">
                <Field
                  name="back_projection"
                  label="Do you need back projection"
                  component={Toggle}
                  wrapperClassName="mb-8 xl:w-1/2"
                  validate={v.back_projection}
                />
              </div>
              <div className="col col-w-full xl:col-w-1/2 flex">
                <Field
                  name="exhibition_space"
                  label="Do you need exhibition space"
                  component={Toggle}
                  wrapperClassName="mb-8 pr-8 xl:w-1/2"
                  validate={v.exhibition_space}
                />
                {values && values.exhibition_space ? (
                  <Field
                    name="exhibition_space_notes"
                    label="Notes"
                    component={Textarea}
                    rows={5}
                    wrapperClassName="w-1/2 mb-8"
                    validate={v.exhibition_space_notes}
                  />
                ) : null}
              </div>
            </>
          ) : null}
          <div className="col col-w-full xl:col-w-1/2 flex">
            <Field
              name="private_dining"
              label="Do you need catering"
              component={Toggle}
              wrapperClassName="mb-8 pr-8 xl:w-1/2"
              validate={v.private_dining}
            />
            {values && values.private_dining ? (
              <Field
                name="private_dining_notes"
                component={Textarea}
                rows={5}
                label="Notes"
                wrapperClassName="w-1/2 mb-8"
                validate={v.private_dining_notes}
              />
            ) : null}
          </div>

          {!values.is_accommodation_only ? (
            <>
              <div className="col col-w-full xl:col-w-1/2 flex">
                <Field
                  name="any_equipment"
                  label="Do you need any equipment"
                  component={Toggle}
                  wrapperClassName="mb-8 pr-8 xl:w-1/2"
                  validate={v.any_equipment}
                />
                {values && values.any_equipment ? (
                  <Field
                    name="any_equipment_notes"
                    component={Textarea}
                    rows={5}
                    label="Notes"
                    wrapperClassName="w-1/2 mb-8"
                    validate={v.any_equipment_notes}
                  />
                ) : null}
              </div>
            </>
          ) : null}

          {!values.is_accommodation_only ? (
              <>
                <div className="col col-w-full xl:col-w-1/2 flex">
                  <Field
                    name="syndicate_room"
                    label="Do you need a syndicate room"
                    component={Toggle}
                    wrapperClassName="mb-8 pr-8 xl:w-1/2"
                    validate={v.syndicate_room}
                  />
                </div>
                {values && values.syndicate_room ? (
                  <div className="col col-w-full">
                    <FieldArray
                      roomStyleOptions={roomStyleOptions}
                      name="syndicate_rooms"
                      props={{ change }}
                      values={values}
                      roomStylesLoading={roomStylesLoading}
                      component={RepeatableSyndicateRoom}
                    />
                  </div>
                ) : null}
                {values && values.syndicate_room ? (
                  <div className="col col-w-full xl:col-w-1/2 flex">
                    <Field
                      name="syndicate_room_notes"
                      component={Textarea}
                      rows={5}
                      label="Syndicate Room Notes"
                      wrapperClassName="w-full mb-8"
                      validate={v.syndicate_room_notes}
                    />
                  </div>
                ) : null}
              </>
          ) : null}
        </div>
        {values && values.syndicate_room ? (
          <hr className="mb-6 border-grey-darker"/>
        ) : null}
        <div className="row">
          <div className="col col-w-full xl:col-w-1/2 flex">
            <Field
              name="date_decision_estimate"
              label="Decision Date"
              component={DateInput}
              wrapperClassName="w-1/2 mb-8"
              validate={v.date_decision_date}
            />
          </div>
        </div>

        {!values.is_accommodation_only ? (
          <div className="row">
            <div className="col col-w-1/2">
              <Field
                name="event_timings"
                label="Event Timings"
                component={Textarea}
                rows={6}
                wrapperClassName="w-full mb-8"
                validate={v.event_timings}
                placeholder="Please add your start times, finish times and any required access times here"
              />
            </div>
          </div>
        ) : null}


        <div className="row">
          <div className="col col-w-1/2">
            <Field
              name="additional_notes"
              label="Additional notes"
              component={Textarea}
              rows={12}
              wrapperClassName="w-full mb-8"
              placeholder="Please add any information on Set up, Overnight hold or Parking requirements here"
            />
          </div>
          <div className="col col-w-1/2">
            <label htmlFor="additional_notes" className="mb-1 block font-normal font-heading text-sm uppercase">Additional/Supporting
              Documents</label>
            <Dropzone
              accept={fileTypesString}
              multiple={true}
              onDrop={handleDrop}
              className="max-w-full relative flex justify-center items-center border-2 border-black border-dashed rounded p-5 mb-5 pointer w-72"
            >
              <div>
                <i className="fas fa-upload mr-3" />
                Upload document
                <br />
                <span className="text-xs block mt-2">
                <b>FILE SIZE</b>: max 10MB
              </span>
              </div>
            </Dropzone>
            <div className="ml-3 mt-3">
              {Array.isArray(attachments) && attachments.map(item => (
                <React.Fragment key={item.id}>
                  <ButtonSpan
                    className="mb-2 text-brand inline-block p-2 bg-white mr-1 border border-grey"
                  >
                    {item.name}
                  </ButtonSpan>
                  <i
                    className="pointer fal fa-times text-warning mr-3"
                    onClick={() => handleDelete(item.name)}
                  />
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
        {(((isAgencyUser || isAgencyAdmin) && isAgencyApproved) && (editEnquiry && (clientName === null || (clientName && allowedAgencySpecificUserTypes.includes(clientName.user_type_id))) || !editEnquiry)) && (
          <Fragment>
            <div className="row">
              <div className="col">
                <div className="mb-4">
                  <h2>AGENCY ONLY</h2>
                  <p> This information is only visible to the agency unless you choose to share the client name with the
                    Venues.</p>
                  {userAgency.can_suppress_brief_notifications || isPlatformAdmin ?
                      <Field
                          name="suppress_notifications"
                          label="Supress All Notifications?"
                          component={Toggle}
                          validate={v.suppress_notifications}
                          wrapperClassName="inline mb-8 xl:w-1/2"
                      />
                      : null
                  }
                </div>
                {!editEnquiry && (
                  <div className="row">
                    <div className="col col-w-full mb-4">
                      <div className="flex flex-wrap gap-2">
                        {enquiryTypes.length > 0
                          ? enquiryTypes.map((enquiryType, key) => (
                            <ButtonSpan
                              key={key}
                              className={classnames(
                                "mt-2 w-1/5 text-center inline-block flex items-center px-4 py-2 border rounded-sm text-sm transition-all duration-300",
                                {
                                  "bg-white opacity-50": values["super_agency_code"] !== enquiryType.code,
                                  "bg-brand text-white border-brand": values["super_agency_code"] === enquiryType.code,
                                }
                              )}
                              onClick={() => onEnquiryTypeToggleChange(enquiryType)}
                            >
                              {enquiryType.name} Enquiry
                            </ButtonSpan>
                          ))
                          : null}
                      </div>
                    </div>
                  </div>
                )}
                <AgencySpecificEnquiryData
                  validators={v}
                  setShowNewCompany={setShowNewCompany}
                  setAwaitingRequests={setAwaitingRequests}
                  showNewCompany={showNewCompany}
                  setShowNewUser={setShowNewUser}
                  showNewUser={showNewUser}
                  setCompany={setCompany}
                  company={company}
                  editEnquiry={editEnquiry}
                  enquiry={enquiry}
                  setClientName={setClientName}
                  client={clientName}
                  addCompany={addCompany}
                  addClient={addClient}
                  setNewCompanyModal={setNewCompanyModal}
                  setNewUserModal={setNewUserModal}
                  formValues={values}
                  shouldClearSearches={shouldClearSearches}
                  setShouldClearSearches={setShouldClearSearches}
                  superAgencyCode={agencyCode}
                  isSuperAgent={(isAgencyUser || isAgencyAdmin)}
                  token={token}
                  showNulledMaxDelegates={showNulledMaxDelegates}
                />
              </div>
            </div>
          </Fragment>
        )}
        {company ?
          <CompanyQuestions
            company={company}
            isSuperAgent={(isAgencyUser || isAgencyAdmin)}
            form={form}
            change={change}
          />
          : null}
        {(company && (company.corporate_account && company.corporate_account.is_active) || (corporateAccount && corporateAccount.is_active)) ? (
          <div className="row">
            <div className="col">
              {(isAgencyUser || isAgencyAdmin) ? (
                <div className="mb-4">
                  <h2>CORPORATE EDGE ONLY</h2>
                  <p>This information is only visible to the agent/company user.</p>
                </div>
              ) : null}
              {company && company.corporate_account && company.corporate_account.is_pharmaceutical && company.corporate_account.show_internal_external_on_brief && offerRfpXpress ?
                <Field
                  name="is_external"
                  label="Is external?"
                  component={Toggle}
                /> : null}
              <CorporateSpecificEnquiryData
                form={form}
                company={company}
                validators={v}
                meetingHostId={values?.meeting_host_id}
                corporateAccount={company?.corporate_account ?? corporateAccount}
                change={change}
              />
              <CorporateSpecificQuestions
                  form={form}
                  company={company}
                  validators={v}
                  corporateAccount={company?.corporate_account ?? corporateAccount}
                  isSuperAgent={(isAgencyUser || isAgencyAdmin)}
                  change={change}/>
            </div>
          </div>
        ) : null}
        {awaitingRequests ? (
          <Loading />
        ) : (
          <>
            {/* if editing the enquiry it should always be enabled. */}
            {/* if clientName exists and client is of type corporate or agency it should only be enabled when clientName exists and current user isSuperAgent */}
            {(editEnquiry && clientName && [USER_TYPES.CORPORATE, USER_TYPES.AGENCY, USER_TYPES.VENUE].includes(clientName.user_type_id) || !editEnquiry) ? (
              <RfpSubmit
                page={2}
                enabled={(!isSuperAgent || ((isAgencyUser || isAgencyAdmin) && company && clientName))}
                prevPage={prevPage}
                hasVenues={hasVenues}
                editEnquiry={editEnquiry}
                setSubmitToAgent={setSubmitToAgent}
                setEnquiryToSubmitted={setEnquiryToSubmitted}
                isSuperAgent={(isAgencyUser || isAgencyAdmin)}
                isInternal={isInternal}
                isAgency={isAgency}
                setSubmitToDraft={setSubmitToDraft}
                enquiry={enquiry}
                requiresAuthorising={requiresAuthorising}
                setRequiresAuthorising={setRequiresAuthorising}
              />
            ) : (
              <RfpSubmit
                page={2}
                enabled={(isAgencyUser || isAgencyAdmin)}
                prevPage={prevPage}
                hasVenues={hasVenues}
                editEnquiry={editEnquiry}
                setSubmitToAgent={setSubmitToAgent}
                setEnquiryToSubmitted={setEnquiryToSubmitted}
                isSuperAgent={(isAgencyUser || isAgencyAdmin)}
                isInternal={isInternal}
                isAgency={isAgency}
                setSubmitToDraft={setSubmitToDraft}
                enquiry={enquiry}
                requiresAuthorising={requiresAuthorising}
                setRequiresAuthorising={setRequiresAuthorising}
              />
            )}
          </>
        )}
      </form>
      {requiresAuthorising && <AuthorisationTriggeredBanner />}
      {newCompanyModal && (
        <CompanyFormModal
          onSubmit={handleCreateCompany}
          onClose={() => setNewCompanyModal(false)}
          isEdgeEnquiry={values.is_edge_enquiry}
          industries={industries}
          token={token}
          agencyCode={agencyCode}
          agency={agency}
          agencyBookingDesks={agencyBookingDesks}
        />
      )}
      {newUserModal && (
        <MinimalUserModalForm
          onSubmit={handleCreateUser}
          onClose={() => setNewUserModal(false)}
          company={company}
          values={values}
          suppressUserNotifications={suppressUserNotifications}
        />
      )}
    </>
  );
};

export default reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Stage3));
