import React, {Fragment} from "react";
import {withRouter} from "react-router-dom";
import { connect } from "react-redux";
import { isLoggedIn, isAgent, isInternal } from "modules/auth";
import {
  ButtonBlack,
  ButtonBrand,
  ButtonPrimary,
  ButtonSpan,
} from "Components/Button";
import {venuePackageUpdatedCuttoff} from "../utils";

const Row = ({ dark, title, value }) => {
  return (
    <div
      className="p-2 flex justify-between items-center even:bg-[#f9f9f9] odd:bg-[#eaeaea]"
      style={{
        backgroundColor: dark ? "#eaeaea" : "#f9f9f9",
      }}
    >
      <span>{title}</span>
      <span className="font-bold">{value}</span>
    </div>
  );
};

const VenueSlide = ({
  agent,
  history,
  internal,
  loggedIn,
  onBuyItNow,
  onSelectVenue,
  onRemove,
  selectedVenues,
  venue,
}) => {
  return (
    <div className="mb-5">
      <h2
        className="text-white bg-black font-normal font-body p-3 uppercase text-xl"
        style={{ minHeight: "6.7rem" }}
      >
        {venue.name}
      </h2>
      <p className="flex items-center py-2 mb-0" style={{ minHeight: "4rem" }}>
        <span className="flex items-center justify-center flex-no-shrink rounded-100 border border-black h-8 w-8 text-lg">
          <i className="fas fa-map-marker-alt"></i>
        </span>
        <span className="pl-2 text-sm flex-shrink">
          {[venue.town, venue.county].filter(Boolean).join(", ")}
        </span>
      </p>
      <div
        className="re-16/9 w-full bg-no-repeat bg-cover bg-center"
        style={
          venue.image
            ? {
                backgroundImage: `url(${window.API}/venues/${venue.slug}/images/${venue.image})`,
              }
            : null
        }
      />
      <div className="flex justify-between p-3 font-normal text-xl mb-2">
        <div>
          <i className="mr-2 fas fa-users" />
          {venue.capacity}
        </div>
        <div>
          <i className="mr-2 fas fa-bed" />
          {venue.bedrooms}
        </div>
        <div>
          <i className="mr-2 fas fa-handshake" />
          {venue.meeting_rooms}
        </div>
      </div>
      {loggedIn && (
        <React.Fragment>
          <Row
            title="Special Offers"
            value={venue.specialOffer ? "Yes" : "No"}
          />
        </React.Fragment>
      )}
      <Row title="Star Rating" value={venue.star_rating} dark />
      {(agent || internal) && (
        <React.Fragment>
          <Row title="Agency Rates" value={venue.agencyRates ? "Yes" : "No"}/>
          <Row
            title="Commission Deals"
            value={venue.commission ? "Yes" : "No"}
            dark
          />
          <Row title="Fam Trips" value={venue.famTrips ? "Yes" : "No"}/>
        </React.Fragment>
      )}
      {history.location.pathname !== '/compare-venues-feedback' && (
        <Fragment>
          <ButtonPrimary
            classes="p-3 flex items-center justify-between w-full my-5"
            onClick={() => onSelectVenue(venue.id)}
            style={{height: "auto", lineHeight: 1}}
          >
            Select for quote
            <i
              className={`fas fa-${
                selectedVenues.includes(venue.id) ? "check-" : ""
              }square`}
            ></i>
          </ButtonPrimary>

          {/*{venue.buy_it_now ? (*/}
          {/*  <ButtonBrand*/}
          {/*    classes="p-3 flex items-center justify-center w-full mb-2"*/}
          {/*    onClick={() => onBuyItNow(venue.slug)}*/}
          {/*    style={{height: "auto", lineHeight: 1}}*/}
          {/*  >*/}
          {/*    Instant Book*/}
          {/*  </ButtonBrand>*/}
          {/*) : (*/}
          {/*  <ButtonBlack*/}
          {/*    classes="p-3 flex items-center justify-center w-full mb-2 opacity-50"*/}
          {/*    disabled*/}
          {/*    style={{height: "auto", lineHeight: 1}}*/}
          {/*  >*/}
          {/*    Instant Book not Available*/}
          {/*  </ButtonBlack>*/}
          {/*)}*/}

        </Fragment>
      )}

      <ButtonBlack
        classes="p-3 flex items-center justify-center w-full mb-2"
        onClick={() => history.push(`/venue/${venue.slug}`)}
        style={{height: "auto", lineHeight: 1}}
      >
        View venue page
      </ButtonBlack>
      <ButtonSpan
        className="p-3 text-center block w-full mb-2"
        onClick={() => onRemove(venue.id.toString())}
        style={{ height: "auto", lineHeight: 1 }}
      >
        <i className="mr-3 fal fa-minus-circle"></i>
        Remove Venue
      </ButtonSpan>
    </div>
  );
};

export default withRouter(
  connect(state => ({
    loggedIn: isLoggedIn(state),
    agent: isAgent(state),
    internal: isInternal(state),
  }))(VenueSlide)
);
