import React from "react";
import { ButtonSpan } from "../Button";
import classnames from "classnames";

export const ToggleReportType = ({ reportType, calendarYearOnClick, financialYearOnClick }) => {
  return <div className="mb-2 xxl:mb-0">
    <div className="flex text-xs min-h-9.5">
      <ButtonSpan
        className={classnames(
          "inline-block flex items-center px-3 border border-grey-md",
          { "opacity-50": reportType === "calendarYear", "bg-brand": reportType !== "calendarYear" },
        )}
        onClick={financialYearOnClick}
      >
        Financial Year
      </ButtonSpan>
      <ButtonSpan
        className={classnames(
          "inline-block flex items-center px-3 border border-grey-md border-r-0",
          { "opacity-50": reportType !== "calendarYear", "bg-brand": reportType === "calendarYear" },
        )}
        onClick={calendarYearOnClick}
      >
        Calendar Year
      </ButtonSpan>
    </div>
  </div>;
};