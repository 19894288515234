export const UPLOAD_USER_PROFILE_IMAGE_REQUEST = 'UPLOAD_USER_PROFILE_IMAGE_REQUEST';
export const UPLOAD_USER_PROFILE_IMAGE_ERROR = 'UPLOAD_USER_PROFILE_IMAGE_ERROR';
export const UPLOAD_USER_PROFILE_IMAGE_REQUEST_RECEIVED = 'UPLOAD_USER_PROFILE_IMAGE_REQUEST_RECEIVED';
export const UNLOAD_USER = 'UNLOAD_USER';
export const DELETE_USER_PROFILE_IMAGE_REQUEST = 'DELETE_USER_PROFILE_IMAGE_REQUEST';
export const DELETE_USER_PROFILE_IMAGE_ERROR = 'DELETE_USER_PROFILE_IMAGE_ERROR';

export const USER_REQUEST = 'USER_REQUEST';
export const USER_CREATED = 'USER_CREATED';
export const USER_CREATED_ERROR = 'USER_CREATED_ERROR';

export const USER_EXISTS_CHECK_REQUEST = 'USER_EXISTS_CHECK_REQUEST';
export const USER_EXISTS_RECEIVED_REQUEST = 'USER_EXISTS_RECEIVED_REQUEST';
export const USER_EXISTS_RECEIVED_ERROR = 'USER_EXISTS_RECEIVED_ERROR';

export const USER_RESEND_INVITE_REQUEST = 'USER_RESEND_INVITE_REQUEST';
export const USER_RESEND_INVITE_RECEIVED_REQUEST = 'USER_RESEND_INVITE_RECEIVED_REQUEST';
export const USER_RESEND_INVITE_RECEIVED_ERROR = 'USER_RESEND_INVITE_RECEIVED_ERROR';

export const REGISTER_INVITED_USER_REQUEST = 'REGISTER_INVITED_USER_REQUEST';
export const REGISTER_INVITED_USER_RECEIVED_REQUEST = 'REGISTER_INVITED_USER_RECEIVED_REQUEST';
export const REGISTER_INVITED_USER_RECEIVED_ERROR = 'REGISTER_INVITED_USER_RECEIVED_ERROR';

export const USER_INVITE_REQUEST = 'USER_INVITE_REQUEST';
export const USER_INVITE_RECEIVED_ERROR = 'USER_INVITE_RECEIVED_ERROR';
export const USER_INVITE_RECEIVED_REQUEST = 'USER_INVITE_RECEIVED_REQUEST';
export const USER_INVITE_UNLOAD = 'USER_INVITE_UNLOAD';
export const USER_INVITES_UNLOAD = 'USER_INVITES_UNLOAD';

export const USER_INVITES_REQUEST = 'USER_INVITES_REQUEST';
export const USER_INVITES_RECEIVED_REQUEST = 'USER_INVITES_RECEIVED_REQUEST';
export const USER_INVITES_RECEIVED_ERROR = 'USER_INVITES_RECEIVED_ERROR';

export const AGENCY_USER_INVITE_REQUEST = 'AGENCY_USER_INVITE_REQUEST';
export const AGENCY_USER_INVITE_RECEIVED_REQUEST = 'AGENCY_USER_INVITE_RECEIVED_REQUEST';
export const AGENCY_USER_INVITE_RECEIVED_ERROR = 'AGENCY_USER_INVITE_RECEIVED_ERROR';

export const DELETE_INVITE_REQUEST = 'DELETE_INVITE_REQUEST';
export const DELETE_INVITE_RECEIVED_REQUEST = 'DELETE_INVITE_RECEIVED_REQUEST';
export const DELETE_INVITE_RECEIVED_ERROR = 'DELETE_INVITE_RECEIVED_ERROR';

export const COMPANY_USER_LIST_REQUEST = 'COMPANY_USER_LIST_REQUEST';
export const COMPANY_USER_LIST_REQUEST_RECEIVED = 'COMPANY_USER_LIST_REQUEST_RECEIVED';
export const COMPANY_USER_LIST_UNLOAD = 'COMPANY_USER_LIST_UNLOAD';
export const COMPANY_USER_LIST_RECEIVED_ERROR = 'COMPANY_USER_LIST_RECEIVED_ERROR';

export const COMPANY_USER_INVITE_REQUEST = 'COMPANY_USER_INVITE_REQUEST';
export const COMPANY_USER_INVITE_RECEIVED_REQUEST = 'COMPANY_USER_INVITE_RECEIVED_REQUEST';
export const COMPANY_USER_INVITE_RECEIVED_ERROR = 'COMPANY_USER_INVITE_RECEIVED_ERROR';

export const CREATE_COMPANY = 'CREATE_COMPANY';
export const CREATE_COMPANY_ERROR = 'CREATE_COMPANY_ERROR';
export const EDIT_COMPANY = 'EDIT_COMPANY';
export const EDIT_COMPANY_ERROR = 'EDIT_COMPANY_ERROR';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const DELETE_COMPANY_ERROR = 'DELETE_COMPANY_ERROR';

export const COMPANY_LIST_REQUEST = 'COMPANY_LIST_REQUEST';
export const COMPANY_LIST_RECEIVED = 'COMPANY_LIST_RECEIVED';
export const COMPANY_LIST_ERROR = 'COMPANY_LIST_ERROR';
export const COMPANY_LIST_EVENTS_REQUEST = 'COMPANY_LIST_EVENTS_REQUEST';
export const COMPANY_LIST_EVENTS_RECEIVED = 'COMPANY_LIST_EVENTS_RECEIVED';
export const COMPANY_LIST_EVENTS_ERROR = 'COMPANY_LIST_EVENTS_ERROR';
export const COMPANY_REQUEST = 'COMPANY_REQUEST';
export const COMPANY_UNLOAD = 'COMPANY_UNLOAD';
export const COMPANY_LIST_UNLOAD = 'COMPANY_LIST_UNLOAD';
export const EDIT_COMPANY_USER = 'EDIT_COMPANY_USER';
export const EDIT_COMPANY_USER_ERROR = 'EDIT_COMPANY_USER_ERROR';
export const DELETE_COMPANY_USER = 'DELETE_COMPANY_USER';
export const DELETE_COMPANY_USER_ERROR = 'DELETE_COMPANY_USER_ERROR';
export const COMPANY_ROLES_UPDATE_RECEIVED_REQUEST = 'COMPANY_ROLES_UPDATE_RECEIVED_REQUEST';

export const COMPANY_USER_REQUEST = 'COMPANY_USER_REQUEST';
export const COMPANY_USER_RECEIVED = 'COMPANY_USER_RECEIVED';
export const COMPANY_USER_RECEIVED_ERROR = 'COMPANY_USER_RECEIVED_ERROR';

export const AGENCY_LIST_REQUEST = 'AGENCY_LIST_REQUEST';
export const AGENCY_LIST_FOR_SELECT_REQUEST = 'AGENCY_LIST_FOR_SELECT_REQUEST';
export const AGENCY_LIST_RECEIVED = 'AGENCY_LIST_RECEIVED';
export const AGENCY_LIST_FOR_SELECT_RECEIVED = 'AGENCY_LIST_FOR_SELECT_RECEIVED';

export const AGENCY_LIST_ERROR = 'AGENCY_LIST_ERROR';
export const AGENCY_LIST_FOR_SELECT_ERROR = 'AGENCY_LIST_FOR_SELECT_ERROR';
export const AGENCY_REQUEST = 'AGENCY_REQUEST';
export const AGENCY_USER_REQUEST = 'AGENCY_USER_REQUEST';
export const DELETE_AGENCY_USER = 'DELETE_AGENCY_USER';
export const DELETE_AGENCY_USER_ERROR = 'DELETE_AGENCY_USER_ERROR';

export const CREATE_AGENCY = 'CREATE_AGENCY';
export const CREATE_AGENCY_ERROR = 'CREATE_AGENCY_ERROR';
export const EDIT_AGENCY = 'EDIT_AGENCY';
export const EDIT_AGENCY_ERROR = 'EDIT_AGENCY_ERROR';
export const EDIT_AGENCY_USER = 'EDIT_AGENCY_USER';
export const EDIT_AGENCY_USER_ERROR = 'EDIT_AGENCY_USER_ERROR';
export const AGENCY_USER_RECEIVED = 'AGENCY_USER_RECEIVED';
export const AGENCY_USER_RECEIVED_ERROR = 'AGENCY_USER_RECEIVED_ERROR';
export const AGENCY_ROLES_UPDATE_RECEIVED_REQUEST = 'AGENCY_ROLES_UPDATE_RECEIVED_REQUEST';

export const AGENCY_RECEIVED_ERROR = 'AGENCY_RECEIVED_ERROR';
export const AGENCY_RECEIVED = 'AGENCY_RECEIVED';
export const AGENCY_UNLOAD = 'AGENCY_UNLOAD';
export const AGENCY_LIST_UNLOAD = 'AGENCY_LIST_UNLOAD';
export const SELECTED_AGENCIES_REQUEST = 'SELECTED_AGENCIES_REQUEST';
export const SELECTED_AGENCIES_RECEIVED_REQUEST = 'SELECTED_AGENCIES_RECEIVED_REQUEST';
export const SELECTED_AGENCIES_RECEIVED_ERROR = 'CHILD_AGENCIES_RECEIVED_ERROR';

export const COMPANY_RECEIVED = 'COMPANY_RECEIVED';
export const COMPANY_RECEIVED_ERROR = 'COMPANY_RECEIVED_ERROR';

export const COUNTRY_LIST_RECEIVED = 'COUNTRY_LIST_RECEIVED';
export const COUNTRY_LIST_REQUEST = 'COUNTRY_LIST_REQUEST';
export const COUNTRY_LIST_RECEIVED_ERROR = 'COUNTRY_LIST_RECEIVED_ERROR';

export const ROLES_LIST_REQUEST = 'ROLES_LIST_REQUEST';
export const ROLES_LIST_RECEIVED = 'ROLES_LIST_RECEIVED';
export const ROLES_LIST_ERROR = 'ROLES_LIST_ERROR';

export const CORPORATE_ROLES_LIST_REQUEST = 'CORPORATE_ROLES_LIST_REQUEST';
export const CORPORATE_ROLES_LIST_RECEIVED = 'CORPORATE_ROLES_LIST_RECEIVED';
export const CORPORATE_ROLES_LIST_ERROR = 'CORPORATE_ROLES_LIST_ERROR';
export const CORPORATE_ROLES_UPDATE_RECEIVED_REQUEST = 'CORPORATE_ROLES_UPDATE_RECEIVED_REQUEST';

export const NOTE_REQUEST = 'NOTE_REQUEST';
export const NOTE_CREATED = 'NOTE_CREATED';
export const NOTE_CREATED_ERROR = 'NOTE_CREATED_ERROR';
export const UNLOAD_NOTE = 'UNLOAD_NOTE';
export const NOTE_UPDATED = 'NOTE_UPDATED';

export const NOTES_LIST_REQUEST = 'NOTES_LIST_REQUEST';
export const NOTES_RECEIVED_REQUEST = 'NOTES_RECEIVED_REQUEST';
export const NOTES_RECEIVED_ERROR = 'NOTES_RECEIVED_ERROR';

export const USER_ROLES_UPDATE_REQUEST = 'USER_ROLES_UPDATE_REQUEST';
export const ROLES_UPDATE_RECEIVED_REQUEST = 'ROLES_UPDATE_RECEIVED_REQUEST';
export const ROLES_UPDATE_RECEIVED_REQUEST_ERROR = 'ROLES_UPDATE_RECEIVED_REQUEST_ERROR';

export const VAT_RATES_LIST_REQUEST = 'VAT_RATES_LIST_REQUEST';
export const VAT_RATES_LIST_RECEIVED = 'VAT_RATES_LIST_RECEIVED';
export const VAT_RATES_LIST_ERROR = 'VAT_RATES_LIST_ERROR';

export const NOMINAL_CODE_LIST_REQUEST = 'NOMINAL_CODE_LIST_REQUEST';
export const NOMINAL_CODE_LIST_RECEIVED = 'NOMINAL_CODE_LIST_RECEIVED';
export const NOMINAL_CODE_LIST_ERROR = 'NOMINAL_CODE_LIST_ERROR';
export const NOMINAL_CODE_LIST_UNLOAD = 'NOMINAL_CODE_LIST_UNLOAD';
export const NOMINAL_CODE_EDIT_REQUEST_RECEIVED = 'NOMINAL_CODE_EDIT_REQUEST_RECEIVED';

export const CORPORATE_ACCOUNT_REQUEST = 'CORPORATE_ACCOUNT_REQUEST';
export const CORPORATE_ACCOUNT_RECEIVED_ERROR = 'CORPORATE_ACCOUNT_RECEIVED_ERROR';
export const CORPORATE_ACCOUNT_RECEIVED = 'CORPORATE_ACCOUNT_RECEIVED';
export const CORPORATE_ACCOUNT_UNLOAD = 'CORPORATE_ACCOUNT_UNLOAD';

export const CORPORATE_ACCOUNT_LOGO_IMAGE_REQUEST = 'CORPORATE_ACCOUNT_LOGO_IMAGE_REQUEST';
export const CORPORATE_ACCOUNT_LOGO_IMAGE_ERROR = 'CORPORATE_ACCOUNT_LOGO_IMAGE_ERROR';
export const DELETE_CORPORATE_ACCOUNT_LOGO_IMAGE_REQUEST = 'DELETE_CORPORATE_ACCOUNT_LOGO_IMAGE_REQUEST';
export const DELETE_CORPORATE_ACCOUNT_LOGO_IMAGE_ERROR = 'DELETE_CORPORATE_ACCOUNT_LOGO_IMAGE_ERROR';

export const CORPORATE_ACCOUNT_LIST_RECEIVED = 'CORPORATE_ACCOUNT_LIST_RECEIVED';
export const CORPORATE_ACCOUNT_LIST_REQUEST = 'CORPORATE_ACCOUNT_LIST_REQUEST';
export const CORPORATE_ACCOUNT_LIST_RECEIVED_ERROR = 'CORPORATE_ACCOUNT_LIST_RECEIVED_ERROR';
export const CORPORATE_ACCOUNT_LIST_UNLOAD = 'CORPORATE_ACCOUNT_LIST_UNLOAD';

export const CORPORATE_ACCOUNT_LOCATION_CREATE = 'CORPORATE_ACCOUNT_LOCATION_CREATE';
export const CORPORATE_ACCOUNT_LOCATION_CREATE_ERROR = 'CORPORATE_ACCOUNT_LOCATION_CREATE_ERROR';
export const EDIT_CORPORATE_ACCOUNT_LOCATION_REQUEST = 'EDIT_CORPORATE_ACCOUNT_LOCATION_REQUEST';
export const EDIT_CORPORATE_ACCOUNT_LOCATION_RESPONSE = 'EDIT_CORPORATE_ACCOUNT_LOCATION_RESPONSE';
export const EDIT_CORPORATE_ACCOUNT_LOCATION_ERROR = 'EDIT_CORPORATE_ACCOUNT_LOCATION_ERROR';

export const CORPORATE_ACCOUNT_LOCATION_REQUEST = 'CORPORATE_ACCOUNT_LOCATION_REQUEST';
export const CORPORATE_ACCOUNT_LOCATION_RECEIVED = 'CORPORATE_ACCOUNT_LOCATION_RECEIVED';
export const CORPORATE_ACCOUNT_LOCATION_RECEIVED_ERROR = 'CORPORATE_ACCOUNT_LOCATION_RECEIVED_ERROR';
export const CORPORATE_ACCOUNT_LOCATION_UNLOAD = 'CORPORATE_ACCOUNT_LOCATION_UNLOAD';

export const CORPORATE_ACCOUNT_LOCATIONS_LIST_REQUEST = 'CORPORATE_ACCOUNT_LOCATIONS_LIST_REQUEST';
export const CORPORATE_ACCOUNT_LOCATIONS_LIST_RECEIVED = 'CORPORATE_ACCOUNT_LOCATIONS_LIST_RECEIVED';
export const CORPORATE_ACCOUNT_LOCATIONS_LIST_RECEIVED_ERROR = 'CORPORATE_ACCOUNT_LOCATIONS_LIST_RECEIVED_ERROR';
export const CORPORATE_ACCOUNT_LOCATIONS_LIST_UNLOAD = 'CORPORATE_ACCOUNT_LOCATIONS_LIST_UNLOAD';

export const CREATE_CORPORATE_ACCOUNT_REQUEST = 'CREATE_CORPORATE_ACCOUNT_REQUEST';

export const CREATE_CORPORATE_ACCOUNT = 'CREATE_CORPORATE_ACCOUNT';
export const CREATE_CORPORATE_ACCOUNT_ERROR = 'CREATE_CORPORATE_ACCOUNT_ERROR';
export const EDIT_CORPORATE_ACCOUNT = 'EDIT_CORPORATE_ACCOUNT';
export const EDIT_CORPORATE_ACCOUNT_ERROR = 'EDIT_CORPORATE_ACCOUNT_ERROR';

export const CORPORATE_ACCOUNT_DOCUMENT_LIST_RECEIVED = 'CORPORATE_ACCOUNT_DOCUMENT_LIST_RECEIVED';
export const CORPORATE_ACCOUNT_DOCUMENT_LIST_REQUEST = 'CORPORATE_ACCOUNT_DOCUMENT_LIST_REQUEST';
export const CORPORATE_ACCOUNT_DOCUMENT_LIST_RECEIVED_ERROR = 'CORPORATE_ACCOUNT_DOCUMENT_LIST_RECEIVED_ERROR';
export const CORPORATE_ACCOUNT_DOCUMENT_LIST_UNLOAD = 'CORPORATE_ACCOUNT_DOCUMENT_LIST_UNLOAD';

export const CORPORATE_ACCOUNT_DOCUMENT_CATEGORY_LIST_RECEIVED = 'CORPORATE_ACCOUNT_DOCUMENT_CATEGORY_LIST_RECEIVED';
export const CORPORATE_ACCOUNT_DOCUMENT_CATEGORY_LIST_REQUEST = 'CORPORATE_ACCOUNT_DOCUMENT_CATEGORY_LIST_REQUEST';
export const CORPORATE_ACCOUNT_DOCUMENT_CATEGORY_LIST_RECEIVED_ERROR = 'CORPORATE_ACCOUNT_DOCUMENT_CATEGORY_LIST_REQUEST';
export const CORPORATE_ACCOUNT_DOCUMENT_CATEGORY_LIST_UNLOAD = 'CORPORATE_ACCOUNT_DOCUMENT_CATEGORY_LIST_UNLOAD';

export const CORPORATE_ACCOUNT_DOCUMENT_REQUEST = 'CORPORATE_ACCOUNT_DOCUMENT_REQUEST';
export const CORPORATE_ACCOUNT_DOCUMENT_RECEIVED_ERROR = 'CORPORATE_ACCOUNT_DOCUMENT_RECEIVED_ERROR';
export const CORPORATE_ACCOUNT_DOCUMENT_RECEIVED = 'CORPORATE_ACCOUNT_DOCUMENT_RECEIVED';
export const CORPORATE_ACCOUNT_DOCUMENT_UNLOAD = 'CORPORATE_ACCOUNT_DOCUMENT_UNLOAD';

export const CREATE_CORPORATE_ACCOUNT_DOCUMENT = 'CREATE_CORPORATE_ACCOUNT_DOCUMENT';
export const CREATE_CORPORATE_ACCOUNT_DOCUMENT_ERROR = 'CREATE_CORPORATE_ACCOUNT_DOCUMENT_ERROR';
export const EDIT_CORPORATE_ACCOUNT_DOCUMENT = 'EDIT_CORPORATE_ACCOUNT_DOCUMENT';
export const EDIT_CORPORATE_ACCOUNT_DOCUMENT_ERROR = 'EDIT_CORPORATE_ACCOUNT_DOCUMENT_ERROR';
export const DELETE_CORPORATE_ACCOUNT_DOCUMENT = 'DELETE_CORPORATE_ACCOUNT_DOCUMENT';
export const DELETE_CORPORATE_ACCOUNT_DOCUMENT_ERROR = 'DELETE_CORPORATE_ACCOUNT_DOCUMENT_ERROR';
export const CREATE_CORPORATE_ACCOUNT_DOCUMENT_CATEGORY = 'CREATE_CORPORATE_ACCOUNT_DOCUMENT_CATEGORY';
export const CREATE_CORPORATE_ACCOUNT_DOCUMENT_CATEGORY_ERROR = 'CREATE_CORPORATE_ACCOUNT_DOCUMENT_CATEGORY_ERROR';

export const AGENCY_BOOKING_DESKS_LIST_REQUEST = 'AGENCY_BOOKING_DESKS_LIST_REQUEST';
export const AGENCY_BOOKING_DESKS_LIST_RECEIVED = 'AGENCY_BOOKING_DESKS_LIST_RECEIVED';
export const AGENCY_BOOKING_DESKS_LIST_RECEIVED_ERROR = 'AGENCY_BOOKING_DESKS_LIST_RECEIVED_ERROR';
export const AGENCY_BOOKING_DESK_LIST_UNLOAD = 'AGENCY_BOOKING_DESK_LIST_UNLOAD';
export const CORPORATE_BOOKING_DESK_UPDATED = 'CORPORATE_BOOKING_DESK_UPDATED';
export const CORPORATE_BOOKING_DESK_UPDATED_ERROR = 'CORPORATE_BOOKING_DESK_UPDATED_ERROR';

export const BUSINESS_UNITS_LIST_REQUEST = 'BUSINESS_UNITS_LIST_REQUEST';
export const BUSINESS_UNITS_LIST_REQUEST_RECEIVED = 'BUSINESS_UNITS_LIST_REQUEST_RECEIVED';
export const BUSINESS_UNITS_LIST_REQUEST_RECEIVED_ERROR = 'BUSINESS_UNITS_LIST_REQUEST_RECEIVED_ERROR';
export const BUSINESS_UNIT_REQUEST = 'BUSINESS_UNIT_REQUEST';
export const BUSINESS_UNIT_REQUEST_RECEIVED = 'BUSINESS_UNIT_REQUEST_RECEIVED';
export const BUSINESS_UNIT_REQUEST_RECEIVED_ERROR = 'BUSINESS_UNIT_REQUEST_RECEIVED_ERROR';
export const BUSINESS_UNITS_UNLOAD = 'BUSINESS_UNITS_UNLOAD';
export const BUSINESS_UNIT_CREATED = 'BUSINESS_UNIT_CREATED';
export const BUSINESS_UNIT_CREATED_ERROR = 'BUSINESS_UNIT_CREATED_ERROR';
export const BUSINESS_UNIT_UPDATED = 'BUSINESS_UNIT_UPDATED';
export const BUSINESS_UNIT_UPDATE_ERROR = 'BUSINESS_UNIT_UPDATE_ERROR';
export const BUSINESS_UNIT_DELETED = 'BUSINESS_UNIT_DELETED';
export const BUSINESS_UNIT_DELETED_ERROR = 'BUSINESS_UNIT_DELETED_ERROR';

export const CORPORATE_ACCOUNT_BOOKING_DESK_DELETED = 'CORPORATE_ACCOUNT_BOOKING_DESK_DELETED';
export const CORPORATE_ACCOUNT_BOOKING_DESK_UNLOAD = 'CORPORATE_ACCOUNT_BOOKING_DESK_UNLOAD';
export const CORPORATE_ACCOUNT_BOOKING_DESK_CREATED = 'CORPORATE_ACCOUNT_BOOKING_DESK_CREATED';
export const CORPORATE_ACCOUNT_BOOKING_DESK_CREATED_ERROR = 'CORPORATE_ACCOUNT_BOOKING_DESK_CREATED_ERROR';
export const CORPORATE_ACCOUNT_BOOKING_DESK_LIST_UNLOAD = 'CORPORATE_ACCOUNT_BOOKING_DESK_LIST_UNLOAD';
export const CORPORATE_ACCOUNT_BOOKING_DESK_LIST_REQUEST = 'CORPORATE_ACCOUNT_BOOKING_DESK_LIST_REQUEST';
export const CORPORATE_ACCOUNT_BOOKING_DESK_LIST_RECEIVED = 'CORPORATE_ACCOUNT_BOOKING_DESK_LIST_RECEIVED';
export const CORPORATE_ACCOUNT_BOOKING_DESK_LIST_RECEIVED_ERROR = 'CORPORATE_ACCOUNT_BOOKING_DESK_LIST_RECEIVED_ERROR';
export const AGENCY_BOOKING_DESK_CREATED = 'AGENCY_BOOKING_DESK_CREATED';
export const AGENCY_BOOKING_DESK_CREATED_ERROR = 'AGENCY_BOOKING_DESK_CREATED_ERROR';
export const AGENCY_BOOKING_DESK_DELETED = 'AGENCY_BOOKING_DESK_DELETED';
export const AGENCY_BOOKING_DESK_ERROR = 'AGENCY_BOOKING_DESK_ERROR';

export const CORPORATE_ACCOUNT_DEPARTMENTS_LIST_REQUEST = 'CORPORATE_ACCOUNT_DEPARTMENTS_LIST_REQUEST';
export const CORPORATE_ACCOUNT_DEPARTMENTS_LIST_RECEIVED = 'CORPORATE_ACCOUNT_DEPARTMENTS_LIST_RECEIVED';
export const CORPORATE_ACCOUNT_DEPARTMENTS_LIST_ERROR = 'CORPORATE_ACCOUNT_DEPARTMENTS_LIST_ERROR';
export const CORPORATE_ACCOUNT_DEPARTMENTS_UNLOAD = 'CORPORATE_ACCOUNT_DEPARTMENTS_UNLOAD';

export const CORPORATE_ACCOUNT_DEPARTMENT_REQUEST = 'CORPORATE_ACCOUNT_DEPARTMENT_REQUEST';
export const CORPORATE_ACCOUNT_DEPARTMENT_RECEIVED = 'CORPORATE_ACCOUNT_DEPARTMENT_RECEIVED';
export const CORPORATE_ACCOUNT_DEPARTMENT_RECEIVED_ERROR = 'CORPORATE_ACCOUNT_DEPARTMENT_RECEIVED_ERROR';
export const CORPORATE_ACCOUNT_DEPARTMENT_UNLOAD = 'CORPORATE_ACCOUNT_DEPARTMENT_UNLOAD';
export const CORPORATE_ACCOUNT_DEPARTMENT_DEACTIVATED = 'CORPORATE_ACCOUNT_DEPARTMENT_DEACTIVATED';
export const CORPORATE_ACCOUNT_DEPARTMENT_DEACTIVATED_ERROR = 'CORPORATE_ACCOUNT_DEPARTMENT_DEACTIVATED_ERROR';
export const CORPORATE_ACCOUNT_DEPARTMENT_CREATED = 'CORPORATE_ACCOUNT_DEPARTMENT_CREATED';
export const CORPORATE_ACCOUNT_DEPARTMENT_CREATED_ERROR = 'CORPORATE_ACCOUNT_DEPARTMENT_CREATED_ERROR';

export const CORPORATE_ACCOUNT_BOOKING_DESK_UPDATED = 'CORPORATE_ACCOUNT_BOOKING_DESK_UPDATED';
export const CORPORATE_ACCOUNT_BOOKING_DESK_ERROR = 'CORPORATE_ACCOUNT_BOOKING_DESK_ERROR';

export const CORPORATE_ACCOUNT_BOOKING_DESK_REQUEST = 'CORPORATE_ACCOUNT_BOOKING_DESK_REQUEST';
export const CORPORATE_ACCOUNT_BOOKING_DESK_RECEIVED = 'CORPORATE_ACCOUNT_BOOKING_DESK_RECEIVED';
export const CORPORATE_ACCOUNT_BOOKING_DESK_RECEIVED_ERROR = 'CORPORATE_ACCOUNT_BOOKING_DESK_RECEIVED_ERROR';

export const CORPORATE_ACCOUNT_LIST_USERS_REQUEST = 'CORPORATE_ACCOUNT_LIST_USERS_REQUEST';
export const CORPORATE_ACCOUNT_LIST_USERS_RECEIVED = 'CORPORATE_ACCOUNT_LIST_USERS_RECEIVED';
export const CORPORATE_ACCOUNT_LIST_USERS_ERROR = 'CORPORATE_ACCOUNT_LIST_USERS_ERROR';
export const UNLOAD_CORPORATE_ACCOUNT_USERS = 'UNLOAD_CORPORATE_ACCOUNT_USERS';

export const CORPORATE_ACCOUNT_PERMISSIONS_REQUEST = "CORPORATE_ACCOUNT_PERMISSIONS_REQUEST";
export const CORPORATE_ACCOUNT_PERMISSIONS_RECEIVED = "CORPORATE_ACCOUNT_PERMISSIONS_RECEIVED";
export const CORPORATE_ACCOUNT_PERMISSIONS_RECEIVED_ERROR = "CORPORATE_ACCOUNT_PERMISSIONS_RECEIVED_ERROR";
export const UNLOAD_CORPORATE_ACCOUNT_PERMISSIONS = "UNLOAD_CORPORATE_ACCOUNT_PERMISSIONS";

export const EVENT_REQUEST = 'EVENT_REQUEST';
export const EVENT_RECEIVED_REQUEST = 'EVENT_RECEIVED_REQUEST';
export const EVENT_RECEIVED_ERROR = 'EVENT_RECEIVED_ERROR';

export const EVENT_UNLOAD = 'EVENT_UNLOAD';

export const PUBLIC_EVENT_REQUEST = 'PUBLIC_EVENT_REQUEST';
export const CREATE_PUBLIC_EVENT = 'CREATE_PUBLIC_EVENT';
export const CREATE_PUBLIC_EVENT_ERROR = 'CREATE_PUBLIC_EVENT_ERROR';

export const PUBLIC_EVENTS_LIST_REQUEST = 'PUBLIC_EVENTS_LIST_REQUEST';
export const PUBLIC_EVENTS_LIST_RECEIVED = 'PUBLIC_EVENTS_LIST_RECEIVED';
export const PUBLIC_EVENTS_LIST_ERROR = 'PUBLIC_EVENTS_LIST_ERROR';
export const PUBLIC_EVENTS_LIST_UNLOAD = 'PUBLIC_EVENTS_LIST_UNLOAD';

export const INDUSTRY_EVENT_REQUEST = 'INDUSTRY_EVENT_REQUEST';
export const CREATE_INDUSTRY_EVENT = 'CREATE_INDUSTRY_EVENT';
export const CREATE_INDUSTRY_EVENT_ERROR = 'CREATE_INDUSTRY_EVENT_ERROR';

export const INDUSTRY_EVENTS_LIST_REQUEST = 'INDUSTRY_EVENTS_LIST_REQUEST';
export const INDUSTRY_EVENTS_LIST_RECEIVED = 'INDUSTRY_EVENTS_LIST_RECEIVED';
export const INDUSTRY_EVENTS_LIST_ERROR = 'INDUSTRY_EVENTS_LIST_ERROR';
export const INDUSTRY_EVENTS_LIST_UNLOAD = 'INDUSTRY_EVENTS_LIST_UNLOAD';

export const CORPORATE_EVENTS_LIST_UNLOAD = 'CORPORATE_EVENTS_LIST_UNLOAD';

export const BOOKED_EVENTS_UNLOAD = 'BOOKED_EVENTS_UNLOAD';
export const BOOKED_EVENTS_LIST_RECEIVED = 'BOOKED_EVENTS_LIST_RECEIVED';
export const OWN_EVENTS_LIST_RECEIVED = 'OWN_EVENTS_LIST_RECEIVED';
export const OWN_EVENTS_UNLOAD = 'OWN_EVENTS_UNLOAD';

export const DELETE_EVENT_REQUEST = 'DELETE_EVENT_REQUEST';
export const EVENT_DELETED_REQUEST = 'EVENT_DELETED_REQUEST';
export const EVENT_DELETED_ERROR = 'EVENT_DELETED_ERROR';

export const EVENTS_LIST_REQUEST = 'EVENTS_LIST_REQUEST';
export const EVENTS_LIST_RECEIVED = 'EVENTS_LIST_RECEIVED';
export const EVENTS_LIST_RECEIVED_ERROR = 'EVENTS_LIST_RECEIVED_ERROR';
export const EVENTS_LIST_UNLOAD = 'EVENTS_LIST_UNLOAD';
export const EVENTS_CHASED_RECEIVED = 'EVENTS_CHASED_RECEIVED';

export const EVENT_STATUS_LIST = 'EVENT_STATUS_LIST';
export const EVENT_STATUS_LIST_RECEIVED = 'EVENT_STATUS_LIST_RECEIVED';
export const EVENT_STATUS_LIST_RECEIVED_ERROR = 'EVENT_STATUS_LIST_RECEIVED_ERROR';
export const EVENT_STATUS_LIST_UNLOAD = 'EVENT_STATUS_LIST_UNLOAD';

export const EVENTS_SINGLE_REQUEST = 'EVENTS_SINGLE_REQUEST';
export const EVENTS_SINGLE_RECEIVED = 'EVENTS_SINGLE_RECEIVED';
export const EVENTS_SINGLE_RECEIVED_ERROR = 'EVENTS_SINGLE_RECEIVED_ERROR';
export const EVENTS_SINGLE_UNLOAD = 'EVENTS_SINGLE_UNLOAD';
export const EVENTS_SINGLE_CONFIRMATION_UPLOAD = 'EVENTS_SINGLE_CONFIRMATION_UPLOAD';
export const EVENTS_SINGLE_CONFIRMATION_DELETE_FILE = 'EVENTS_SINGLE_CONFIRMATION_DELETE_FILE';
export const EVENTS_SINGLE_FINAL_COMMISSION_VALUES = 'EVENTS_SINGLE_FINAL_COMMISSION_VALUES';
export const EVENTS_SINGLE_FINAL_COMMISSION_VALUES_ERROR = 'EVENTS_SINGLE_FINAL_COMMISSION_VALUES_ERROR';

export const EVENTS_SINGLE_INVOICED_RECEIVED = 'EVENTS_SINGLE_INVOICED_RECEIVED'
export const EVENTS_SINGLE_INVOICED_RECEIVED_ERROR = 'EVENTS_SINGLE_INVOICED_RECEIVED_ERROR'

export const SNAPSHOT_FIGURES_RECEIVED = 'SNAPSHOT_FIGURES_RECEIVED';
export const SNAPSHOT_FIGURES_REQUEST = 'SNAPSHOT_FIGURES_REQUEST';
export const SNAPSHOT_FIGURES_RECEIVED_ERROR = 'SNAPSHOT_FIGURES_RECEIVED_ERROR';
export const SNAPSHOT_FIGURES_UNLOAD = 'SNAPSHOT_FIGURES_UNLOAD';

export const SNAPSHOT_FIGURES_ITEMS_RECEIVED = 'SNAPSHOT_FIGURES_ITEMS_RECEIVED';
export const SNAPSHOT_FIGURES_ITEMS_REQUEST = 'SNAPSHOT_FIGURES_ITEMS_REQUEST';
export const SNAPSHOT_FIGURES_ITEMS_RECEIVED_ERROR = 'SNAPSHOT_FIGURES_ITEMS_RECEIVED_ERROR';
export const SNAPSHOT_FIGURES_ITEMS_UNLOAD = 'SNAPSHOT_FIGURES_ITEMS_UNLOAD';

export const SNAPSHOT_FIGURES_GRAPH_DATA_REQUEST = 'SNAPSHOT_FIGURES_GRAPH_DATA_REQUEST';
export const SNAPSHOT_FIGURES_GRAPH_DATA_RECEIVED = 'SNAPSHOT_FIGURES_GRAPH_DATA_RECEIVED';
export const SNAPSHOT_FIGURES_GRAPH_DATA_RECEIVED_ERROR = 'SNAPSHOT_FIGURES_GRAPH_DATA_RECEIVED_ERROR';
export const SNAPSHOT_FIGURES_GRAPH_DATA_UNLOAD = 'SNAPSHOT_FIGURES_GRAPH_DATA_UNLOAD';


export const SALES_SUMMARY_FIGURES_RECEIVED = 'SALES_SUMMARY_FIGURES_RECEIVED';
export const SALES_SUMMARY_FIGURES_REQUEST = 'SALES_SUMMARY_FIGURES_REQUEST';
export const SALES_SUMMARY_FIGURES_RECEIVED_ERROR = 'SALES_SUMMARY_FIGURES_RECEIVED_ERROR';
export const SALES_SUMMARY_FIGURES_UNLOAD = 'SALES_SUMMARY_FIGURES_UNLOAD';

export const SALES_SUMMARY_ITEM_REQUEST = 'SALES_SUMMARY_ITEM_REQUEST';
export const SALES_SUMMARY_ITEM_RECEIVED = 'SALES_SUMMARY_ITEM_RECEIVED';
export const SALES_SUMMARY_ITEM_RECEIVED_ERROR = 'SALES_SUMMARY_ITEM_RECEIVED_ERROR';
export const SALES_SUMMARY_ITEM_UNLOAD = 'SALES_SUMMARY_ITEM_UNLOAD';

export const AGENT_RFP_ITEM_REQUEST = 'AGENT_RFP_ITEM_REQUEST';
export const AGENT_RFP_ITEM_RECEIVED = 'AGENT_RFPY_ITEM_RECEIVED';
export const AGENT_RFP_ITEM_RECEIVED_ERROR = 'AGENT_RFP_ITEM_RECEIVED_ERROR';
export const AGENT_RFP_ITEM_UNLOAD = 'AGENT_RFP_ITEM_UNLOAD';

export const DRAFT_ENQUIRY_REQUEST = 'DRAFT_ENQUIRY_REQUEST';
export const DRAFT_ENQUIRY_RECEIVED_REQUEST = 'DRAFT_ENQUIRY_RECEIVED_REQUEST';
export const DRAFT_ENQUIRY_RECEIVED_ERROR = 'DRAFT_ENQUIRY_RECEIVED_ERROR';
export const DRAFT_ENQUIRY_UNLOAD = 'DRAFT_ENQUIRY_UNLOAD';

export const VENUES_LIST_REQUEST = 'VENUES_LIST_REQUEST';
export const VENUES_LIST_RECEIVED_REQUEST = 'VENUES_LIST_RECEIVED_REQUEST';
export const VENUES_LIST_RECEIVED_ERROR = 'VENUES_LIST_RECEIVED_ERROR';
export const VENUES_LIST_UNLOAD = 'VENUES_LIST_UNLOAD';

export const PROPOSAL_INVITE_RECEIVED = 'PROPOSAL_INVITE_RECEIVED';
export const PROPOSAL_INVITE_RECEIVED_ERROR = 'PROPOSAL_INVITE_RECEIVED_ERROR';
export const PROPOSAL_INVITE_REQUEST = 'PROPOSAL_INVITE_REQUEST';
export const PROPOSAL_UNLOAD = 'PROPOSAL_UNLOAD';

export const SHORTLIST_DELETE_REQUEST = 'SHORTLIST_DELETE_REQUEST';
export const SHORTLIST_DELETE_REQUEST_RECEIVED = 'SHORTLIST_DELETE_REQUEST_RECEIVED';
export const SHORTLIST_DELETE_ERROR = 'SHORTLIST_DELETE_ERROR';
export const SHORTLIST_UNLOAD = 'SHORTLIST_UNLOAD';

export const ENQUIRY_LOG_REPORT_REQUEST = 'ENQUIRY_LOG_REPORT_REQUEST';
export const EXPORT_ENQUIRY_LOG_REPORT_REQUEST_RECEIVED = 'EXPORT_ENQUIRY_LOG_REPORT_REQUEST_RECEIVED';
export const ENQUIRY_LOG_REPORT_REQUEST_RECEIVED = 'ENQUIRY_LOG_REPORT_REQUEST_RECEIVED';
export const ENQUIRY_LOG_REPORT_ERROR = 'ENQUIRY_LOG_REPORT_ERROR';
export const ENQUIRY_LOG_REPORT_UNLOAD = 'ENQUIRY_LOG_REPORT_UNLOAD';

export const SNAPSHOT_REPORT_REQUEST = 'SNAPSHOT_REPORT_REQUEST';
export const SNAPSHOT_REPORT_REQUEST_RECEIVED = 'SNAPSHOT_REPORT_REQUEST_RECEIVED';
export const SNAPSHOT_REPORT_REQUEST_ERROR = 'SNAPSHOT_REPORT_REQUEST_ERROR';
export const SNAPSHOT_REPORT_UNLOAD = 'SNAPSHOT_REPORT_UNLOAD';

export const SPEND_REPORT_REQUEST = 'SPEND_REPORT_REQUEST';
export const SPEND_REPORT_REQUEST_RECEIVED = 'SPEND_REPORT_REQUEST_RECEIVED';
export const SPEND_REPORT_REQUEST_ERROR = 'SPEND_REPORT_REQUEST_ERROR';
export const SPEND_REPORT_UNLOAD = 'SPEND_REPORT_UNLOAD';

export const MONTHLY_EVENT_VALUES_REPORT_REQUEST = 'MONTHLY_EVENT_VALUES_REPORT_REQUEST';
export const MONTHLY_EVENT_VALUES_REPORT_REQUEST_RECEIVED = 'MONTHLY_EVENT_VALUES_REPORT_REQUEST_RECEIVED';
export const MONTHLY_EVENT_VALUES_REPORT_REQUEST_ERROR = 'MONTHLY_EVENT_VALUES_REPORT_REQUEST_ERROR';
export const MONTHLY_EVENT_VALUES_REPORT_UNLOAD = 'MONTHLY_EVENT_VALUES_REPORT_UNLOAD';

export const SITE_VISITS_LIST_REQUEST = "SITE_VISITS_LIST_REQUEST";
export const SITE_VISITS_LIST_REQUEST_RECEIVED = "SITE_VISITS_LIST_REQUEST_RECEIVED";
export const SITE_VISITS_LIST_UNLOAD = "SITE_VISITS_LIST_UNLOAD";
export const SITE_VISITS_LIST_ERROR = "SITE_VISITS_LIST_ERROR";


export const COMPANY_BRANCH_LIST_REQUEST = "COMPANY_BRANCH_LIST_REQUEST";
export const COMPANY_BRANCH_LIST_RECEIVED_REQUEST = "COMPANY_BRANCH_LIST_RECEIVED_REQUEST";
export const COMPANY_BRANCH_LIST_RECEIVED_ERROR = "COMPANY_BRANCH_LIST_RECEIVED_ERROR";
export const COMPANY_BRANCH_REQUEST = "COMPANY_BRANCH_REQUEST";
export const COMPANY_BRANCH_CREATED = "COMPANY_BRANCH_CREATED";
export const COMPANY_BRANCH_REQUEST_RECEIVED = "COMPANY_BRANCH_REQUEST_RECEIVED";
export const COMPANY_BRANCH_REQUEST_RECEIVED_ERROR = "COMPANY_BRANCH_REQUEST_RECEIVED_ERROR";
export const COMPANY_BRANCHES_UNLOAD = "COMPANY_BRANCHES_UNLOAD";
export const COMPANY_BRANCH_UNLOAD = "COMPANY_BRANCH_UNLOAD";

export const DISABLE_COMPANY_USER_REQUEST = "DISABLE_COMPANY_USER_REQUEST";
export const DISABLE_COMPANY_USER_REQUEST_RECEIVED = "DISABLE_COMPANY_USER_REQUEST_RECEIVED";
export const DISABLED_COMPANY_USER_REQUEST_ERROR = "DISABLED_COMPANY_USER_REQUEST_ERROR";

export const HOTEL_GROUPS_LIST_REQUEST = "HOTEL_GROUPS_LIST_REQUEST";
export const HOTEL_GROUPS_LIST_REQUEST_RECEIVED = "HOTEL_GROUPS_LIST_REQUEST_RECEIVED";
export const HOTEL_GROUPS_UNLOAD = "HOTEL_GROUPS_UNLOAD";
export const HOTEL_GROUPS_LIST_ERROR = "HOTEL_GROUPS_LIST_ERROR";

export const HOTEL_GROUP_SINGLE_REQUEST = "HOTEL_GROUP_SINGLE_REQUEST";
export const HOTEL_GROUP_SINGLE_REQUEST_RECEIVED = "HOTEL_GROUP_SINGLE_REQUEST_RECEIVED";
export const HOTEL_GROUP_SINGLE_ERROR = "HOTEL_GROUP_SINGLE_ERROR";
export const HOTEL_GROUP_SINGLE_UNLOAD = "HOTEL_GROUP_SINGLE_UNLOAD";

export const HOTEL_GROUP_VENUES_REQUEST = "HOTEL_GROUP_VENUES_REQUEST";
export const HOTEL_GROUP_VENUES_REQUEST_RECEIVED = "HOTEL_GROUP_VENUES_REQUEST_RECEIVED";
export const HOTEL_GROUP_VENUES_ERROR = "HOTEL_GROUP_VENUES_ERROR";
export const HOTEL_GROUP_VENUES_UNLOAD = "HOTEL_GROUP_VENUES_UNLOAD";


export const HOTEL_GROUP_TYPES_REQUEST = "HOTEL_GROUP_TYPES_REQUEST";
export const HOTEL_GROUP_TYPES_REQUEST_RECEIVED = "HOTEL_GROUP_TYPES_REQUEST_RECEIVED";
export const HOTEL_GROUP_TYPES_ERROR = "HOTEL_GROUP_TYPES_ERROR";
export const HOTEL_GROUP_TYPES_UNLOAD = "HOTEL_GROUP_TYPES_UNLOAD";

export const VENUE_SINGLE_REQUEST = "VENUE_SINGLE_REQUEST";
export const VENUE_SINGLE_REQUEST_RECEIVED = "VENUE_SINGLE_REQUEST_RECEIVED";
export const VENUE_SINGLE_ERROR = "VENUE_SINGLE_ERROR";


export const HOTEL_GROUP_ACCOUNT_MANAGER_REQUEST = "HOTEL_GROUP_ACCOUNT_MANAGER_REQUEST";
export const HOTEL_GROUP_ACCOUNT_MANAGER_REQUEST_RECEIVED = "HOTEL_GROUP_ACCOUNT_MANAGER_REQUEST_RECEIVED";
export const HOTEL_GROUP_ACCOUNT_MANAGER_ERROR = "HOTEL_GROUP_ACCOUNT_MANAGER_ERROR";

export const LAST_SIMPLE_SEARCH = 'LAST_SIMPLE_SEARCH'

export const AGENCY_FINANCIALS_REQUEST = 'AGENCY_FINANCIALS_REQUEST';
export const AGENCY_FINANCIALS_RECEIVED = 'AGENCY_FINANCIALS_RECEIVED';
export const AGENCY_FINANCIALS_RECEIVED_ERROR = 'AGENCY_FINANCIALS_RECEIVED_ERROR';
export const AGENCY_FINANCIALS_CREATE_RECEIVED = 'AGENCY_FINANCIALS_CREATE_RECEIVED';
export const AGENCY_FINANCIALS_EDIT_RECEIVED = 'AGENCY_FINANCIALS_EDIT_RECEIVED';
export const AGENCY_FINANCIALS_UNLOAD = 'AGENCY_FINANCIALS_UNLOAD';

export const INVOICE_PDFS_RECEIVED_REQUEST = 'INVOICE_PDFS_RECEIVED_REQUEST'
export const INVOICE_PDFS_REQUEST = 'INVOICE_PDFS_REQUEST'
export const INVOICE_PDFS_RECEIVED_ERROR = 'INVOICE_PDFS_RECEIVED_ERROR'
export const UNLOAD_INVOICE_PDFS = 'UNLOAD_INVOICE_PDFS'

export const CASH_FLOW_REPORT_REQUEST = "CASH_FLOW_REPORT_REQUEST";
export const CASH_FLOW_REPORT_REQUEST_RECEIVED = "CASH_FLOW_REPORT_REQUEST_RECEIVED";
export const CASH_FLOW_REPORT_REQUEST_ERROR = "CASH_FLOW_REPORT_REQUEST_ERROR";
export const CASH_FLOW_REPORT_UNLOAD = "CASH_FLOW_REPORT_UNLOAD";

export const ANNUAL_REPORT_REQUEST = "ANNUAL_REPORT_REQUEST";
export const ANNUAL_REPORT_REQUEST_RECEIVED = "ANNUAL_REPORT_REQUEST_RECEIVED";
export const ANNUAL_REPORT_REQUEST_ERROR = "ANNUAL_REPORT_REQUEST_ERROR";
export const ANNUAL_REPORT_UNLOAD = "ANNUAL_REPORT_UNLOAD";
