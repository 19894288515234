import React from "react";
import { string, func, object, bool, arrayOf, shape, number } from "prop-types";
import { ButtonSpan, ButtonPrimary } from "Components/Button";
import { ApiForm, Succeeded, Failed } from "Components/Form";
import Loading from "Components/Loading";
import Modal from "Components/Modal";
import OfferFields from "./OfferFields";
class Form extends React.Component {
  static propTypes = {
    venuePermissionToCheck: string,
    formId: string.isRequired,
    url: string.isRequired,
    closeModal: func.isRequired,
    closeModalRefresh: func.isRequired,
    showBookingRange: bool,
    data: object,
    params: object,
    venueOptions: arrayOf(
      shape({
        id: number.isRequired,
        name: string.isRequired,
      })
    ),
  };

  fields = () => {
    const all = [
      "id",
      "title",
      "venues",
      "content",
      "valid_from",
      "valid_to",
      "phone_number",
      "email",
      "terms_and_conditions",
      "files",
      "image",
      "image_id",
    ];
    return this.props.showBookingRange
      ? all.concat(["valid_booking_to", "valid_booking_from"])
      : all;
  };

  render() {
    return (
      <Modal>
        <div className="modal">
          <div
            className="modal-inner"
            style={{
              width: this.props.width || 1200,
              maxWidth: "90%",
            }}
          >
            <ButtonSpan
              className="m-3 absolute pin-t pin-r"
              onClick={this.props.closeModal}
            >
              Close
              <i className="fas fa-times ml-3" />
            </ButtonSpan>
            <ApiForm
              create={!this.props.data}
              data={this.props.data}
              form={this.props.formId}
              url={this.props.url}
              params={this.props.params}
              fields={this.fields()}
              pluckTheIds={["venues"]}
              appendIdToPatch
              handleResponse={this.props.closeModalRefresh}
              render={(submitting, failed, succeeded) => (
                <React.Fragment>
                  <OfferFields
                    formId={this.props.formId}
                    showBookingRange={this.props.showBookingRange}
                    venuePermissionToCheck={this.props.venuePermissionToCheck}
                    venueOptions={this.props.venueOptions}
                  />
                  <div className="text-right">
                    <ButtonPrimary type="submit">
                      {submitting ? <Loading inline /> : "Save"}
                    </ButtonPrimary>
                  </div>
                  {succeeded ? <Succeeded /> : null}
                  {failed ? <Failed /> : null}
                </React.Fragment>
              )}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export default Form;
