import React, { useEffect, useState } from "react";
import axios from "axios";
import { ButtonPrimary, ButtonRed, defaultClasses } from "../Button";
import Loading from "../Loading";
import { Error } from "../Form";
import * as VENUE_ROUTES from "../../Router/venue_routes";
import { Link, useHistory } from "react-router-dom";

export default function StripeForm({ licenceType,
                                     priceComponent, onSuccess, venue, email, token,
                                      setDiscountedPrice, setDiscountDescription, discountDescription }) {
  const [stripe, setStripe] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [elements, setElements] = useState(null);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  const [loadingStripe, setLoadingStripe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [discountCode, setDiscountCode] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);

  const [subscriptionEmail, setSubscriptionEmail] = useState(email || null);
  const history = useHistory();

  useEffect(() => {
    void createStripeSession();
  }, []);

  useEffect(() => {
    try {
      if (!loadingStripe) {
        if (!clientSecret) {
          // showMessage("Failed to retrieve client secret.");
          return;
        }

        setStripe(window.stripeInstance);

        const elementsInstance = window.stripeInstance.elements({ clientSecret });
        const paymentElement = elementsInstance.create("payment", {
          clientSecret: clientSecret,
        });

        paymentElement.mount("#payment-element");
        setElements(elementsInstance);
      }
    } catch (error) {
      showMessage("Failed to initialize payment: " + error.message);
    }
  }, [clientSecret, loadingStripe]);

  if ( !licenceType || !licenceType.requires_payment || !licenceType?.product?.price?.price_id) {
    return <Error message="Invalid Licence Type Supplied." />;
  }
  const stripePriceId = licenceType.product.price.price_id;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingState(true);

    if (!stripe || !elements) {
      showMessage("Stripe has not been properly initialized.");
      setLoadingState(false);
      return;
    }

    if (!subscriptionEmail) {
      setError("Please enter a valid email address to be associated with your subscription.");
      setLoadingState(false);
      return;
    }

    if (!termsAccepted) {
      setError("Please accept the terms and conditions to proceed.");
      setLoadingState(false);
      return;
    }

    try {
      const { setupIntent, error } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: "https://www.edgevenues.com",
        },
        redirect: "if_required",
      });

      if (error) {
        setError(error.message);
        setLoadingState(false);
        return;
      }

      const response = await axios.post(
        `${window.API}${VENUE_ROUTES.API.SELF_SERVICE.STRIPE.CREATE_SUBSCRIPTION.replace(":venueSlug", venue.slug)}`,
        {
          payment_method_id: setupIntent.payment_method,
          discount_code: discountCode,
          subscriptionEmail,
          price_id: stripePriceId
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      const { subscriptionStatus, subscription } = response.data;

      showMessage("Subscription created successfully.");

      if (onSuccess) {
        onSuccess(subscription, subscriptionStatus)
      }
    } catch (error) {
      showMessage("Failed to create subscription: " + error.message);
      setLoadingState(false);
    }
  };

  const createStripeSession = async () => {
    setLoadingStripe(true);
    try {
      const response = await axios.post(
        `${window.API}${VENUE_ROUTES.API.SELF_SERVICE.STRIPE.CREATE_SESSION.replace(":venueSlug", venue.slug)}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const { clientSecret, subscriptionExists, subscriptionId } = response.data;
      setClientSecret(clientSecret);
      setLoadingStripe(false);
      if (subscriptionExists) {
        history.push(VENUE_ROUTES.FRONTEND.MANAGE_VENUE_LICENCE.PAYMENT_REDIRECT.replace(":venueSlug", venue.slug).replace(":status", "success"),
          { subscriptionId, venue },
        );
      }
      if (clientSecret && token) {
        history.push(VENUE_ROUTES.FRONTEND.MANAGE_VENUE_LICENCE.UPGRADE, {
          clientSecret,
          venue,
        });
      }
    } catch (error) {
      setLoadingStripe(false);
      setMessage("Failed to initialize payment: " + error.message);
    }
    setLoadingStripe(false);
  };

  const handleDiscountApply = async () => {
    try {
      const response = await axios.post(`${window.API}${VENUE_ROUTES.API.SELF_SERVICE.STRIPE.APPLY_DISCOUNT.replace(':venueSlug', venue?.slug)}`, {
        discount_code: discountCode,
        lt_code: licenceType.code
      });
      // console.log({response});
      if (typeof response.data.description !== "undefined") {
        setDiscountDescription(response.data.description);
      } else {
        setDiscountDescription(null);
      }

      if (typeof response.data.discountedPrice !== "undefined") {
        setDiscountedPrice(response.data.discountedPrice / 100); // convert to GBP
      } else {
        setDiscountedPrice(null);
        setError(response.data.error);
      }
    } catch (error) {
      setDiscountedPrice(null);
      setError(error?.response?.data?.error ?? error.message);
    }
  };

  const showMessage = (messageText) => {
    setMessage(messageText);
    setTimeout(() => {
      setMessage("");
    }, 4000);
  };

  const setLoadingState = (isLoading) => {
    setLoading(isLoading);
  };

  const handleTermsChange = (event) => {
    setTermsAccepted(event.target.checked);
    if (event.target.checked) {
      setError(null);
    }
  };

  const handleRemoveDiscount = () => {
    setDiscountCode("");
    setDiscountDescription(null);
    setDiscountedPrice(null);
    setError(null);
  };
  return <>
    {priceComponent && priceComponent}

    <div className="flex flex-col p-5 space-y-5">

      <div className="flex mt-5 flex-col sm:flex-row sm:items-center space-y-2 sm:space-y-0 sm:space-x-3">
        <label htmlFor="discountCode" className="font-mediumm screen-reader sm:w-1/4">Subscription Email</label>
        <input
          type="text"
          id="subscriptionEmail"
          placeholder="Enter subscription email"
          value={subscriptionEmail}
          onChange={(e) => setSubscriptionEmail(e.target.value)}
          className="py-3 border border-grey-md w-full outline-none font-normal text-black text-sm focus:border-primary bg-white sm:w-1/2"
        />
      </div>

      <div className="flex mt-5 flex-col sm:items-center space-y-2 sm:space-y-0">
        <div className="w-full flex flex-col sm:flex-row sm:items-center sm:space-x-3">
          <label htmlFor="discountCode" className="font-mediumm sm:w-1/4">
            Discount Code
          </label>
          <input
            type="text"
            id="discountCode"
            placeholder="Enter discount code"
            value={discountCode}
            onChange={(e) => setDiscountCode(e.target.value)}
            className="py-3 border border-grey-md w-full outline-none font-normal text-black text-sm focus:border-primary bg-white sm:w-1/2"
          />
          <ButtonPrimary onClick={() => handleDiscountApply()} classes={"ml-2"}>
            Apply
          </ButtonPrimary>
          {discountDescription && (
            <ButtonRed onClick={handleRemoveDiscount} classes={"ml-2"}>
              Remove
            </ButtonRed>
          )}
        </div>
      </div>
    </div>

    {loadingStripe ? <Loading /> : null}

    <form id="payment-form" onSubmit={handleSubmit}>
      <div className="p-5">
        <div id="payment-element"></div>
        <div className="flex w-2/3 items-center my-4">
          <input
            type="checkbox"
            id={"termsAccepted"}
            checked={termsAccepted}
            onChange={handleTermsChange}
            className="form-checkbox h-3 w-3 text-primary"
          />
          <label className="font-medium ml-4" htmlFor={"termsAccepted"}>
            Accept{" "} <Link to={"/files/edge-terms-of-service.pdf"} target={"_blank"}><strong>terms and
            conditions</strong></Link>
          </label>
        </div>
        <button
          id="submit"
          disabled={loading}
          className={`${defaultClasses.join(" ")} text-white my-4 py-5 border-primary bg-primary hover:bg-dark-blue hover:text-white hover:border-dark-blue h-6 ${loading ? "pointer-events-none" : ""}`}>
          <div id="spinner" className={loading ? "" : "hidden"}><Loading /></div>
          <span id="button-text" className={loading ? "hidden" : ""}>Pay</span>
        </button>
      </div>
      {error &&
        <div className={"px-5"}>
          <Error
            touched
            error={error}
          />
        </div>
      }
    </form>
    <div id="payment-message" className={message ? "" : "hidden"}>
      {message}
    </div>
  </>;
}