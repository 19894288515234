import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { string, object, array, func, bool } from "prop-types";
import { token } from "modules/auth";
import Loading from "Components/Loading";
import { ButtonBrand } from "Components/Button";
import FormModal from "./FormModal";
import hydrate from "./hydrate";
import deleteById from "./delete";
import articleResult from "./articleResult";
import { hasAnyVenueWithPermission } from "../../../modules/permissions";
import { FRONTEND } from "../../../Router/venue_routes";

class ArticlesForm extends React.Component {
  static propTypes = {
    hasRelevantVenues: bool.isRequired,
    formId: string.isRequired,
    url: string.isRequired,
    token: string.isRequired,
    history: object.isRequired,
    location: object.isRequired,
    params: object,
    title: string,
    additionalFields: array,
    renderAdditionalFields: func,
    venuePermissionToCheck: string,
  };

  state = {
    fetching: true,
    data: [],
    error: false,
    modal: undefined,
    formData: undefined,
  };

  componentDidMount() {
    hydrate(this.props, this.stateHelpers);
  }

  stateHelpers = {
    done: data =>
      this.setState({
        fetching: false,
        data,
        error: false,
      }),
    error: () =>
      this.setState({
        fetching: false,
        error: true,
      }),
  };

  deleteById = id => {
    deleteById(
      this.props,
      {
        complete: data =>
          this.setState({
            data,
          }),
      },
      id
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.props.title && <h1 className="mb-5">{this.props.title}</h1>}

        {
          <div className="w-full md:w-3/4 lg:w-1/2 xl:w-2/5 mb-10">
            <p>{this.props.intro}</p>
          </div>
        }

        {/* SHOW ADD BUTTON ON TOP IF LOTS OF ARTICLES ARE HIDING THE ONE BELOW */}
        {this.state.data.length > 5 ? (
          <div className="mb-5 text-right">
            <ButtonBrand
              onClick={() =>
                this.setState({
                  modal: "new",
                  modalData: undefined,
                })
              }
            >
              Add new
            </ButtonBrand>
          </div>
        ) : null}

        {this.state.error ? (
          <p className="text-warning">
            Sorry, there was a problem fetching your data
          </p>
        ) : null}

        {this.state.data.length ? (
          /* RENDER PUBLISHED ITEMS */
          this.state.data.map(item => (
            <div key={item.id}>
              {articleResult(
                item,
                () =>
                  this.setState({
                    modal: item.id,
                    modalData: item,
                  }),
                () => this.deleteById(item.id)
              )}
            </div>
          ))
        ) : (
          <div>
            {this.state.fetching ? (
              <Loading large />
            ) :
              this.props.hasRelevantVenues && (<p>You haven't published anything yet</p>)
            }
          </div>
        )}

        {/* ADD ANOTHER */}
        {this.props.hasRelevantVenues ? (
        <div className="mt-5 text-right">
          <ButtonBrand
            onClick={() =>
              this.setState({
                modal: "new",
                modalData: undefined,
              })
            }
          >
            Add new
          </ButtonBrand>
        </div>) : <p>You have no venues of the appropriate licence type to add this content. <a href={FRONTEND.MANAGE_VENUE_LICENCE.INDEX} className="text-blue font-bold underline">Click here to upgrade</a></p>}

        {/* THE FORM MODAL */}
        {this.state.modal ? (
          <FormModal
            formId={this.props.formId}
            url={this.props.url}
            venuePermissionToCheck={this.props.venuePermissionToCheck}
            data={this.state.modalData}
            params={this.props.params}
            width={this.props.modalWidth}
            height={this.props.modalHeight}
            additionalFields={this.props.additionalFields}
            renderAdditionalFields={this.props.renderAdditionalFields}
            closeModalRefresh={data => {
              this.setState({ data, modal: undefined, modalData: undefined });
            }}
            closeModal={() => {
              this.setState({ modal: false, modalData: false });
            }}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect((state,props) => ({
    token: token(state),
    hasRelevantVenues: hasAnyVenueWithPermission(state.auth, props.venuePermissionToCheck)
  }))(ArticlesForm)
);