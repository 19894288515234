import React, {Fragment, useState} from "react";
import {connect} from "react-redux";
import axios from "axios";
import Loading from "Components/Loading";
import {ButtonBrand} from "../../../../../Components/Button";
import {token} from "../../../../../modules/auth";

const Hubspot = ({company, loading, error, token}) => {
    const [hubspotId, setHubspotId] = useState(company?.hubspot_account_details?.hubspot_company_identifier ?? null);
    const [isSaving, setIsSaving] = useState(false);
    if (loading) {
        return (
            <div>
                <Loading/>
            </div>
        );
    }

    if (error) {
        return (
            <div>
                <p>Error loading venue data</p>
            </div>
        );
    }
    const save = () => {
        setIsSaving(true);
        axios.patch(
            `${process.env.REACT_APP_API}/company/${company.id}/hubspot`,
            {
                companyId: hubspotId,
            },
            {
                headers: {Authorization: `Bearer ${token}`},
            }
        ).then(response => {
            setIsSaving(false);

        })
            .catch(e => {

                setIsSaving(false);
            });
    }

    const idChanged = company?.hubspot_account_details?.hubspot_company_identifier !== hubspotId;

    return (
        <div>
            {company && (
                <Fragment>
                    <label>
                        Hubspot Company id
                        <input className='p-2 mb-2 border w-full outline-none font-normal text-black focus:border-primary bg-white rounded-none appearance-none border-grey-md' type={'text'}
                               name={'Hubspot company id'} maxLength={32} value={hubspotId} onChange={(e) => setHubspotId(e.target.value)}/>
                    </label>
                    {idChanged && !isSaving ? <ButtonBrand onClick={() => save()}>Save</ButtonBrand> : null}
                    {idChanged && isSaving ? <Loading/> : null}
                </Fragment>
            )}
        </div>
    );
};

const mapStateToProps = state => ({
    ...state.company,
    token: token(state),
});
export default connect(mapStateToProps, null)(Hubspot);
