import axios from "axios";

const getVenueLicenceSettings = async () => {
  try {
    const response = await axios.get(`${window.API}/venue-licence-settings`);
    return { result: response.data };
  } catch (error) {
    return { error };
  }
};

export { getVenueLicenceSettings };
