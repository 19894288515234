import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import reduxWindow from "redux-window";
import { reducer as auth } from "modules/auth";
import { reducer as articles } from "modules/articles";
import { reducer as namedLocations } from "modules/named-locations";
import { reducer as analytics } from "modules/analytics";
import {
  agency,
  agencyBookingDesks,
  agencyFinancials,
  agencyList,
  agencyUser,
  bookedEvent,
  bookedEvents,
  businessUnits,
  company,
  companyBranches,
  companyList,
  companyUsers,
  corporateAccount,
  corporateAccountDocument,
  corporateAccountDocumentsCategoryList,
  corporateAccountDocumentsList,
  corporateAccountList,
  corporateAccountLocation,
  corporateAccountLocationsList,
  corporateAccountPermissions,
  corporateAccountUsers,
  corporateBookingDesks,
  corporateDepartment,
  corporateDepartments,
  countries,
  draftEnquiry,
  enquiryLogReport,
  eventStatus,
  hotelGroup,
  hotelGroups,
  hotelGroupTypes,
  hotelGroupVenues,
  industryEvents,
  monthlyEventValuesReport,
  nominalCodes,
  notes,
  proposal,
  publicEvents,
  reportingFigures,
  roles,
  search,
  selectedAgencies,
  shortlist,
  singleUser,
  siteVisits,
  snapshotReport,
  spendReport,
  cashFlowReport,
  userInvite,
  userInvites,
  vatRate,
  venues, annualReport,
} from "redux/reducers/reducer";
import { reducer as brand } from "modules/brand";
import { reducer as industries } from "modules/industries";
import { invoicePdfs } from "./redux/reducers/invoicePdfs";

export default combineReducers({
  form,
  auth,
  brand,
  reduxWindow,
  articles,
  namedLocations,
  analytics,
  companyList,
  company,
  countries,
  agencyList,
  agency,
  agencyFinancials,
  agencyUser,
  roles,
  vatRate,
  singleUser,
  notes,
  industries,
  corporateAccountList,
  corporateAccount,
  corporateAccountDocument,
  corporateAccountDocumentsList,
  corporateAccountDocumentsCategoryList,
  publicEvents,
  industryEvents,
  bookedEvents,
  bookedEvent,
  nominalCodes,
  reportingFigures,
  corporateBookingDesks,
  corporateAccountUsers,
  corporateDepartments,
  corporateDepartment,
  userInvite,
  userInvites,
  corporateAccountLocationsList,
  corporateAccountLocation,
  eventStatus,
  draftEnquiry,
  venues,
  proposal,
  shortlist,
  enquiryLogReport,
  snapshotReport,
  spendReport,
  companyUsers,
  monthlyEventValuesReport,
  siteVisits,
  agencyBookingDesks,
  businessUnits,
  companyBranches,
  selectedAgencies,
  corporateAccountPermissions,
  hotelGroups,
  hotelGroup,
  hotelGroupVenues,
  hotelGroupTypes,
  search,
  cashFlowReport,
  invoicePdfs,
  annualReport
});