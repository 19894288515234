import React, { Fragment } from "react";
import { reduxForm, Field, change } from "redux-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { func, number, shape, oneOf, arrayOf, string } from "prop-types";
import {
  Input,
  Textarea,
  Failed,
  DateRange,
  Date,
  Select,
} from "Components/Form";
import { ButtonPrimary, ButtonSpan } from "Components/Button";
import Loading from "Components/Loading";
import { required, optional, int } from "modules/validation";
import { token, user } from "modules/auth";
import { venueInteraction } from "modules/analytics";
import submit from "./submit";
import BuyItNowGetCard from "./GetBilling";

import {
  EnquiryModal,
  Catering,
  Equipment,
  VenueSearch,
  MeetingHost,
  EventName,
  MeetingRooms,
  AdditionalInfo,
  Attachments,
  Contact,
} from "./components";

const v = {
  required: required(),
  optionalInt: optional(int),
};

class RequestForQuote extends React.Component {
  state = {
    showBilling: false,
    billingComplete: false,
    billingSubmitting: false,
    billingSuccess: false,
    hasBillingDetails: false,
    formValues: {},
  };

  static propTypes = {
    closeModal: func.isRequired,
    initialValues: shape({
      type: oneOf([
        "Buy it now",
        "Day delegate rate",
        "24hr delegate rate",
        "General",
      ]),
      venues: arrayOf(number),
      venues_text: arrayOf(string),
    }),
  };

  UNSAFE_componentWillMount() {
    this.props.initialize(
      {
        ...this.props.initialValues,
        email: (this.props.user && this.props.user.email) || "",
        phone: (this.props.user && this.props.user.phone) || "",
      },
      {
        updateUnregisteredFields: true,
      }
    );

    this.setState({ hasBillingDetails: !!this.props.user.hasBillingDetails });
    // if (this.props.initialValues.type !== "Buy it now") {
    //   this.props.change("request-for-quote", "syndicate_rooms", [
    //     { layout: null, back_projection: null }
    //   ]);
    // }
  }

  submitAfterBilling = () => {
    this.setState(
      {
        billingComplete: true,
        billingSubmitting: true,
      },
      () =>
        this.submit(this.state.formValues)
          .then(() =>
            this.setState({
              billingSuccess: true,
              billingSubmitting: false,
            })
          )
          .catch(() => {
            window.alert(
              "Sorry, there was an issue completing the Buy It Now."
            );
          })
    );
  };

  submit = values => {
    // const { initialValues } = this.props;
    // const { billingComplete } = this.state;
    // const buyItNow = initialValues.type === "Buy it now";
    return submit(values, this.props, {
      onlyValidate: false,
    }).then(() => {

    });
  };

  billingError = () => {
    this.setState({ showBilling: false, billingSubmitting: false }, () => {
      window.alert("Sorry, there was an issue completing the Buy It Now.");
    });
  };

  billingClose = () => {
    const { billingSuccess } = this.state;

    if (billingSuccess) {
      this.props.closeModal();
      return;
    }

    if (
      window.confirm(
        "Billing details are required to complete the Buy It Now. Are you sure you want to cancel?"
      )
    ) {
      this.props.closeModal();
    }
  };

  showBilling = values =>
    this.setState({ showBilling: true, formValues: values });

  renderBilling = () => {
    const { billingSuccess, billingSubmitting } = this.state;
    const { hasBillingDetails } = this.state;

    return (
      <EnquiryModal closeModal={this.billingClose}>
        <div className="mt-10 p-6">
          <p>
            Thank you for your booking this will be booked on your behalf and
            this will be a confirmed booking. This rate is offered on the basis
            of a one day room hire, subject to availability and on confirming
            availability and additional pricing a credit card number will be
            required to confirm the booking and secure the space. No monies will
            be deducted from the credit card unless there is a no show on the
            day. All other aspects of the booking will be dealt with directly
            with the venue.
          </p>
          <p>
            On submission of this you are agreeing to our terms and conditions
            and we will secure your booking and revert within 2 hours during
            normal working hours.
          </p>
          {billingSuccess ? (
            <div className="text-right py-3">
              Thank you for your enquiry, we will be in touch shortly
              <ButtonSpan
                className="text-primary ml-2"
                onClick={this.props.closeModal}
              >
                return to website
                <i className="fal fa-arrow-right ml-2" />
              </ButtonSpan>
            </div>
          ) : null}

          {!billingSuccess && hasBillingDetails ? (
            <div>
              <p>
                You have previously added billing details to your Edge Venues
                account so no further details are required.
              </p>
              <div className="text-right">
                <ButtonPrimary onClick={this.submitAfterBilling}>
                  {billingSubmitting ? <Loading inline /> : "Accept"}
                </ButtonPrimary>
              </div>
            </div>
          ) : null}

          {!billingSuccess && !hasBillingDetails ? (
            <BuyItNowGetCard
              onSuccess={this.submitAfterBilling}
              onError={this.billingError}
              receivedToken={() => this.setState({ billingSubmitting: true })}
            >
              <div className="text-right">
                <ButtonPrimary>
                  {billingSubmitting ? (
                    <Loading inline />
                  ) : (
                    "Add Billing Details"
                  )}
                </ButtonPrimary>
              </div>
            </BuyItNowGetCard>
          ) : null}
        </div>
      </EnquiryModal>
    );
  };

  render() {
    const { closeModal, initialValues } = this.props;
    const buyItNow = initialValues.type === "Buy it now";

    if (this.state.showBilling) return this.renderBilling();

    return (
      <EnquiryModal
        closeModal={closeModal}
        onClickLogo={() => this.setState({ showBilling: true })}
      >
        <form noValidate onSubmit={this.props.handleSubmit(this.submit)}>
          <EventName />
          <div className="flex flex-wrap -mx-2">
            <div className="w-full lg:w-1/3 flex flex-wrap px-2">
              {buyItNow ? (
                <Fragment>
                  <Field
                    label="Event Date"
                    name="start_date"
                    size="sm"
                    component={Date}
                    validate={v.required}
                  />
                </Fragment>
              ) : (
                <DateRange
                  formId="request-for-quote"
                  fromLabel="Date from"
                  toLabel="Date to"
                  startName="start_date"
                  endName="end_date"
                  validate={v.required}
                />
              )}
              <div className="lg:w-1/2">
                <Field
                  name="total_delegates"
                  label="No. Delegates"
                  size="sm"
                  validate={int}
                  options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(value => ({
                    value,
                    text: String(value),
                  }))}
                  component={buyItNow ? Select : Input}
                />
              </div>
              {buyItNow && <MeetingHost />}
              {!buyItNow && (
                <Field
                  name="total_bedrooms"
                  label="No. Bedrooms"
                  component={Input}
                  size="sm"
                  validate={v.optionalInt}
                />
              )}
            </div>
            <div className="w-full lg:w-2/3 px-2">
              <AdditionalInfo />
            </div>
          </div>

          {buyItNow ? (
            <VenueSearch label="Venue" />
          ) : (
            <Fragment>
              <VenueSearch label="Either search and select a venue..." />
              <Field
                name="location"
                label="...or describe the location/venue you are looking for"
                component={Textarea}
              />
            </Fragment>
          )}

          {!buyItNow && <MeetingRooms />}

          <Catering buyItNow={buyItNow} />
          <div className="flex flex-wrap">
            <div className="w-full lg:w-1/2">
              <Equipment />
              {!buyItNow && <Attachments />}
            </div>
            <div className="w-full lg:w-1/2 flex flex-col justify-end">
              <Contact />
            </div>
          </div>

          {!this.props.submitSucceeded ? (
            <div className="text-right">
              <ButtonPrimary type="submit">
                {this.props.submitting ? <Loading inline /> : "Submit"}
              </ButtonPrimary>
            </div>
          ) : null}
          {this.props.submitSucceeded ? (
            <div className="text-right py-3">
              Thank you for your enquiry, we will be in touch shortly
              <ButtonSpan
                className="text-primary ml-2"
                onClick={this.props.closeModal}
              >
                return to website
                <i className="fal fa-arrow-right ml-2" />
              </ButtonSpan>
            </div>
          ) : null}
          {this.props.submitFailed ? <Failed text={this.props.error} /> : null}
        </form>
      </EnquiryModal>
    );
  }
}

export default withRouter(
  reduxForm({
    form: "request-for-quote",
  })(
    connect(
      state => ({
        token: token(state),
        user: user(state),
      }),
      { change, venueInteraction }
    )(RequestForQuote)
  )
);
