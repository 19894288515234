import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { reduxForm, getFormValues } from "redux-form";
import ConfirmedEventInformation from "../../pages/admin/corporate/events/components/ConfirmedEventInformation";
import CompletedEventInformation from "../../pages/admin/corporate/events/components/CompletedEventInformation";
import { ButtonPrimary, ButtonPrimaryRed } from "../Button";
import Loading from "../Loading";
import {
  isAgency,
  isInternal,
  token as tokenSelector,
  token,
  isCorporate,
  userAgency,
  isAgencyUser,
  isAgencyAdmin, isRolePlatformAdmin,
} from "../../modules/auth";
import {
  completeEvent,
  deleteSingleEventConfirmDocument,
  eventsSingleUnload, fetchAgencyFinancials, fetchVatRates, invoiceEvent,
  storeSingleBookedEventConfirmation,
} from "../../redux/actions";
import { useHistory } from "react-router";
import { WhitePanelWithHeader } from "../../ui";
import FinalCommissionValuesModal from "../../pages/admin/corporate/events/components/FinalCommissionValuesModal";
import useService from "../../hooks/useService";
import { getEnquiryById } from "../../services/rfpService";
import Warning from "../Warning";
import BookedEventsDownloadButton from "./BookedEventsDownloadButton";
import moment from "moment";
import { Failed, Succeeded } from "../Form";
import { isCompanyAdmin, isCompanyUser, isEdgeFinance, isRoleEdgeAdmin } from "../../modules/permissions";
import RFPCancelModalForm from "../Rfp/RFPCancelModalForm";
import { BOOKED_EVENT_STATUS, ENQUIRY_STATUS } from "../../constants";
import CarbonImpactEventInformation from "../../pages/admin/corporate/events/components/CarbonImpactEventInformation";
import { checkHasGBPFinancialData } from "../../utils";
import InvoicePdfs from "./InvoicePdfs";

const mapStateToProps = (state, props) => {
  let delegate_text = null;
  let attendee_count = null;
  let invoice_amount_net = state.bookedEvent.bookedEvent?.enquiry_venue_datas?.proposal_data?.summary?.summary_items.find(item => item.step_id === 9)?.total_exc_vat;
  let invoice_amount_net_src_cur = state.bookedEvent.bookedEvent?.enquiry_venue_datas?.currency_id ? state.bookedEvent.bookedEvent?.enquiry_venue_datas?.proposal_data?.summary?.summary_items.find(item => item.step_id === 9)?.total_exc_vat_src_cur : null;
  let invoice_amount_gross = state.bookedEvent.bookedEvent?.enquiry_venue_datas?.proposal_data?.summary?.summary_items.find(item => item.step_id === 9)?.total_inc_vat;
  let invoice_amount_gross_src_cur = state.bookedEvent.bookedEvent?.enquiry_venue_datas?.currency_id ? state.bookedEvent.bookedEvent?.enquiry_venue_datas?.proposal_data?.summary?.summary_items.find(item => item.step_id === 9)?.total_inc_vat_src_cur : null;
  let invoice_amount_vat = parseFloat(invoice_amount_gross - invoice_amount_net).toFixed(2);
  let invoice_amount_vat_src_cur = parseFloat(invoice_amount_gross_src_cur - invoice_amount_net_src_cur).toFixed(2);
  let estimated_commission_value_net = null;
  let estimated_commission_value_net_src_cur = null;
  let total_commission_value_net = null;
  let total_commission_value_net_src_cur = null;
  let total_commission_value_vat_src_cur = null;
  let total_commission_value_vat = null;
  let total_commission_value_gross = null;
  let total_commission_value_gross_src_cur = null;
  let total_commission_vat_rate_id = null;
  let total_commission_includes_vat = null;
  let total_commission_xero_code = null;
  let estimated_carbon_impact_value = null;
  let total_carbon_impact_value = null;
  let projected_commission_value = state.bookedEvent.bookedEvent?.enquiry_venue_datas?.proposal_data?.commission_items.find(item => item.step_id === 9) ?
    state.bookedEvent.bookedEvent.enquiry_venue_datas.proposal_data.commission_items.find(item => item.step_id === 9).total_commission : null;
  let projected_commission_value_src_cur = state.bookedEvent.bookedEvent?.enquiry_venue_datas?.currency_id && state.bookedEvent.bookedEvent?.enquiry_venue_datas?.proposal_data?.commission_items.find(item => item.step_id === 9) ?
    state.bookedEvent.bookedEvent.enquiry_venue_datas.proposal_data.commission_items.find(item => item.step_id === 9).total_commission_src_cur : null;
  let delegate_upload_option = null;
  if (state.bookedEvent) {
    delegate_text = state.bookedEvent.bookedEvent.delegate_text;
    attendee_count = state.bookedEvent.bookedEvent.attendee_count;
    invoice_amount_net = state.bookedEvent.bookedEvent.invoice_amount_net ? state.bookedEvent.bookedEvent.invoice_amount_net : invoice_amount_net;
    invoice_amount_net_src_cur = state.bookedEvent.bookedEvent.invoice_amount_net_src_cur ? state.bookedEvent.bookedEvent.invoice_amount_net_src_cur : invoice_amount_net_src_cur;
    invoice_amount_vat = state.bookedEvent.bookedEvent.invoice_amount_vat ? state.bookedEvent.bookedEvent.invoice_amount_vat : invoice_amount_vat;
    invoice_amount_vat_src_cur = state.bookedEvent.bookedEvent.invoice_amount_vat_src_cur ? state.bookedEvent.bookedEvent.invoice_amount_vat_src_cur : invoice_amount_vat_src_cur;
    invoice_amount_gross = parseFloat(parseFloat(invoice_amount_net) + parseFloat(invoice_amount_vat)).toFixed(2);
    invoice_amount_gross_src_cur = parseFloat(parseFloat(invoice_amount_net_src_cur) + parseFloat(invoice_amount_vat_src_cur)).toFixed(2);
    total_commission_value_net = state.bookedEvent.bookedEvent.total_commission_value_net;
    total_commission_value_net_src_cur = state.bookedEvent.bookedEvent.total_commission_value_net_src_cur;
    total_commission_value_vat = state.bookedEvent.bookedEvent.total_commission_value_vat;
    total_commission_value_vat_src_cur = state.bookedEvent.bookedEvent.total_commission_value_vat_src_cur;
    total_commission_value_gross = Number(Number(total_commission_value_net) + Number(total_commission_value_vat)).toFixed(2);
    total_commission_value_gross_src_cur = Number(Number(total_commission_value_net_src_cur) + Number(total_commission_value_vat_src_cur)).toFixed(2);
    total_commission_vat_rate_id = state.bookedEvent.bookedEvent.total_commission_vat_rate_id;
    total_commission_includes_vat = state.bookedEvent.bookedEvent.total_commission_includes_vat;
    total_commission_xero_code = state.bookedEvent.bookedEvent ? state.bookedEvent.bookedEvent?.nominal_code?.code : null;
    estimated_commission_value_net = state.bookedEvent.bookedEvent.estimated_commission_value_net ? state.bookedEvent.bookedEvent.estimated_commission_value_net : projected_commission_value;
    estimated_commission_value_net_src_cur = state.bookedEvent.bookedEvent.estimated_commission_value_net_src_cur ? state.bookedEvent.bookedEvent.estimated_commission_value_net_src_cur : projected_commission_value_src_cur;
    delegate_upload_option = state.bookedEvent.bookedEvent.delegate_file_url ? "document" : state.bookedEvent.bookedEvent.delegate_text ? "link" : null;
    estimated_carbon_impact_value = state.bookedEvent.bookedEvent && state.bookedEvent.bookedEvent.estimated_carbon_impact_value ? state.bookedEvent.bookedEvent.estimated_carbon_impact_value : state.bookedEvent.bookedEvent.enquiry_venue_datas?.proposal_data?.carbon_impact?.carbon_impact_value_total;
    total_carbon_impact_value = state.bookedEvent.bookedEvent && state.bookedEvent.bookedEvent.total_carbon_impact_value ? state.bookedEvent.bookedEvent.total_carbon_impact_value : null;
  }
  return {
    token: token(state),
    agencyId: state.bookedEvent?.bookedEvent?.agency_id || state.bookedEvent?.bookedEvent?.company?.agency_id,
    vatRates: state.vatRate.vatRates,
    isCompanyUser: isCompanyUser(state.auth),
    isCompanyAdmin: isCompanyAdmin(state.auth),
    isCorporate: isCorporate(state),
    isInternal: isInternal(state),
    isAgency: isAgency(state),
    userAgency: state?.auth?.user?.agency,
    isAgencyUser: isAgencyUser(state),
    isAgencyAdmin: isAgencyAdmin(state),
    isRolePlatformAdmin: isRolePlatformAdmin(state),
    isEdgeFinance: isEdgeFinance(state.auth),
    ...state.agencyFinancials,
    initialValues: {
      delegate_text: delegate_text,
      attendee_count: attendee_count,
      invoice_amount_net: invoice_amount_net,
      invoice_amount_net_src_cur: invoice_amount_net_src_cur,
      invoice_amount_vat: invoice_amount_vat,
      invoice_amount_vat_src_cur: invoice_amount_vat_src_cur,
      invoice_amount_gross: invoice_amount_gross,
      invoice_amount_gross_src_cur: invoice_amount_gross_src_cur,
      estimated_commission_value_net_src_cur: estimated_commission_value_net_src_cur,
      estimated_commission_value_net: estimated_commission_value_net,
      total_commission_value_net: total_commission_value_net,
      total_commission_value_net_src_cur: total_commission_value_net_src_cur,
      total_commission_value_vat: total_commission_value_vat,
      total_commission_value_vat_src_cur: total_commission_value_vat_src_cur,
      total_commission_value_gross: total_commission_value_gross,
      total_commission_value_gross_src_cur: total_commission_value_gross_src_cur,
      total_commission_vat_rate_id: total_commission_vat_rate_id,
      total_commission_includes_vat: total_commission_includes_vat,
      total_commission_xero_code: total_commission_xero_code,
      delegate_upload_option: delegate_upload_option,
      estimated_carbon_impact_value: estimated_carbon_impact_value,
      total_carbon_impact_value: total_carbon_impact_value,
    },
  };
};

const mapDispatchToProps = {
  storeSingleBookedEventConfirmation,
  deleteSingleEventConfirmDocument,
  eventsSingleUnload,
  invoiceEvent,
  completeEvent,
  fetchVatRates,
  fetchAgencyFinancials,
};

let BookedEventsForm = (props) => {
  let [contractUploadedDocument, setContractUploadedDocument] = useState();
  let [delegateUploadedDocument, setDelegateUploadedDocument] = useState(null);
  let [invoiceUploadedDocument, setInvoiceUploadedDocument] = useState(null);
  let [showCancelModal, setShowCancelModal] = useState(false);
  let [modal, setModal] = useState(false);
  let [success, setSuccess] = useState(false);
  let [submitError, setSubmitError] = useState(false);
  let history = useHistory();
  const token = useSelector(tokenSelector);
  let [submitting, setSubmitting] = useState("");
  const values = useSelector(getFormValues("eventsForm")) || {};
  const { loading, data, error } = useService({
    service: getEnquiryById,
    trigger: props.event.enquiry.id,
    params: props.event.enquiry.id,
  });
  useEffect(() => {
    props.fetchVatRates(token);
  }, [data]);

  useEffect(() => {
    if (props.agencyId && props.agencyId) {
      props.fetchAgencyFinancials(props.agencyId, token);
    }
  }, [props.agencyId]);

  if (loading) {
    return <Loading large />;
  }

  if (error) {
    return <Warning>There was a problem fetching data</Warning>;
  }


  let onSubmit = (formValues) => {
    setSubmitting("form");
    const formData = new FormData();
    Object.entries(formValues).forEach(
      ([key, value]) => value && formData.append(key, value),
    );
    if (contractUploadedDocument) {
      formData.append("contract_document", contractUploadedDocument[0]);

    }
    if (delegateUploadedDocument) {
      formData.append("delegate_document", delegateUploadedDocument[0]);

    }
    if (invoiceUploadedDocument) {
      formData.append("invoice_document", invoiceUploadedDocument[0]);

    }
    props.storeSingleBookedEventConfirmation(props.eventId, props.token, formData).then(
      (response) => {
        setSubmitting(null);
      },
    ).catch(
      (error) => {
        setSubmitError(true);
      },
    );
  };

  let complete = () => {
    setSubmitting("complete");
    props.completeEvent(props.event.id, props.token)
      .then((response) => {
        setSubmitting(null);
        setSuccess(true);
        setTimeout(function() {
          setSuccess(false);
        }.bind(this), 1500);
      });
  };

  let deleteFile = (id) => {
    props.deleteSingleEventConfirmDocument(props.eventId, id, props.token);
  };
  let eventStarted = moment() > moment(props.event.event_date_from);

  let readyForInvoicing = props.event.requires_invoicing && (parseInt(props.event.event_status.code) >= BOOKED_EVENT_STATUS.STATUS_CANCELLED_PENDING_INVOICE || eventStarted);
  const IS_DENIED = parseInt(props.event.event_status.code) === BOOKED_EVENT_STATUS.DENIED;
  return (
    <div>
      {showCancelModal && (
        <RFPCancelModalForm
          onClose={() => {
            setShowCancelModal(false);
            window.location.reload();
          }}
          enquiry={data}
        />
      )}


      <form className="border-none pb-2" onSubmit={props.handleSubmit(onSubmit)}>
        {/*{props.event?.venue?.carbon_impact && props?.event?.venue?.carbon_impact?.has_valid_carbon_impact ?*/}
        {/*    <WhitePanelWithHeader*/}
        {/*        title={'Carbon Impact'}*/}
        {/*        collapsible*/}
        {/*        defaultOpen={true}*/}
        {/*    >*/}
        {/*        <CarbonImpactEventInformation*/}
        {/*            proposalData={props.event.enquiry_venue_datas.proposal_data}*/}
        {/*        />*/}
        {/*    </WhitePanelWithHeader>*/}
        {/*    : null}*/}

        {props.canManageEventPreData && !IS_DENIED ?
          <WhitePanelWithHeader
            title={"Confirmed Event Information"}
            collapsible
            defaultOpen={true}
          >
            <ConfirmedEventInformation
              setContractUploadedDocument={setContractUploadedDocument}
              setDelegateUploadedDocument={setDelegateUploadedDocument}
              contractUploadedDocument={contractUploadedDocument}
              delegateUploadedDocument={delegateUploadedDocument}
              contractDocument={props.event ? props.event.contract_file : null}
              delegateDocument={props.event ? props.event.delegate_file : null}
              deleteFile={deleteFile}
              event={props.event}
              canViewContractUploader={!(props.isCompanyUser || props.isCompanyAdmin)}
              delegateToggleType={props.event.delegate_file_url ? "document" : props.event.delegate_text ? "link" : null}
            />
          </WhitePanelWithHeader>
          : null}

        {readyForInvoicing && !IS_DENIED && props.canManageEventCompletion && (props.isRolePlatformAdmin || props.isEdgeFinance || ((props.isAgencyAdmin))) ?
          <WhitePanelWithHeader
            title={"Completed Event Information"}
            collapsible
            defaultOpen={true}
          >

            <CompletedEventInformation
              setInvoiceUploadedDocument={setInvoiceUploadedDocument}
              invoiceUploadedDocument={invoiceUploadedDocument}
              invoiceDocument={props.event ? props.event.invoice_file : null}
              deleteFile={deleteFile}
              event={props.event}
              openFinalModal={() => setModal("final")}
              submitting={submitting}
              vatRates={props.vatRates}
              change={props.change}
              agencyFinancials={props.agencyFinancials}
              canOnlyAccessUploadInvoice={readyForInvoicing && props.canManageEventCompletion && (props.isRolePlatformAdmin || props.isEdgeFinance || ((props.isAgencyAdmin) && props.userAgency.can_manage_invoicing))}
            />
          </WhitePanelWithHeader> :
          ((props.isAgencyAdmin || props.isAgencyUser) && !props.userAgency.can_manage_invoicing) ?
            <div className="bg-warning px-3 py-6 my-5 block font-bold text-white">
              <p className="mb-0"><i className="fas text-2lg fa-exclamation-triangle mr-3"></i>
                Your agency does not manage commission invoicing
              </p>
            </div>
            :
            (props.isAgencyUser && !IS_DENIED ?
                <div className="bg-primary px-3 py-6 my-5 block font-bold text-white">
                  <p className="mb-0">
                    {props.event.has_been_invoiced ? "Event has been invoiced" : "Event awaiting invoicing"}
                  </p>
                </div>
                : null
            )
        }

        {props.canManageEventCompletion && props.isRolePlatformAdmin ?
          <WhitePanelWithHeader
            title={"Invoice PDFs"}
            collapsible
            defaultOpen={false}
          >
            <InvoicePdfs
              event={props.event}
            />
          </WhitePanelWithHeader>
          : null}

        <div className={"row flex flex-row"}>
          <div className="col  ">
            <div className={"corp-form-buttons flex flex-row justify-between"}>
              <div className={"flex flex-row"}>
                {!eventStarted && props.canCancelEvent ?
                  <ButtonPrimaryRed classes={`mr-2`} onClick={() => setModal("cancel")}>
                    {submitting && submitting === "cancel" ? <Loading inline /> : "Cancel"}
                  </ButtonPrimaryRed>
                  :
                  null
                }

                {!IS_DENIED && readyForInvoicing && props.canManageEventCompletion && (props.isRolePlatformAdmin || props.isEdgeFinance || ((props.isAgencyAdmin) && props.userAgency.can_manage_invoicing)) ?
                  <BookedEventsDownloadButton
                    type={"invoice"}
                    text={props.event.event_status.code >= 40 ? "Download Invoice" : "Generate Invoice"}
                    onClick={() => props.invoiceEvent(props.event.id, props.token)}
                    event={props.event}
                    disabled={
                      !checkHasGBPFinancialData(props.agencyFinancials) && (!props.event.total_commission_value_net || Number(props.event.final_commission_value) === 0)
                    }
                  />
                  : null}
                {!IS_DENIED && readyForInvoicing && props.canManageEventCompletion && (props.isRolePlatformAdmin || props.isEdgeFinance || ((props.isAgencyAdmin) && props.userAgency.can_export_to_xero)) ?
                  <BookedEventsDownloadButton
                    type={"xero"}
                    text={"Export Event For Xero"}
                    event={props.event}
                    isRed={!!props.event.exported_to_xero_date}
                    disabled={props.event.event_status.code < 40}
                  />
                  : null}
                {!props.event.requires_invoicing && props.canManageEventCompletion && !(parseInt(props.event.event_status.code) === BOOKED_EVENT_STATUS.CANCELLED || parseInt(props.event.event_status.code) === BOOKED_EVENT_STATUS.DENIED) ?
                  <ButtonPrimary classes={`mr-2`} onClick={() => complete()}>
                    {submitting && submitting === "complete" ? <Loading inline /> : "Complete"}
                  </ButtonPrimary>
                  : null}
                {!IS_DENIED && readyForInvoicing && props.event.total_commission_value_net && props.canManageEventCompletion && (props.isRolePlatformAdmin || ((props.isAgencyAdmin) && props.userAgency.can_manage_invoicing)) ?
                  <ButtonPrimary classes={`mr-2`} onClick={() => {
                    setModal("final");
                  }}>
                    {"Recalculate Final Commission"}
                  </ButtonPrimary>
                  : null}
              </div>

              {(
                (!props.isCorporate && (parseInt(props.event.event_status.code) !== BOOKED_EVENT_STATUS.CANCELLED || props.event.cancellation_charges_apply))
                || (props.isCorporate && parseInt(props.event.event_status.code) === BOOKED_EVENT_STATUS.CONFIRMED)
              ) ?
                <ButtonPrimary type="submit">
                  {submitting && submitting === "form" ? <Loading inline /> : "Save"}
                </ButtonPrimary>
                : null
              }

              {(!props.isAgency && !props.isInternal) && data.managing_trinity_user ?
                <div className={"ml-5"}>
                  <ButtonPrimaryRed onClick={(e) => {
                    window.open(`mailto:${data.managing_trinity_user.email}?subject=Cancelling RFP ${data.client_details.ref_id}`, "_blank");
                    e.preventDefault();
                  }}>
                    Cancel Booking
                  </ButtonPrimaryRed>
                </div>
                :
                <ButtonPrimaryRed classes={"ml-5"} onClick={() => setShowCancelModal(true)}>
                  {submitting ? <Loading inline /> : "Cancel Booking"}
                </ButtonPrimaryRed>
              }

            </div>
          </div>
        </div>

      </form>
      {modal && modal === "final" && (
        <FinalCommissionValuesModal
          onClose={() => {
            setModal(false);
            window.location.reload();
          }}
          enquiry={data}
          event={props.event}
          vatRates={props.vatRates}
          includesVat={values["total_commission_includes_vat"]}
          includesVatRate={values["total_commission_vat_rate_id"]}
        />
      )}
      {success && <Succeeded text={`Event has been completed successfully`} leftAlign />}
      {submitError || error ? <Failed /> : null}
    </div>
  );
};


BookedEventsForm = reduxForm({
  form: "eventsForm",
  enableReinitialize: true,
})(BookedEventsForm);

export default connect(mapStateToProps, mapDispatchToProps)(BookedEventsForm);
