import { WhitePanelWithHeader } from "../../../../../ui";
import moment from "moment";
import React, { useState } from "react";
import { hasAgencyRoles, isInternal, userId as userIdSelector } from "../../../../../modules/auth";
import { useSelector } from "react-redux";
import ConfirmationExportsModal from "./ConfirmationExportsModal";
import { ButtonPrimary } from "../../../../../Components/Button";

let ConfirmationComplete = ({ enquiry, enquiryVenueData, showCommission, isAgency, isVenue}) => {
  // const userId = useSelector(userIdSelector);
  const hasAgency = useSelector(state => hasAgencyRoles(state));
  const hasInternal = useSelector(state => isInternal(state));
  const [showExportsModal, setShowExportsModal] = useState(false);
  // check corporate account export net settings, default to false
  let exportNet = false;

  if (enquiry && enquiry.company_details && enquiry.company_details.name && enquiry.company_details.name.corporate_account) {
    exportNet = Boolean(enquiry.company_details.name.corporate_account.export_net);
  }

  function renderContractConfirmationText(enquiry, enquiryVenueData) {
    if (enquiry.company_details.branch !== "" && enquiry.company_details.branch !== null) {
      return (<p className="font-bold">The contract and final details of this booking should now be
        between {enquiryVenueData.venue.name} and {enquiry.company_details.branch}.</p>);
    } else if (enquiry.company_details.name !== "" && enquiry.company_details.name !== null) {
      return (<p className="font-bold">The contract and final details of this booking should now be
        between {enquiryVenueData.venue.name} and {enquiry.company_details.name.name}.</p>);
    } else if (enquiry.agency_specific_data.client_name !== "" && enquiry.agency_specific_data.client_name !== null) {
      return (<p className="font-bold">The contract and final details of this booking should now be
        between {enquiryVenueData.venue.name} and {enquiry.agency_specific_data.client_name}.</p>);
    } else if (enquiry.agency_details.name !== "" && enquiry.agency_details.name !== null) {
      return (<p className="font-bold">The contract and final details of this booking should now be
        between {enquiryVenueData.venue.name} and {enquiry.agency_details.name}.</p>);
    }
    return (<p className="font-bold">The contract and final details of this booking should now be
      between {enquiryVenueData.venue.name} and {enquiry.client_details.name}.</p>);
  }

  return (
    <WhitePanelWithHeader icon="far fa-clipboard" title="Final Confirmation Details">
      <div className="bg-brand px-3 py-6 font-bold mb-3">
        <p className="font-bold">
          {isVenue ? "Thank you for accepting this booking." : "Thanks for confirming this event booking."} Both parties
          will have received an email and PDF of the pre contract confirmation agreement.
        </p>
        {renderContractConfirmationText(enquiry, enquiryVenueData)}
        {!showCommission && (
          <p className="font-bold">
            EDGE Venues has facilitated the booking of this event but does not earn any commission.
          </p>
        )}
        <ButtonPrimary
          onClick={() => setShowExportsModal(!showExportsModal)}
        >
          Export
        </ButtonPrimary>
        {showExportsModal ? (
          <ConfirmationExportsModal
            enquiry={enquiry}
            exportNet={exportNet}
            enquiryVenueData={enquiryVenueData}
            agency={isAgency}
            hasAgency={hasAgency}
            hasInternal={hasInternal}
            proposalData={enquiryVenueData.proposal_data}
            closeModal={() => setShowExportsModal(false)}
          />
        ) : null}
      </div>
      <p>Event Name: <strong>
        {enquiry.brief.event_name} - (Ref: {enquiry.client_details.ref_id})</strong></p>
      <p>Venue: <strong>{enquiryVenueData.venue.name}</strong> - Confirmed Event Start
        Date: <strong>{moment(enquiryVenueData.date_from_override ? enquiryVenueData.date_from_override : enquiry.brief.date_from).format("DD/MM/Y")}</strong> -
        Confirmed Event End
        Date: <strong>{moment(enquiryVenueData.date_to_override ? enquiryVenueData.date_to_override : enquiry.brief.date_to).format("DD/MM/Y")}</strong>
      </p>
      <p>
        User
        Confirmed: <strong>{moment(enquiryVenueData.venue_confirmation.date_user_confirmed).format("DD/MM/Y hh:mm")}</strong> /
        Venue Confirmed:
        <strong>{moment(enquiryVenueData.venue_confirmation.date_venue_confirmed).format("DD/MM/Y hh:mm")}</strong>
      </p>

    </WhitePanelWithHeader>
  );
};
export default ConfirmationComplete;
