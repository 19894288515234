import React from "react";
import { connect } from "react-redux";
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";
import { change, Field, getFormValues } from "redux-form";
import prettyRadius from "modules/pretty-radius";
import { withRouter } from "react-router-dom";
import { debounce } from "lodash";
import Checkbox from "../../../Components/Form/Checkbox";
import { getWidth } from "redux-window";
import {
  fetchPriceBandingData,
  fetchVenuePriceBandingData,
} from "../../../services/priceBandingService/fetchPriceBandings";
import { token } from "../../../modules/auth";
import MarkerWithVenuePopup from "../../../Components/Map/MarkerWithVenuePopup";

// const style = {
//   strokeColor: "rgb(0, 153, 255)",
//   strokeOpacity: 0.4,
//   strokeWeight: 2,
//   fillColor: "rgb(0, 153, 255)",
//   fillOpacity: 0.2,
//   zIndex: 1
// };

class Map extends React.Component {
  state = {
    markers: [],
    selectedId: undefined,
    updateOnMove: false,
    isSelectedToShortlist: true,
    venuePriceBandings: null,
    priceBandings: null,
  };

  constructor(props) {
    super(props);
    this.setRef = {
      map: e => (this.map = e),
      circle: e => (this.circle = e),
      searchBox: e => (this.searchBox = e),
    };
  }

  componentDidMount = () => {
    if (this.props.loadOnMove) {
      this.setState({ updateOnMove: true });
    }
  };

  zoom = () => {
    if (!this.props.lat && !this.props.lng) {
      return 6;
    }
    switch (prettyRadius(this.props.radius)) {
      case 0.25:
        return 15;
      case 0.5:
        return 14;
      case 1:
      case 2:
        return 13;
      case 3:
      case 4:
        return 12;
      case 5:
      case 6:
      case 7:
        return 11;
      case 8:
      case 9:
      case 10:
        return 10;
      case 20:
        return 9;
      case 30:
      case 40:
      case 50:
        return 8;
      case 60:
      case 70:
      case 80:
      case 90:
      case 100:
        return 7;
      case 150:
      case 200:
        return 6;
      default:
        return 13;
    }
  };

  setLocation = e => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    this.props.change("advanced-search", "lat", lat);
    this.props.change("advanced-search", "lng", lng);
    if (this.state.updateOnMove) {
      this.props.search({
        lat,
        lng,
      });
    }
  };

  setSearch = (lat, lng) => {
    this.props.change("advanced-search", "lat", lat);
    this.props.change("advanced-search", "lng", lng);
    this.props.change("advanced-search", "location", "");
    this.props.search({
      lat,
      lng,
    });
  };

  onCenterChange = () => {
    if (!this.state.selectedId && this.state.updateOnMove) {
      const center = this.map.getCenter();
      const lat = center.lat();
      const lng = center.lng();
      this.props.change("advanced-search", "lat", lat);
      this.props.change("advanced-search", "lng", lng);
      this.props.search({
        lat,
        lng,
      });
    }
  };

  showData = venue => {
    const isSelected = this.venueOnList(venue.id);

    fetchPriceBandingData(this.props.token).then(response => {
      this.setState({
        priceBandings: response,
      });
    });
    fetchVenuePriceBandingData(this.props.token, venue.slug).then(response => {
      if (Object.keys(response).length !== 0) {
        this.setState({
          venuePriceBandings: response,
        });
      } else {
        this.setState({
          venuePriceBandings: null,
        });
      }
    });

    this.setState({ selectedId: venue.id, isSelectedToShortlist: isSelected });
  };

  closeWindow = () => {
    this.setState({ selectedId: undefined });
  };

  shortlistedVenues = () =>
    this.props.activeShortlist &&
    Array.isArray(this.props.activeShortlist.venues)
      ? this.props.activeShortlist.venues
      : [];

  venueOnList = id => !!this.shortlistedVenues().find(venue => venue.id === id);

  shortListForm = () => `${this.props.type}-search-shortlist`;

  toggleShortlist = (toggleVenue, isAdding) => {
    const venues = this.shortlistedVenues();
    const newVenuesShortList = isAdding
      ? [...venues, toggleVenue]
      : venues.filter(venue => venue.id !== toggleVenue.id);

    this.props.change(this.shortListForm(), "venues", newVenuesShortList);
    this.setState({ isSelectedToShortlist: !this.state.isSelectedToShortlist });
  };

  updateResultsOnMapMove = e => {
    this.setState({ updateOnMove: e.length > 0 });
  };

  hasPrice = result => {
    return (
      result.ddr_price_ex_vat > 0 ||
      result["24hr_dr_price_ex_vat"] > 0 ||
      result.buy_it_now_price_exc_vat > 0
    );
  };

  render() {
    return (
      <>
        {!this.props.mobile && (
          <div
            className="bg-white rounded absolute py-2 px-4"
            style={{ bottom: 15, left: 15 }}
          >
            <Field
              component={Checkbox}
              label=""
              name="update_on_move"
              options={["Update Results When Map Moves"]}
              onChange={this.updateResultsOnMapMove}
              wrapperClassName="font-bold"
              labelClassName="mb-0"
            />
          </div>
        )}
        <GoogleMap
          onClick={this.setLocation}
          onCenterChanged={debounce(this.onCenterChange, 500)}
          ref={this.setRef.map}
          zoom={this.zoom()}
          center={
            !this.state.selectedId &&
            new window.google.maps.LatLng(
              parseFloat(this.props.lat) || 52.6445885,
              parseFloat(this.props.lng) || -0.5006198
            )
          }
          options={{
            streetViewControl: false,
            mapTypeControl: false,
          }}
        >
          {this.props.lat && this.props.lng ? (
            <Marker
              position={{
                lat: parseFloat(this.props.lat),
                lng: parseFloat(this.props.lng),
              }}
            />
          ) : null}
          {this.props.results.slice(0, 100).map(result => {
            return (
              <MarkerWithVenuePopup
                key={result.id}
                result={result}
                selectedId={this.state.selectedId}
                hasPrice={this.hasPrice(result)}
                onClick={() => this.showData(result)}
                onCloseClick={() => this.closeWindow()}
                checked={this.state.isSelectedToShortlist}
                toggleShortlist={() => {
                  this.toggleShortlist(
                    result,
                    !this.state.isSelectedToShortlist
                  );
                }}
                priceBandings={this.state.priceBandings}
                venuePriceBandings={this.state.venuePriceBandings}
              />
            );
          })}
        </GoogleMap>
      </>
    );
  }
}

export default withScriptjs(
  withGoogleMap(
    connect(
      (state, props) => {
        const v = getFormValues("advanced-search")(state) || {};
        return {
          radius: v.radius,
          lat: v.lat,
          lng: v.lng,
          activeShortlist: getFormValues(`${props.type}-search-shortlist`)(
            state
          ),
          mobile: getWidth(state) < 768,
          token: token(state),
          loadOnMove: v.update_on_move ? v.update_on_move.length > 0 : false,
        };
      },
      { change }
    )(withRouter(Map))
  )
);
