import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { token, venues } from "../../modules/auth";
import { refreshUserVenues } from "../../modules/auth-thunks";
import { connect } from "react-redux";
import { currencyFormatter } from "../../utils";
import { Link, useRouteMatch } from "react-router-dom";
import * as ROUTES from "../../Router/venue_routes";
import * as VENUE_ROUTES from "../../Router/venue_routes";
import { getVenueLicenceSettings } from "../../services/venueLicenceSettingsService";
import { LICENCE_TYPE_CODES } from "../../constants";

function VenueUpgradeBanner({ venues, refreshUserVenues, currentVenue, token }) {
  const [upgradePrice, setUpgradePrice] = useState();
  const match = useRouteMatch(VENUE_ROUTES.FRONTEND.MANAGE_VENUE_LICENCE.INDEX);
  const matchAdminWelcome = useRouteMatch("/admin/welcome");
  const matchPublicHome = useRouteMatch("/");
  const matchEditVenue = useRouteMatch(VENUE_ROUTES.FRONTEND.VENUE.EDIT);
  const matchViewVenue = useRouteMatch(VENUE_ROUTES.FRONTEND.VENUE.PUBLIC_VIEW);
  const matchVenuesDashboard = useRouteMatch(VENUE_ROUTES.FRONTEND.VENUE.DASHBOARD);

  const [bannerOverrideTitle, setBannerOverrideTitle] = useState();
  const [discountCodeInstructions, setDiscountCodeInstructions] = useState();
  const [discountModifier, setDiscountModifier] = useState(1);

  const UPGRADE_BANNER_TEXT = `Upgrade :venueName today. Become a licenced venue for only :value a month. Control your costs with no platform fee, get priority placements, unlimited marketing, great analytics. ${discountCodeInstructions}. :readMore`;

  useEffect(() => {
    const fetchSettings = async () => {
      const { result, error } = await getVenueLicenceSettings();
      if (!result) {
        return;
      }
      let overrideTitle = result.find(value => value.key === "venue_licence_banner_override_title");
      let discountCodeAndInstructions = result.find(value => value.key === "venue_licence_discount_code_and_instructions");
      let discountModifierMultiple = result.find(value => value.key === "venue_licence_discount_override_modifier");
      if (overrideTitle) {
        setBannerOverrideTitle(overrideTitle.value);
      }
      if (discountCodeAndInstructions) {
        setDiscountCodeInstructions(discountCodeAndInstructions.value);
      }
      if (discountModifierMultiple) {
        setDiscountModifier(discountModifierMultiple.value);
      }
    };

    void fetchSettings();
  }, []);

  useEffect(() => {
    refreshUserVenues(true);
  }, [refreshUserVenues]);

  useEffect(() => {
    const getProduct = async () => {
      try {
        const { data } = await axios.get(
          `${window.API}${VENUE_ROUTES.API.SELF_SERVICE.STRIPE.GET_DEFAULT_PRODUCT}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        const { price, product } = data;
        let originalUpgradePrice = price?.unit_amount;

        if (discountModifier) {
          originalUpgradePrice *= discountModifier;
        }

        setUpgradePrice(currencyFormatter("GBP").format(originalUpgradePrice / 100));
      }catch (error) {
        console.error("Error fetching stripe default product", error);
      }
    };
    void getProduct();
  }, []);

  const venuesToUpgrade = useMemo(() => {
    return venues.filter(v => v.is_opted_out || v.active_licence_type_code === null || v.active_licence_type_code === LICENCE_TYPE_CODES.BASIC_FEE_CODE || v.active_licence_type_code === LICENCE_TYPE_CODES.INTERNATIONAL_FEE_CODE
    );
    // return venues.filter(v => v.is_opted_out);
    // const venuesSet = new Set();
    //
    // if (currentVenue && currentVenue.temp_permission === "free") {
    //   if (venues.find(venue => currentVenue.id === venue.id)) {
    //     venuesSet.add(currentVenue);
    //   }
    // } else if (matchEditVenue) {
    //   let editVenue = venues.find(venue => venue.slug === matchEditVenue.params.venue);
    //   if (editVenue && editVenue.temp_permission === "free") {
    //     venuesSet.add(editVenue);
    //   }
    // } else if (!currentVenue) {
    //   venues.forEach((venue) => {
    //     if (venue.temp_permission === "free") {
    //       venuesSet.add(venue);
    //     }
    //   });
    // }
    //
    // return Array.from(venuesSet);
  }, [venues, currentVenue, matchEditVenue]);

  const upgradeText = useMemo(() => {
    const replacePlaceholders = (template, replacements) => {
      return template.replace(/:venueName|:value|:readMore/g, (matched) => replacements[matched]);
    };

    if (venuesToUpgrade.length === 1) {
      const { name, slug } = venuesToUpgrade[0];

      const textWithPlaceholders = replacePlaceholders(UPGRADE_BANNER_TEXT, {
        ":venueName": `<a href="${ROUTES.FRONTEND.MANAGE_VENUE_LICENCE.INDEX}?venue=${slug}" class="text-white underline">${name}</a>`,
        ":value": `${upgradePrice}+VAT`,
        ":readMore": `<a href="${ROUTES.FRONTEND.MANAGE_VENUE_LICENCE.LICENSED_VENUE_PROMO_PAGE}?venue=${slug}" class="text-white underline">Read more</a>`,
      });

      return <span dangerouslySetInnerHTML={{ __html: textWithPlaceholders }} />
    } else {
      const linkedVenueNames = venuesToUpgrade.slice(0, 3).map((venue, index) => (
        `<a href="${ROUTES.FRONTEND.MANAGE_VENUE_LICENCE.INDEX}?venue=${venue.slug}" class="text-white underline">${venue.name}</a>${
          index < 2 && index < venuesToUpgrade.length - 1 ? ", " : ""
        }`
      )).join("");

      const additionalCount =
        venuesToUpgrade.length > 3 ? `+ ${venuesToUpgrade.length - 3} more` : "";

      const textWithPlaceholders = replacePlaceholders(UPGRADE_BANNER_TEXT, {
        ":venueName": linkedVenueNames + additionalCount,
        ":value": `${upgradePrice}+VAT`,
        ":readMore": `<a href="${ROUTES.FRONTEND.MANAGE_VENUE_LICENCE.LICENSED_VENUE_PROMO_PAGE}" class="text-white underline">Read more</a>`,
      });

      return <span dangerouslySetInnerHTML={{ __html: textWithPlaceholders }} />
    }
  }, [venuesToUpgrade, upgradePrice, UPGRADE_BANNER_TEXT]);

  if (match) {
    return null;
  }

  if (!matchAdminWelcome && !matchEditVenue && !matchVenuesDashboard && !matchViewVenue && !matchPublicHome) {
    return null;
  }

  if (venuesToUpgrade.length === 0) {
    return null;
  }

  return (
    <div className={`bg-primary px-3 py-6 block font-bold text-white my-5`}>
      {bannerOverrideTitle ? (
        <h3>{bannerOverrideTitle}</h3>
      ) : (
        <h3>Upgrade your venue today</h3>
      )}
      {upgradeText}
    </div>
  );
}

const mapStateToProps = (state) => ({
  venues: venues(state),
  token: token(state),
});

const mapDispatchToProps = { refreshUserVenues };

export default connect(mapStateToProps, mapDispatchToProps)(VenueUpgradeBanner);
