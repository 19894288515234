import React from "react";
import { Link } from "react-router-dom";
import { ButtonBlack, ButtonSpan } from "Components/Button";

const NaSlide = ({ data, onRemove, venue }) => {
  const { venue_initial_response } = data || {};
  const { venue_availability_type, availability_note } = venue_initial_response || {};

  const reason = venue_availability_type?.description || "";
  const availabilityNote = availability_note || "";
  return (
    <div className="mb-5">
      <h2
        className="text-white bg-black font-normal font-body p-3 uppercase"
        style={{ minHeight: "6.7rem" }}
      >
        {venue.name}
      </h2>
      <p
        className="bg-red text-white font-normal text-lg flex flex-wrap items-center p-3"
        style={{ minHeight: "5rem" }}
      >
        <i className="fas fa-exclamation-circle fa-2x text-white uppercase"></i>
        <span className="ml-3">Not available</span>
        {reason && (
          <span className="w-full mt-3 text-base text-white">
            Reason: <em>{reason}</em>
          </span>
        )}
        {availabilityNote && (
          <span className="w-full mt-3 text-base text-white">
            <em>{availabilityNote}</em>
          </span>
        )}
      </p>
      <div
        className="re-16/9 w-full bg-no-repeat bg-cover bg-center"
        style={
          venue.image
            ? {
              backgroundImage: `url(${window.API}/venues/${venue.slug}/images/${venue.image})`,
            }
            : null
        }
      />
      <div className="flex justify-between p-3 font-normal text-xl mb-2">
        <div>
          <i className="mr-2 fas fa-users" />
          {venue.capacity}
        </div>
        <div>
          <i className="mr-2 fas fa-bed" />
          {venue.bedrooms}
        </div>
        <div>
          <i className="mr-2 fas fa-handshake" />
          {venue.meeting_rooms}
        </div>
      </div>
      {/* <div className="p-3 bg-red text-white">
        <span className="font-normal">UPDATE FROM HOTEL</span>
      </div>
      <div className="p-3" style={{ backgroundColor: "#eaeaea" }}>
        <p className="font-bold">Dates unavailable</p>
        <p className="ml-3">Blah blah</p>
      </div>
      <div className="p-3" style={{ backgroundColor: "#f9f9f9" }}>
        <p className="font-bold">
          Would you like to view dates available at {venue.name}?
        </p>
        <ButtonRed onClick={() => {}} classes="mr-3">
          Yes
        </ButtonRed>
        <ButtonBlack onClick={() => {}}>No</ButtonBlack>
      </div> */}
      <ButtonBlack
        Element={Link}
        target="_blank"
        to={`/venue/${venue.slug}`}
        rel="noopener noreferrer"
        classes="p-3 flex items-center justify-center w-full mb-2"
        style={{ height: "auto", lineHeight: 1 }}
      >
        View venue
      </ButtonBlack>
      <ButtonSpan
        className="p-3 text-center block w-full mb-2"
        onClick={() => onRemove(venue)}
        style={{ height: "auto", lineHeight: 1 }}
      >
        <i className="mr-3 fal fa-minus-circle"></i>
        Remove Venue
      </ButtonSpan>
    </div>
  );
};

export default NaSlide;
