export const ENQUIRY_STATUS = {
    DRAFT: 'DRAFT',
    CANCELLED: 'CANCELLED',
    CONFIRMED: 'CONFIRMED',
    DENIED: 'DENIED'
}

export const GOOGLE_MAPS_API_KEY = `AIzaSyAaTrHlUOn97GNkea1Aq3gaes2eetTcBV4`

export const BOOKED_EVENT_STATUS = {
    PROVISIONAL: 0,
    DENIED: 10,
    REVISING_CONFIRMATION: 11,
    CONFIRMED: 20,
    STATUS_CANCELLED_PENDING_INVOICE: 25,
    PENDING_INVOICE: 30,
    COMPLETED: 40,
    CANCELLED: 50
}

export const COUNTRIES = {
    UNITED_KINGDOM: 77,
    ENGLAND: 252,
    NORTHERN_IRELAND: 253,
    SCOTLAND: 255,
    WALES: 256
}

// Taken from user_types.id in the backend
export const USER_TYPES = {
    CORPORATE: 1,
    AGENCY: 2,
    INDIVIDUAL: 3,
    CHARITY: 4,
    OTHER: 5,
    VENUE: 6
}

export const EDGE_INFO_EMAIL = "info@edgevenues.com"
export const EDGE_NOTIFICATIONS_EMAIL = "notifications@edgevenues.com"
export const EDGE_SALES_EMAIL = 'sales@edgevenues.com';
export const EDGE_ENQUIRIES_EMAIL = EDGE_INFO_EMAIL; //'enquiries@edgevenues.com';
export const EDGE_PHONE_NUMBER = '01780 484 051';

// temporary solution to hide auto and reassign for roche
export const ROCHE_COMPANY_CODE = 'RPL';

export const ROCHE_THIRD_PARTY_TEXT = "Roche are not responsible for booking venues for Third Party Meetings where Roche have a presence. The Third Party is responsible for, and must control the selection and booking of the venue. We therefore refer you to SOP-0112349 (Third Party Organised Meetings with Roche Presence) and will not progress this enquiry further. Please contact Affiliate Enablement directly should you have any queries."
export const DEFAULT_AGENCY_CONTACT = {
    name: 'EDGE Venues',
    email_address: EDGE_ENQUIRIES_EMAIL,
    phone_number: EDGE_PHONE_NUMBER
}

export const USER_TOKEN_DISABLED = 'TOKEN_DISABLED';

export const UK_COUNTRY_IDS = [77, 252, 253, 255, 256, 257, 111, 81];

export const LICENCE_TYPE_CODES = {
    PAID_LICENCE_CODE: 'PAID',
    BASIC_FEE_CODE: 'BFEE',
    ENHANCED_FEE_CODE: 'EFEE',
    INTERNATIONAL_FEE_CODE: 'IFEE',
    OPT_OUT_LICENCE_CODE: 'OPTO'
}

export const PERMISSIONS = {
    CREATE_USER: "create_user",
    READ_USER: "read_user",
    UPDATE_USER: "update_user",
    DELETE_USER: "delete_user",
    CREATE_VENUE: "create_venue",
    READ_VENUE: "read_venue",
    UPDATE_VENUE: "update_venue",
    DELETE_VENUE: "delete_venue",
    CREATE_MOVERS_AND_SHAKER: "create_movers-and-shaker",
    READ_MOVERS_AND_SHAKER: "read_movers-and-shaker",
    UPDATE_MOVERS_AND_SHAKER: "update_movers-and-shaker",
    DELETE_MOVERS_AND_SHAKER: "delete_movers-and-shaker",
    CREATE_SPECIAL_OFFER: "create_special-offer",
    READ_SPECIAL_OFFER: "read_special-offer",
    UPDATE_SPECIAL_OFFER: "update_special-offer",
    DELETE_SPECIAL_OFFER: "delete_special-offer",
    CREATE_FAM_VISIT: "create_fam-visit",
    READ_FAM_VISIT: "read_fam-visit",
    UPDATE_FAM_VISIT: "update_fam-visit",
    DELETE_FAM_VISIT: "delete_fam-visit",
    CREATE_COMMISSION_DEAL: "create_commission-deal",
    READ_COMMISSION_DEAL: "read_commission-deal",
    UPDATE_COMMISSION_DEAL: "update_commission-deal",
    DELETE_COMMISSION_DEAL: "delete_commission-deal",
    CREATE_AGENCY_RATE: "create_agency-rate",
    READ_AGENCY_RATE: "read_agency-rate",
    UPDATE_AGENCY_RATE: "update_agency-rate",
    DELETE_AGENCY_RATE: "delete_agency-rate",
    CREATE_NEWS: "create_news",
    READ_NEWS: "read_news",
    UPDATE_NEWS: "update_news",
    DELETE_NEWS: "delete_news",
    CREATE_SHORTLIST: "create_shortlist",
    READ_SHORTLIST: "read_shortlist",
    UPDATE_SHORTLIST: "update_shortlist",
    DELETE_SHORTLIST: "delete_shortlist",
    CREATE_NOTE: "create_note",
    READ_NOTE: "read_note",
    UPDATE_NOTE: "update_note",
    DELETE_NOTE: "delete_note",
    CREATE_LARAVEL_ROLES: "create_laravel_roles",
    READ_LARAVEL_ROLES: "read_laravel_roles",
    UPDATE_LARAVEL_ROLES: "update_laravel_roles",
    DELETE_LARAVEL_ROLES: "delete_laravel_roles",
    CREATE_LARAVEL_PERMISSIONS: "create_laravel_permissions",
    READ_LARAVEL_PERMISSIONS: "read_laravel_permissions",
    UPDATE_LARAVEL_PERMISSIONS: "update_laravel_permissions",
    DELETE_LARAVEL_PERMISSIONS: "delete_laravel_permissions",
    CREATE_SUBSCRIPTIONS: "create_subscriptions",
    READ_SUBSCRIPTIONS: "read_subscriptions",
    UPDATE_SUBSCRIPTIONS: "update_subscriptions",
    DELETE_SUBSCRIPTIONS: "delete_subscriptions",
    CAN_CREATE_RFP: "can_create_rfp",
    CAN_CREATE_OWN_CHECKSHEET: "can_create_own_checksheet",
    CAN_CREATE_ALL_CHECKSHEETS: "can_create_all_checksheets",
    CAN_MANAGE_RFP: "can_manage_rfp",
    CAN_READ_CHECKSHEET: "can_read_checksheet",
    CAN_READ_RFP: "can_read_rfp",
    IS_TRINITY: "is_trinity",
    IS_TRINITY_EDITOR: "is_trinity_editor",
    READ_ANALYTICS: "read_analytics",
    CAN_MANAGE_VENUES_CONTACTS: "can_manage_venues_contacts",
    CAN_ADMINISTER_AGENCIES: "can_administer_agencies",
    CAN_ADMINISTER_COMPANIES: "can_administer_companies",
    CAN_VIEW_OWN_AGENCY: "can_view_own_agency",
    CAN_EDIT_AGENCY_PROFILE: "can_edit_agency_profile",
    CAN_EDIT_COMPANY_PROFILE: "can_edit_company_profile",
    CAN_MANAGE_CORPORATE_ACCOUNT_DOCUMENTS: "can_manage_corporate_account_documents",
    CAN_VIEW_CORPORATE_ACCOUNT_DOCUMENTS: "can_view_corporate_account_documents",
    CAN_MANAGE_CORPORATE_ACCOUNTS: "can_manage_corporate_accounts",
    CAN_MANAGE_PUBLIC_CALENDAR_EVENTS: "can_manage_public_calendar_events",
    CAN_MANAGE_INDUSTRY_CALENDAR_EVENTS: "can_manage_industry_calendar_events",
    CAN_CHASE_EVENT: "can_chase_event",
    CAN_MANAGE_EVENT_COMPLETION: "can_manage_event_completion",
    CAN_MANAGE_PRE_EVENT_DATA: "can_manage_pre_event_data",
    CAN_VIEW_EVENTS: "can_view_events",
    CAN_CANCEL_EVENTS: "can_cancel_events",
    CAN_MANAGE_COMPANY_USERS: "can_manage_company_users",
    CAN_CREATE_HIGHLY_CONFIDENTIAL_RFP: "can_create_highly_confidential_rfp",
    CAN_VIEW_HIGHLY_CONFIDENTIAL_RFP: "can_view_highly_confidential_rfp",
    CAN_ACCESS_CORPORATE_CALENDAR: "can_access_corporate_calendar",
    CAN_ACCESS_LOCATIONS: "can_access_locations",
    CAN_ACCESS_DOCUMENTS: "can_access_documents",
    CAN_ACCESS_EDGE_TEAM_UPDATES: "can_access_edge_team_updates",
    CAN_DISPLAY_ALL_IMAGES: "can_display_all_images",
    CAN_DISPLAY_QUICK_VIEW: "can_display_quick-view",
    CAN_DISPLAY_SITE_VISITS: "can_display_site-visits",
    CAN_DISPLAY_VIRTUAL_TOUR: "can_display_virtual-tour",
    CAN_BE_INCLUDED_IN_SITE_ANALYTICS: "can_be_included_in_site-analytics",
    CAN_BE_INCLUDED_IN_SPECIAL_OFFERS: "can_be_included_in_special-offers",
    CAN_BE_INCLUDED_IN_NEWS: "can_be_included_in_news",
    CAN_BE_INCLUDED_IN_BLOGS: "can_be_included_in_blogs",
    CAN_BE_INCLUDED_IN_RENOVATION_NEWS: "can_be_included_in_renovation-news",
    CAN_BE_INCLUDED_IN_MOVERS_AND_SHAKERS: "can_be_included_in_movers-and-shakers",
    CAN_BE_INCLUDED_IN_AGENCY_RATES: "can_be_included_in_agency-rates",
    CAN_BE_INCLUDED_IN_COMMISSION_DEALS: "can_be_included_in_commission-deals",
    CAN_BE_INCLUDED_IN_FAM_VISITS: "can_be_included_in_fam-visits"
};
