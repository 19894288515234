import React, { useState } from "react";
import { Field } from "redux-form";
import { connect } from "react-redux";

import { Date, Input, Toggle } from "../../Components/Form";
import { bool, isDate, maxLength, optional, required } from "../../modules/validation";
import { Select, Textarea } from "../../ui";


// const v = {
//   event_timings: required(),
//   additional_notes: optional(maxLength(256)),
//   syndicate_room_notes: optional(maxLength(256)),
//   exhibition_space_notes: optional(maxLength(256)),
//   private_dining_notes: optional(maxLength(256)),
//   back_projection_notes: optional(maxLength(256)),
//   any_equipment_notes: optional(maxLength(256)),
//   date_decision_date: [required(), isDate],
//   is_edge_enquiry: bool,
//   is_trinity_enquiry: bool,
// };

// const mapStateToProps = state => {};
//
// const mapDispatchToProps = {};

const TextQuestion = ({ question, skipCustomFields = false }) => {
  return <Field
    key={question.fieldIdentifier}
    name={`custom_fields.${question.fieldIdentifier}`}
    label={question.label}
    component={Input}
    size="lg"
    validate={!skipCustomFields && question.required ? required() : null}
  />;
};


const CheckboxQuestion = ({ question, skipCustomFields = false }) => {
  return (
    <>
      <br />
      <Field
        key={question.fieldIdentifier}
        name={`custom_fields.${question.fieldIdentifier}`}
        label={question.label}
        component={Toggle}
        defaultValue={question.default}
        wrapperClassName="mb-8 xl:w-1/2"
        validate={!skipCustomFields && question.required ? required() : null}
      />
    </>
  );

};


const DropdownQuestion = ({ question, skipCustomFields = false }) => {
  return <Field
    key={question.fieldIdentifier}
    name={`custom_fields.${question.fieldIdentifier}`}
    label={question.label}
    component={Select}
    options={question.options.sort((a, b) => a.sort < b.sort).map((option) => {
      return {
        text: option.label,
        value: option.value,
      };
    })}
    validate={!skipCustomFields && question.required ? required() : null}
    wrapperClassName="max-w-full mb-5 mr-6 w-80"
  />;
};

const NumericQuestion = ({ question, skipCustomFields = false }) => {
  return <Field
    key={question.fieldIdentifier}
    name={`custom_fields.${question.fieldIdentifier}`}
    label={question.label}
    type={"number"}
    component={Input}
    size="lg"
    validate={!skipCustomFields && question.required ? required() : null}
  />;
};

const DateQuestion = ({ question, skipCustomFields = false }) => {
  return <Field
    key={question.fieldIdentifier}
    name={`custom_fields.${question.fieldIdentifier}`}
    label={question.label}
    size="sm"
    component={Date}
    validate={!skipCustomFields && question.required ? required() : null}
  />;
};

const TextAreaQuestion = ({ question, skipCustomFields = false }) => {
  return <Field
    key={question.fieldIdentifier}
    name={`custom_fields.${question.fieldIdentifier}`}
    label={question.label}
    rows={12}
    wrapperClassName="max-w-full mb-5 mr-6"
    component={Textarea}
    validate={!skipCustomFields && question.required ? required() : null}
  />;
};

const CorporateSpecificQuestions = (props) => {
  const { corporateAccount } = props;
  const [skipCustomFields, setSkipCustomFields] = useState(false);
  if (!corporateAccount || !corporateAccount.custom_field_questions) {
    return null;
  }
  let questions = JSON.parse(corporateAccount.custom_field_questions);

  let getQuestionComponent = (questionType, question) => {
    switch (questionType) {
      case "text":
        return <TextQuestion
          key={question.fieldIdentifier}
          question={question}
          skipCustomFields={skipCustomFields}
        />;
      case "checkbox":
        return <CheckboxQuestion
          key={question.fieldIdentifier}
          question={question}
          skipCustomFields={skipCustomFields}
        />;
      case "dropdown":
        return <DropdownQuestion
          key={question.fieldIdentifier}
          question={question}
          skipCustomFields={skipCustomFields}
        />;
      case "date":
        return <DateQuestion
          key={question.fieldIdentifier}
          question={question}
          skipCustomFields={skipCustomFields}
        />;
      case "numeric":
        return <NumericQuestion
          key={question.fieldIdentifier}
          question={question}
          skipCustomFields={skipCustomFields}
        />;
      case "textarea":
        return <TextAreaQuestion
          key={question.fieldIdentifier}
          question={question}
          skipCustomFields={skipCustomFields}
        />;
      default:
        return null;
    }
  };

  if (!questions) {
    return null;
  }

  return (
    <div className="row">
      <div className="col lg:col-w-1/2">
        <div className="p-4 bg-light-grey mt-4">
          <h3>Custom fields</h3>
          <p>Please complete the following additional information</p>
          {props.isSuperAgent ? (
            <Field
              name="skip_custom_fields"
              label="Skip Custom Fields?"
              component={Toggle}
              onChange={setSkipCustomFields}
            />
          ) : null}
          {questions.map(question => {
            return getQuestionComponent(question.fieldType, question);
          })}
        </div>
      </div>
    </div>
  );
};

export default connect(
  null,
  null,
)(CorporateSpecificQuestions);
