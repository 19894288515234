const alphabetiseByName = (a, b) =>
  a.name.localeCompare(b.name, undefined, { sensitivity: "base" });

const groupedEventStatusOptions = [
  { label: "Provisional", value: "Provisional" },
  { label: "Confirmed", value: "Confirmed" },
  { label: "Cancelled", value: "Cancelled" },
  { label: "Revising Confirmation", value: "Revising Confirmation" },
];

const isProd = () => {
  return (
    !!window &&
    window?.EDGE_VERSION?.toString() !== "dev" &&
    !window?.EDGE_VERSION?.toString().includes("stage")
  );
};
const isNotProd = () => {
  return (
    !!window &&
    (window?.EDGE_VERSION?.toString() === "dev" ||
      !!window?.EDGE_VERSION?.toString().includes("stage"))
  );
};

export { isProd, isNotProd, alphabetiseByName, groupedEventStatusOptions };
