// import get from "lodash/fp/get";
import get from "lodash/get";
import anyPass from "lodash/fp/anyPass";
import {PERMISSIONS} from "../constants";

/**
 * Getters
 */

// auth:Object -> [role:String]
const getRoles = auth => get(auth, "role", "").split(":");
const getPermissions = auth => get(auth, "permission", "").split(":")

// role:String -> auth:Object -> Boolean
const hasRole = role => auth => getRoles(auth).includes(role);
const hasPermission = permission => auth => getPermissions(auth).includes(permission);
export const venueHasPermission = (venue,permission) => venue.permissions?.includes(permission);

export const hasVenues = auth =>
  Array.isArray(auth.venues) && auth.venues.length > 0;

// export const hasPaidVenues = auth =>
//   Array.isArray(auth.venues) &&
//   auth.venues.filter(v => v.temp_permission === "paid").length > 0;
//
// export const hasPremiumVenues = auth =>
//   Array.isArray(auth.venues) &&
//   auth.venues.filter(v => v.temp_permission === "premium").length > 0;

const getCompany = auth => {
  return (auth?.company ?? auth?.companyObject) ?? false;
}

const getCorporateAccount = auth => {
  return (auth?.company?.corporate_account ?? auth?.corporateAccount) ?? false;
}

export const hasCorporateAccount = auth => {
  // console.log({a: auth})
  return !!getCorporateAccount(auth);
}

export const hasCorporateKpiDocument = auth => {
  return !!getCorporateAccount(auth)?.kpi_document
}

export const hasActiveCorporateAccount = auth => {
  // console.log({a: auth})
  return getCorporateAccount(auth)?.is_active;
}
export const hasCorporateAccess = auth => {
  return !!auth?.user?.has_corporate_access;
}
export const corporateAccountId = auth => {
  // console.log({a: auth})
  // console.log(auth)
  return getCorporateAccount(auth)?.id ?? false;
}

export const corporateAccountObject = auth => {
  // console.log({a: auth})
  return getCorporateAccount(auth);
}


export const companyObject = auth => {
  // console.log({a: auth})
  return getCompany(auth); //.company ? auth.company : {};
}

export const agencyObject = auth => {
  return auth.agency && (typeof auth.agency !== undefined) ? auth.agency : {};
}

export const hasLicensedVenues = auth => Array.isArray(auth.venues) && auth.venues.some(venue => !venue.is_opted_out);
export const hasAnyVenueWithPermission = (auth,permissionString) => Array.isArray(auth.venues) && auth.venues.some(venue => venueHasPermission(venue,permissionString));
export const hasAnyVenueWithAnyPermission = (auth, permissionStringArray) => permissionStringArray.some(permissionString => hasAnyVenueWithPermission(auth,permissionString));

/**
 * Has Role Selectors
 * auth:Object -> Boolean
 */

export const isRoleSuperAdmin = hasRole("super-admin");
export const isRoleEdgeAdmin = hasRole("edge-admin");
export const isRoleJuniorAgent = hasRole("junior_agency_user");
export const isRoleTrinity = hasRole("trinity");
export const isRoleTrinityUser = hasRole("trinity-user");
// export const isRoleTrinityEditor = hasRole("trinity-editor");
export const isMarketingManager = hasRole("marketing-manager");
export const isRoleAccountManager = hasRole("account-manager");
export const isRoleTrinityEdgeRFP = hasRole('trinity-edge-rfp');
export const isVenueRFPResponder = hasRole('venue-rfp-responder');
export const isSuperAgent = hasRole('super_agent');
export const isAgencyAdmin = hasRole('agency_administrator');
export const isAgencyUser = hasRole('agency_user');
export const isCompanyAdmin = hasRole('company_administrator');
export const isCompanyUser = hasRole('company_user');
export const isHeadOfProcurement = hasRole('head_of_procurement');
export const isHeadOfEvents = hasRole('head_of_events');
export const isNonEventSpecialist = hasRole('non_event_specialist');
export const isEventSpecialist = hasRole('event_specialist');

export const isEdgeSupport = hasRole('edge-support');
export const isEdgeFinance = hasRole('edge-finance');


export const isRolePlatformAdmin = anyPass([
  isRoleSuperAdmin,
  isRoleEdgeAdmin,
]);
export const isAdminSupportOrFinance = anyPass([
  isRoleEdgeAdmin,
  isEdgeSupport,
  isEdgeFinance,
]);


export const canSeeAnalytics = auth => {
  return (isRolePlatformAdmin(auth) || isEdgeSupport(auth) || hasAnyVenueWithPermission(auth, PERMISSIONS.CAN_BE_INCLUDED_IN_SITE_ANALYTICS)  || isRoleAccountManager(auth)) && hasPermission(PERMISSIONS.READ_ANALYTICS)(auth);
}

export const canSeeSubscriptions = auth => {
  return (isInternal(auth) || isRoleAccountManager(auth) || isRoleTrinityUser(auth)) && hasPermission(PERMISSIONS.READ_SUBSCRIPTIONS)(auth);
}
export const canCreateSubscriptions = auth => (canSeeSubscriptions(auth) && hasPermission(PERMISSIONS.CREATE_SUBSCRIPTIONS)(auth));

export const canEditVenue = hasPermission(PERMISSIONS.UPDATE_VENUE);
export const canCreateVenue = hasPermission(PERMISSIONS.CREATE_VENUE);
export const canEditUsers = hasPermission(PERMISSIONS.UPDATE_USER);
export const canEditRoles = hasPermission(PERMISSIONS.UPDATE_LARAVEL_ROLES);
export const canCreateOwnChecksheet = hasPermission(PERMISSIONS.CAN_CREATE_OWN_CHECKSHEET);
export const canCreateAllChecksheets = hasPermission(PERMISSIONS.CAN_CREATE_ALL_CHECKSHEETS);
export const canReadChecksheet = hasPermission(PERMISSIONS.CAN_READ_CHECKSHEET);
export const canCreateRfp = hasPermission(PERMISSIONS.CAN_CREATE_RFP);
export const canReadRfp = hasPermission(PERMISSIONS.CAN_READ_RFP);
export const canManageRfp = hasPermission(PERMISSIONS.CAN_MANAGE_RFP);
export const canAdministerCompany = hasPermission(PERMISSIONS.CAN_ADMINISTER_COMPANIES);
export const canAdministerAgencies = hasPermission(PERMISSIONS.CAN_ADMINISTER_AGENCIES);
export const canManageCompanyUsers = hasPermission(PERMISSIONS.CAN_MANAGE_COMPANY_USERS);
export const canViewEvents = hasPermission(PERMISSIONS.CAN_VIEW_EVENTS);
export const canManageEventCompletion = hasPermission(PERMISSIONS.CAN_MANAGE_EVENT_COMPLETION);
export const canHaveCorporateAccounts =  auth => auth.user && auth.user.agency && auth.user.agency.can_have_corporate_accounts;
export const canManageEventPreData = hasPermission(PERMISSIONS.CAN_MANAGE_PRE_EVENT_DATA);
export const canChaseEvent = hasPermission(PERMISSIONS.CAN_CHASE_EVENT);
export const canCancelEvent = hasPermission('can_cancel_event'); // PERMISSIONS.CAN_CANCEL_EVENT); // 'can_cancel_event' ???????

export const canCreateHighlyConfidentialRfp = hasPermission(PERMISSIONS.CAN_CREATE_HIGHLY_CONFIDENTIAL_RFP);
export const canViewHighlyConfidentialRfp = hasPermission(PERMISSIONS.CAN_VIEW_HIGHLY_CONFIDENTIAL_RFP);

// Corporate specific permissions
export const canAccessCorporateLocations = hasPermission(PERMISSIONS.CAN_ACCESS_LOCATIONS);
export const canAccessCorporateCalendar = hasPermission(PERMISSIONS.CAN_ACCESS_CORPORATE_CALENDAR);
export const canAccessCorporateDocuments = hasPermission(PERMISSIONS.CAN_ACCESS_DOCUMENTS);
export const canAccessEdgeTeamUpdates = hasPermission(PERMISSIONS.CAN_ACCESS_EDGE_TEAM_UPDATES);

export const isIndividualUserType = auth => auth.user && auth.user.user_type && auth.user.user_type.name === 'Individual'
// export const isVenueUserType = auth => auth.user && auth.user.user_type && auth.user.user_type.name === 'Venue'
export const isAgencyUserType = auth => auth.user && auth.user.user_type && auth.user.user_type.name === 'Agency'

export const isInternal = anyPass([
  isRoleSuperAdmin,
  isRoleEdgeAdmin,
  isEdgeSupport,
  isEdgeFinance,

]);

/**
 * Action Permissions
 * auth:Object -> Boolean
 */

export const canDeleteVenue = isRolePlatformAdmin;
export const canSetVenueLevel = isRolePlatformAdmin;
export const canEditVenueName = isRolePlatformAdmin;
export const canRecalculateVenueScore = isRolePlatformAdmin;


export const canSeeVenuesAdminArea = anyPass([isRolePlatformAdmin, canCreateVenue, canEditVenue]);


