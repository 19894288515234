import React from "react";
import { reportsCurrencyFormatter } from "../../../utils";

const CashFlowReportTable = ({ cashFlowReport = [] }) => {
  if (cashFlowReport.length === 0) return <></>;

  const startMonth = cashFlowReport?.start_month;
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const monthKeys = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

  const reorderMonths = (start) => {
    const startIndex = start - 1;
    return {
      reorderedMonths: [...months.slice(startIndex), ...months.slice(0, startIndex)],
      reorderedMonthKeys: [...monthKeys.slice(startIndex), ...monthKeys.slice(0, startIndex)],
    };
  };

  const { reorderedMonths, reorderedMonthKeys } = reorderMonths(startMonth);

  const totalConfirmedEvents = reorderedMonthKeys.reduce((sum, month) => sum + (cashFlowReport.confirmed_events_count[month]?.numberOfEvents || 0), 0);
  const totalConverted = reorderedMonthKeys.reduce((sum, month) => sum + (cashFlowReport.converted_confirmed_events[month]?.numberOfEvents || 0), 0);
  const totalConfirmedValues = reorderedMonthKeys.reduce((sum, month) => sum + (cashFlowReport.confirmed_events_values[month]?.event_values || 0), 0).toFixed(2);
  const totalConfirmedConvertedValues = reorderedMonthKeys.reduce((sum, month) => sum + (cashFlowReport.converted_confirmed_values[month]?.event_values || 0), 0).toFixed(2);
  const totalDeniedValues = reorderedMonthKeys.reduce((sum, month) => sum + (cashFlowReport.denied_events_values[month]?.event_values || 0), 0).toFixed(2);
  const totalProvisionalValues = reorderedMonthKeys.reduce((sum, month) => sum + (cashFlowReport.provisional_events_values[month]?.event_values || 0), 0).toFixed(2);

  return (
    <div className={"min-w-full overflow-y-auto"}>
      <table className={"edge-table no-bg-edge-table"}>
        <thead>
        <tr>
          <th></th>
          {reorderedMonths.map((month, index) => (
            <th key={index}>{month}</th>
          ))}
          <th>Total</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <strong>Confirmed Events in Month (#) event date</strong>
          </td>
          {reorderedMonthKeys.map((month) => (
            <td key={month}>{cashFlowReport.confirmed_events_count[month]?.numberOfEvents || 0}</td>
          ))}
          <td>
            <strong>{totalConfirmedEvents}</strong>
          </td>
        </tr>
        <tr>
          <td>
            <strong>Provisional (£) event date</strong>
          </td>
          {reorderedMonthKeys.map((month) => (
            <td key={month}>
              {cashFlowReport.provisional_events_values[month]
                ? reportsCurrencyFormatter("GBP").format(cashFlowReport.provisional_events_values[month].event_values)
                : 0}
            </td>
          ))}
          <td>
            <strong>{reportsCurrencyFormatter("GBP").format(totalProvisionalValues)}</strong>
          </td>
        </tr>
        <tr>
          <td>
            <strong>Denied (£) event date</strong>
          </td>
          {reorderedMonthKeys.map((month) => (
            <td key={month}>
              {cashFlowReport.denied_events_values[month]
                ? reportsCurrencyFormatter("GBP").format(cashFlowReport.denied_events_values[month].event_values)
                : 0}
            </td>
          ))}
          <td>
            <strong>
              {reportsCurrencyFormatter("GBP").format(totalDeniedValues)}
            </strong>
          </td>
        </tr>
        <tr>
          <td>
            <strong>Confirmed (£) event date</strong>
          </td>
          {reorderedMonthKeys.map((month) => (
            <td key={month}>
              {cashFlowReport.confirmed_events_values[month]
                ? reportsCurrencyFormatter("GBP").format((cashFlowReport.confirmed_events_values[month].event_values))
                : 0}
            </td>
          ))}
          <td>
            <strong>{reportsCurrencyFormatter("GBP").format(totalConfirmedValues)}</strong>
          </td>
        </tr>
        <tr>
          <td>
            <strong>Confirmed target (£)</strong>
          </td>
          {reorderedMonths.map((_, month) => (
            <td key={month}>
              {cashFlowReport && cashFlowReport.annual_confirmed_target ? (
                reportsCurrencyFormatter("GBP").format(cashFlowReport.annual_confirmed_target / 12)
              ) : "-"}
            </td>
          ))}
          {/*{reorderedMonthKeys.map((month) => (*/}
          {/*  <td key={month}>*/}
          {/*    {cashFlowReport.confirmed_events_values[month]*/}
          {/*      ? reportsCurrencyFormatter("GBP").format((cashFlowReport.confirmed_events_values[month].event_values))*/}
          {/*      : 0}*/}
          {/*  </td>*/}
          {/*))}*/}
          <td>
            <strong>{reportsCurrencyFormatter("GBP").format(totalConfirmedValues)}</strong>
          </td>
        </tr>
        <tr>
          <td>
            <strong>Converted</strong>
          </td>
          {reorderedMonthKeys.map((month) => (
            <td key={month}>{cashFlowReport.converted_confirmed_events[month]?.numberOfEvents || 0}</td>
          ))}
          <td>
            <strong>{totalConverted}</strong>
          </td>
        </tr>
        <tr>
          <td>
            <strong>Converted Value</strong>
          </td>
          {reorderedMonthKeys.map((month) => (
            <td key={month}>
              {cashFlowReport.converted_confirmed_values[month]
                ? reportsCurrencyFormatter("GBP").format(cashFlowReport.converted_confirmed_values[month].event_values)
                : 0}
            </td>
          ))}
          <td>
            <strong>{reportsCurrencyFormatter("GBP").format(totalConfirmedConvertedValues)}</strong>
          </td>
        </tr>
        <tr>
          <td>
            <strong>Variance</strong>
          </td>
          {reorderedMonths.map((_, month) => (
            <td key={month}>
              {cashFlowReport && cashFlowReport.annual_confirmed_target ? (
                reportsCurrencyFormatter("GBP").format(cashFlowReport.confirmed_events_values[month]?.event_values - (cashFlowReport.annual_confirmed_target / 12))
              ) : "-"}
            </td>
          ))}
        </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CashFlowReportTable;
