import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  change,
  Field,
  FieldArray,
  getFormValues,
  reduxForm,
} from "redux-form";
import moment from "moment";
import { Toggle, Input } from "Components/Form";
import { ButtonBrand } from "Components/Button";
import { stage2 as v } from "./validate";
import RfpSubmit from "./RfpSubmit";
import {Textarea} from "../../ui";

const getDates = (from, to) => {
  let current = moment(from).subtract(0, "days");
  const dates = [];

  while (moment(current).isSameOrBefore(to, "day")) {
    dates.push({
      date: current,
      quantity: 0,
    });
    current = moment(current).add(1, "days");
  }

  return dates;
};

const getNewDate = (values, before) => {
  if (!values.bedroom_dates.length) {
    return moment(values.date_from);
  }

  if (before) {
    return moment(values.bedroom_dates[0].date).subtract(1, "days");
  }

  return moment(values.bedroom_dates[values.bedroom_dates.length - 1].date).add(
    1,
    "days"
  );
};

const renderNights = ({ fields, meta: { error, submitFailed }, values }) => (
  <div>
    <div className="flex flex-wrap">
      <ButtonBrand
        style={{ marginTop: 22.5 }}
        classes="mb-5 mr-8"
        onClick={() =>
          fields.unshift({
            date: getNewDate(values, true),
            quantity: 0,
          })
        }
      >
        Add night before
      </ButtonBrand>
      {fields.map((member, index) => (
        <div key={index} className="flex items-start">
          <Field
            name={`${member}.quantity`}
            label={`${moment(values.bedroom_dates[index].date).format(
              "Do MMM YYYY"
            )}`}
            component={Input}
            wrapperClassName="w-32 mr-1 mb-8"
            validate={v.quantity}
          />
          <i
            className="fal fa-times-circle pointer mr-5"
            onClick={() => fields.remove(index)}
          />
        </div>
      ))}
      <ButtonBrand
        classes="mb-5"
        style={{ marginTop: 22.5 }}
        onClick={() => fields.push({ date: getNewDate(values), quantity: 0 })}
      >
        Add night after
      </ButtonBrand>
    </div>
    {submitFailed && error && (
      <div className="text-warning my-1 mb-3">{error}</div>
    )}
  </div>
);

const Stage2 = ({ handleSubmit, prevPage, form, requiresAuthorising }) => {
  const values = useSelector(getFormValues(form));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!values.bedroom_dates) {
      if (values.bedrooms_required) {
        const dates = getDates(values.date_from, values.date_to);

        dispatch(change(form, "bedroom_dates", dates));
      }
    }
  }, []);

  useEffect(() => {
    if (
      values.bedrooms_required &&
      !(values.bedroom_dates && values.bedroom_dates.length)
    ) {
      const dates = getDates(values.date_from, values.date_to);

      dispatch(change(form, "bedroom_dates", dates));
    }
    if (!values.bedrooms_required) {
      dispatch(change(form, "bedroom_dates", []));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.bedrooms_required]);

  return (
    <form onSubmit={handleSubmit}>
      {!values.is_accommodation_only ? (
        <Field
          name="bedrooms_required"
          label="Do you require bedrooms"
          component={Toggle}
          wrapperClassName="w-full mb-8"
          validate={v.bedrooms_required}
        />
      ) : null}
      {values && values.bedrooms_required ? (
        <>
          <FieldArray
            name="bedroom_dates"
            component={renderNights}
            props={{values}}
          />
          <Field
            name="bedroom_additional_notes"
            label="Additional Bedroom Notes"
            rows={12}
            wrapperClassName="w-full mb-8"
            component={Textarea}
            validate={v.bedroom_additional_notes}
          />
        </>
      ) : null}
      <RfpSubmit page={1} prevPage={prevPage} requiresAuthorising={requiresAuthorising}/>
    </form>
  );
};

export default reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Stage2);
