import React, {Component} from "react";
import {Field, reduxForm} from "redux-form";
import {connect} from 'react-redux';
import Loading from "../Loading";
import {ButtonPrimary} from "../Button";
import {Select, Textarea} from "../../ui";
import {objectDiff, processFormValues, renderFormInput} from "../../modules/formTools";
import {Failed, Input, Succeeded} from "../Form";
import isArray from "lodash/isArray";
import {required} from "../../modules/validation";
import {token} from "../../modules/auth";
import {agencyCompanyDomains} from "../../services/companyService/agencyCompanyDomains";

const v = {
    required: required(),
};

const mapStateToProps = (state, props) => {
    let initialValues = {};
    let legalText = "<p>The contract for this event will be between :companyVar and the :venueVar. Please send the contract directly to the client. All deposits and accounts will be settled directly between the venue and the client. :agencyVar has acted as an agency in respect of this booking and will claim commission as per the below breakdown. :agencyVar provides a venue finding service both in the UK and Internationally. </p><p>Prices quoted include tax at the rate specified, but are subject to change</p>";
    let legalTTextInvoice = "<p>Payment is due within 14 days from the date of the invoice.<br/>Please quote invoice number on all payment advice.</p>";

    if (Object.entries(props.company).length > 0) {
        initialValues = {   ...props.company, ...props.company.address, legal_text: props.company?.legal_text || legalText, legal_text_invoice: props.company?.legal_text_invoice || legalTTextInvoice};
    }

    if (props.agencyCode) {
        initialValues.agency_code = props.agencyCode
    }
    return {
        initialValues,
        token: token(state),
    };
};

class CompanyForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: '',
            companyDomains: null,
            domainError: []
        };
    }

    componentDidMount() {
        agencyCompanyDomains(this.props.token).then((response) => {
            this.setState({
                companyDomains: response.result
            })
        })
    }


    onSubmit = formValues => {
        this.setState({
            errorMessage: '',
        })
        delete this.props.initialValues.agency_code;
        const formData = new FormData();
        if (this.props.dirty && (Object.keys(this.props.initialValues).length > 0)) {
            formData.append("_method", "PATCH");
            const data = objectDiff(this.props.initialValues, formValues)

            Object.entries(data).forEach(([key, value]) => {
                if (value && !isArray(value)) {
                    formData.append(key, value)
                } else {
                    if (value && value.length > 0) {
                        formData.append(key, JSON.stringify(value))
                    }
                    if (key == 'address2' || key == 'address3') {
                        formData.append(key, value)
                    }
                }
            });
            return this.props.onSubmit(formData, this.props.token).catch((errors) => {
                if (errors?.response?.data?.status === 'error') {
                    this.setState({
                        errorMessage: errors.response.data.message
                    })
                }
                if (errors?.errors?.name) {
                    this.setState({
                        errorMessage: errors?.errors?.name
                    })
                }
            });
        } else {
            Object.entries(formValues).forEach(
                ([key, value]) => value && formData.append(key, value)
            );
            if (this.props.isSuperAgent && this.props.selectedAgencies && this.props.selectedAgencies.length === 1 && typeof this.props.selectedAgencies[0].id !== 'undefined') {
                formData.append('agency_id', this.props.selectedAgencies[0].id)
            }
            try {
                return this.props.onSubmit(formData, this.props.token).catch((errors) => {
                    if (errors?.response?.data?.status === 'error') {
                        this.setState({
                            errorMessage: errors.response.data.message
                        })
                    }
                    if (errors?.errors?.name) {
                        this.setState({
                            errorMessage: errors?.errors?.name
                        })
                    }
                });
            } catch (error) {
                return this.props.onSubmit(formData, this.props.token)
            }
        }
        return processFormValues(this.props, formValues, this.props.initialValues);
    }

    render() {
        const {
            company,
            countries,
            industries,
            bookingDesks,
            agency,
            selectedAgencies,
            isSuperAgent,
            fullWidth = false
        } = this.props;
        const options = [];
        if (Object.entries(countries).length > 0) {
            countries.sort((a, b) => a.name.localeCompare(b.name))
            countries.map((country) => {
                options.push({
                    text: country.name,
                    value: country.id
                })
            })
            options.forEach(function (country, i) {
                if (country.text === "United Kingdom") {
                    options.splice(i, 1);
                    options.unshift(country);
                }
            });
        }
        let industriesOptions = [];

        let bookingDeskOptions = [];
        if (bookingDesks && agency && agency.name && typeof bookingDesks[agency.name] !== 'undefined' && bookingDesks[agency.name].length > 0) {

            bookingDesks[agency.name].map(desk => {
                bookingDeskOptions.push({
                    text: desk.name,
                    value: desk.id
                })
            })
        }

        if (industries && Object.entries(industries).length > 0) {
            industries.map(industry => {
                industriesOptions.push({
                    text: industry.data.name,
                    value: industry.data.id,
                })
            })
        }
        const rfpXpressOptions = [
            {
                text: "Yes",
                value: 1,
            },
            {
                text: "No",
                value: 0,
            }
        ];

        const ssoMethodOptions = [{
            text: "Token",
            value: "token"
        }];


        return (
            <div>
                <div className="overflow-hidden border-gray-200 sm:rounded-lg">
                    <form className="border-none" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                        <div className={'row'}>
                            <div className={`col col-w-full mb-6 ${fullWidth ? '' : 'md:col-w-1/2'}`}>
                                <Field name="name" label="Company Name" component={renderFormInput}
                                       disabled={this.props.isCompanyAdmin && (!this.props.isAgencyAdmin && !this.props.isRoleEdgeAdmin)}
                                       validate={v.required}/>
                                <Field name="address1" label="Address Line 1" component={renderFormInput}
                                       disabled={this.props.isCompanyAdmin && (!this.props.isAgencyAdmin && !this.props.isRoleEdgeAdmin)}
                                       validate={v.required}/>
                                <Field name="address2" label="Address Line 2" component={renderFormInput}
                                       disabled={this.props.isCompanyAdmin && (!this.props.isAgencyAdmin && !this.props.isRoleEdgeAdmin)}/>
                                <Field name="address3" label="Address Line 3" component={renderFormInput}
                                       disabled={this.props.isCompanyAdmin && (!this.props.isAgencyAdmin && !this.props.isRoleEdgeAdmin)}/>
                                <Field name="city" label="City" component={renderFormInput}
                                       disabled={this.props.isCompanyAdmin && (!this.props.isAgencyAdmin && !this.props.isRoleEdgeAdmin)}
                                       validate={v.required}/>
                                <Field name="county" label="County" component={renderFormInput}
                                       disabled={this.props.isCompanyAdmin && (!this.props.isAgencyAdmin && !this.props.isRoleEdgeAdmin)}
                                       validate={v.required}/>
                                <Field name="postcode" label="Postcode" component={renderFormInput}
                                       disabled={this.props.isCompanyAdmin && (!this.props.isAgencyAdmin && !this.props.isRoleEdgeAdmin)}
                                       validate={v.required}/>
                                <Field name="country_id" label="Country" component={Select} options={options}
                                       disabled={this.props.isCompanyAdmin && (!this.props.isAgencyAdmin && !this.props.isRoleEdgeAdmin)}
                                       validate={v.required}/>
                                <Field name="industry_id" label="Industry Sector" component={Select}
                                       options={industriesOptions}
                                       disabled={this.props.isCompanyAdmin && (!this.props.isAgencyAdmin && !this.props.isRoleEdgeAdmin)}
                                       validate={!this.props.isRoleEdgeAdmin ? v.required : ''}/>
                                {bookingDeskOptions && bookingDeskOptions.length > 0 ?
                                    <Field name="default_booking_desk_id" label="Default Booking Desk"
                                           component={Select} options={bookingDeskOptions}/> : null}

                                <Field name="approved_domains"
                                       label="Approved Domains (comma seperated eg. google.co.uk, outlook.com)"
                                       component={renderFormInput} validate={v.required}
                                       onChange={(e) => {
                                           let domains = e.target.value.replace(/\s+/g, '').split(",")
                                           let match = false;
                                           domains.forEach(domain => {
                                               this.state.companyDomains.forEach(companyDomain => {
                                                   if (typeof (domain) !== 'undefined' && domain && companyDomain.approved_domains) {
                                                       let approvedDomains = companyDomain.approved_domains.trim().split(",");
                                                       approvedDomains.forEach((approvedDomain) => {

                                                           if (domain === approvedDomain.trim()) {
                                                               match = true;
                                                               let errorMessage = 'Are you sure? One of the domains listed is in use beneath a different Company [' + companyDomain['name'] + ']';
                                                               if (this.state.domainError.indexOf(errorMessage) === -1) {
                                                                   this.setState({domainError: [...this.state.domainError, errorMessage]})

                                                               }
                                                           }
                                                       })
                                                   }

                                               })
                                           })
                                           if (!match) {
                                               this.setState({
                                                   domainError: []
                                               })
                                           }
                                       }}/>

                                {this.props.company.agency && Boolean(this.props.company.agency.can_manage_specific_company_legal_text) && (this.props.isAgencyAdmin || this.props.isRoleEdgeAdmin) && (
                                    <div>
                                        <Field
                                            name="legal_text"
                                            label="Terms and conditions for RFP and confirmation."
                                            component={Textarea}

                                        />

                                        <div className="mb-4 text-xs border border-grey p-2">
                                            <p>
                                                Legal Text replaces the default content displayed on the Confirmation
                                                PDF.
                                                Please use variables below in
                                                the same format for Legal Text (valid HTML allowed):
                                            </p>
                                            <ul className="list-reset font-bold">
                                                <li>:clientVar</li>
                                                <li>:venueVar</li>
                                                <li>:agencyVar</li>
                                                <li>:companyVar</li>
                                            </ul>
                                        </div>
                                        <Field
                                            name="legal_text_invoice"
                                            label="Terms and conditions for Invoice."
                                            component={Textarea}
                                        />
                                    </div>
                                )}

                                {this.state.domainError.length > 0 ? this.state.domainError.map(error => {
                                    return <div className={'my-2'}>{error ?
                                        <span className="text-warning font-normal">{error}</span> : null}</div>
                                }) : null}
                                {company && company.corporate_account && company.sso_method !== 'saml2' ? (
                                    <Field name="sso_method" label="SSO Method" component={Select}
                                           options={ssoMethodOptions}/>
                                ) : null}

                                {this.props.company.agency && Boolean(this.props.company.agency.offer_rfp_xpress_service) ? (
                                    <Field name="offer_rfp_xpress_service" label="Express 2 Hour SLA Service?"
                                           component={Select} options={rfpXpressOptions} noPlaceholder={true}/>
                                ) : null}

                                {this.props.isCompanyAdmin && (!this.props.isAgencyAdmin && !this.props.isRoleEdgeAdmin) && this.props.company.agency ?
                                    <p>To edit this part of your profile, please
                                        contact {this.props.company.agency.name} via {this.props.company.agency.email_address} / {this.props.company.agency.phone_number}</p> :
                                    <ButtonPrimary type="submit">
                                        {this.props.submitting ? <Loading inline/> : "Save"}
                                    </ButtonPrimary>
                                }
                                {this.props.submitSucceeded && !this.props.company && !this.props.submitError && !this.state.errorMessage &&
                                    <Succeeded text="Company created successfully"/>}
                                {this.props.submitSucceeded && this.props.company && !this.props.submitError && !this.state.errorMessage &&
                                    <Succeeded/>}
                                {this.props.errorMessage && this.props.submitError ?
                                    <Failed leftAlign text={this.props.errorMessage}/> : null}
                                {(this.props.company && this.props.company.error) || this.props.submitError || this.state.errorMessage &&
                                    <Failed leftAlign text={this.state.errorMessage ? this.state.errorMessage : null}/>}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

//const validate = {
//  name: required("Please enter a Company Name"),
//  code: required("Please enter a Company Code"),
//  address1: required("Please enter Address Line 1"),
//  address2: required("Please enter Address Line 2"),
//  city: required("Please enter City"),
//  county: required("Please enter County"),
//  postcode: required("Please enter Postcode"),
//  country_id: required("Please choose a country")
//};

CompanyForm = reduxForm({
    form: 'companyForm',
    enableReinitialize: true,
    destroyOnUnmount: false,
})(CompanyForm);

export default connect(mapStateToProps, null)(CompanyForm);
