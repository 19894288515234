import React, {useEffect, useState} from "react";
import { Field, getFormValues, reduxForm } from "redux-form";
import { useSelector } from "react-redux";
import {
  Date as DateField,
  Input,
  Toggle,
  Textarea,
  Select,
} from "Components/Form";
import { stage1 as v } from "./validate";
import RfpSubmit from "./RfpSubmit";
import Loading from "../../Components/Loading";
import BriefPreferences from "./BriefPreferences";
import * as CORPORATE_ROUTES from "../../Router/corporate_routes";
import UnapprovedAgency from "../../Components/corporate/agency/UnapprovedAgency";

const Stage1 = ({ prevPage, form, handleSubmit, roomStyleOptions, hasVenues, editEnquiry, isSuperAgent, isAgency, isVenueUser, briefPreferencesRelevant, enquiry, setShowDateWarningMessage, change, requiresAuthorising, disableMaxDelegates, userAgency }) => {
  const values = useSelector(getFormValues(form)) || {};
  const [dateChanged, setDateChanged] = useState(false);
  // useEffect(() => {
  //   change('event_name', 'Testing')
  //   change('number_of_delegates', 8)
  //   change('date_from', new Date())
  //   change('date_to', new Date())
  //   change('room_style_id', 3)
  // }, [])

  useEffect(() => {
    // set date_to = date_from on initial set of date_from
    if ( values['date_from'] && !values['date_to'] ) {
      change('date_to', values['date_from']);
    }
    if (enquiry && enquiry.enquiry_venue_datas) {
      enquiry.enquiry_venue_datas.forEach(evd => {
        if (dateChanged && evd.status > 40) {
          setShowDateWarningMessage(true)
        }
      })
    }
  }, [enquiry, dateChanged])

  useEffect(() => {
    if (values.is_accommodation_only) {
      change('room_style_id', 8)
      change('bedrooms_required', 1)
    }
  }, [values['is_accommodation_only'], values['room_style_id']])

  if (!roomStyleOptions) {
    return <Loading large />;
  } else {
    let otherOption = roomStyleOptions.find(option => option.text === 'Other')
    if (otherOption) {
      otherOption.text = 'Other - Please add layout notes on Step 3'
    }
  }
  //TODO: REMOVE WHEN BACKDATED RFPS ARE IN
  return (
    <form onSubmit={handleSubmit}>
      <div className="row bg-light-blue pt-6 mb-4" style={{marginLeft: "0", marginRight: "0", fontSize: "1rem"}}>
        <div className="col col-w-full font-bold">
          <Field
            name="is_accommodation_only"
            label="Is this booking for Accommodation only?"
            component={Toggle}
            wrapperClassName="mb-8 flex-grow"
            validate={v.accommodation_only}
          />
        </div>
      </div>
      <div className="row">
        <div className="col col-w-full xl:col-w-6/10">
          <div className="row">
            <div className="col col-w-full md:col-w-3/5">
              <Field
                name="event_name"
                label="Event name"
                component={Input}
                wrapperClassName="w-full mb-8 mr-2"
                validate={v.event_name}
              />
            </div>
            <div className={`col col-w-1/2 ${disableMaxDelegates ? "md:col-w-2/5" : "md:col-w-1/5"}`}>
              <Field
                name="number_of_delegates"
                label={disableMaxDelegates ? "Number of delegates" : "Min. delegates"}
                component={Input}
                wrapperClassName="w-full mb-8"
                validate={v.number_of_delegates}
              />
            </div>
            {disableMaxDelegates ? null :
              <div className="col md:col-w-1/5 col-w-1/2">
                <Field
                  name="maximum_number_of_delegates"
                  label="Max. delegates"
                  component={Input}
                  wrapperClassName="w-full mb-8"
                  validate={v.maximum_number_of_delegates}
                />
              </div>
            }
          </div>

          <div className="row">
            <div className="col col-w-1/2 md:col-w-1/3">
              <Field
                name="date_from"
                label="Date from"
                component={DateField}
                placeholder="Date from"
                onChange={() => setDateChanged(true)}
                wrapperClassName="w-full mb-8 mr-2"
                validate={!editEnquiry && !isSuperAgent && v.date_from}
              />
            </div>
            <div className="col col-w-1/2 md:col-w-1/3">
              <Field
                name="date_to"
                label="Date to"
                wrapperClassName="w-full mb-8 mr-2"
                onChange={() => setDateChanged(true)}
                component={DateField}
                placeholder="Date to"
                validate={!editEnquiry && !isSuperAgent && v.date_to}
              />
            </div>
            <div className="col col-w-full md:col-w-1/3">
              {values && values.date_to && values.date_from ? (
                <Field
                  name="date_flexible"
                  label="Flexible dates?"
                  component={Toggle}
                  wrapperClassName="mb-8 flex-grow"
                  validate={v.date_flexible}
                />
              ) : null}
            </div>
          </div>
          {values && values.date_flexible ? (
            <Field
              name="date_flexible_notes"
              label="Flexible dates notes"
              component={Textarea}
              rows={5}
              wrapperClassName="w-full mb-8"
              validate={v.date_flexible_note}
            />
          ) : null}
        </div>
        {!values['is_accommodation_only'] ? (
          <div className="col col-w-full xl:col-w-4/10">
            <Field
              name="room_style_id"
              label="Main room layout"
              component={Select}
              options={roomStyleOptions}
              wrapperClassName="mb-3"
              validate={v.room_style_id}
            />
            {values && values.room_style_id ? (
              <div
                className="re-16/9 mb-8 bg-cover"
                style={{
                  maxWidth: 300,
                  backgroundSize: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url('/img/assets/mr-${roomStyleOptions
                    .find(
                      option => option.value === parseInt(values.room_style_id),
                    )
                    .text.toLowerCase()
                    .replace("-", "")}.jpg')`,
                }}
              />
            ) : null}
          </div>
        ) : null}
      </div>
      <BriefPreferences editEnquiry={editEnquiry} hasVenues={hasVenues} />
      {(userAgency && userAgency.is_approved) || !userAgency ?
        <RfpSubmit page={0} prevPage={prevPage} isVenueUser={isVenueUser}
                   requiresAuthorising={requiresAuthorising} /> :
        <UnapprovedAgency userAgency={userAgency} />
      }

    </form>
  );
};

export default reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Stage1);
