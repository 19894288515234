import React from "react";
import RegistrationLandingPage from "Components/RegistrationLandingPage";

const Venues = () => {
  return (
    <RegistrationLandingPage
      img="/img/landing/conference-banner.jpg"
      title="Register today for maximum exposure of your venue through EDGE"
      heroBody="Register your venue with EDGE Venues for free and connect with event planners worldwide, increasing your visibility and securing high-quality enquiries through our innovative platform."
      bannerFeatures={[]}
      type="venue"
      successUrl="/venue-registration-complete"
      formColor="#847b68"
      bodyTitle="Why use EDGE for your venue?"
      bodyFeatures={[
        {
          icon: "user",
          title: "Free to Register",
          content: (
            <div>
              <p>
                Register for free today and join thousands of high-quality venues that corporates and agents rely on
                through EDGE Venues. Created by industry professionals, for the industry, our platform is designed to
                meet your needs, providing an efficient tool for planning creative meetings and events.
              </p>
            </div>
          ),
        },
        {
          icon: "search",
          title: "Simple and Effective RFP Process",
          content: (
            <div>
              <p>
                Simplicity is key. Buyers can submit requests to multiple venues through our streamlined RFP process,
                with price ranges clearly defined. This leads to high-quality enquiries. These requests are sent
                directly to you for completion, and once submitted, buyers can review, accept, or decline the venues of
                their choice.
              </p>
            </div>
          ),
        },
        {
          icon: "server",
          title: "Manage Your Own Data",
          content: (
            <div>
              <p>
                Make your venue stand out. With your own login, you can update and manage your data, ensuring your
                profile remains engaging. Personalise it using our 125 available fields* and add images. EDGE Venues
                enforces a minimum data requirement to ensure better quality enquiries for your venue.
              </p>
            </div>
          ),
        },
        {
          icon: "chart-pie",
          title: "Real-Time Analytics",
          content: (
            <div>
              <p>
                Track your venue&#39;s performance in real-time through your personal dashboard. Our analytics provide
                valuable insights, including the number of impressions, clicks, audience demographics, and how people
                are engaging with your venue. You can also view the value of the enquiries you&#39;re receiving.
              </p>
            </div>
          ),
        },
        {
          icon: "clipboard-check",
          title: "High-Quality Enquiries",
          content: (
            <div>
              <p>
                EDGE Venues focuses on quality, not quantity. Our efficient RFP process ensures only high-quality
                enquiries are sent to venues that meet the required data standards. This means clients have all the
                information they need to make informed requests, eliminating spam and irrelevant enquiries.
              </p>
            </div>
          ),
        },
        {
          icon: "chart-pie",
          title: "Knowledge Base",
          content: (
            <div>
              <p>
                While our platform is simple and easy to use, we’ve created helpful guides to support you along the way.
                If you need assistance, our team is just a chat, phone call, or email away, offering the personal touch
                alongside cutting-edge technology.
              </p>
            </div>
          ),
        },
      ]}
      splitFeatureTitle="Find out about becoming an EDGE Venues+ member today"
      splitFeatureList={[
        "Enhanced position on all searches",
        "Detailed management information",
        "Unlimited offer posting",
        "Unlimited news and blog posting",
        "Quarterly industry intelligence review",
      ]}
    />
  );
};

export default Venues;
