import React, { useState, useEffect } from "react";
import LiveSearch from "../Form/LiveSearch";
import { ButtonPrimary } from "../Button";
import Loading from "../Loading";
import AddFromShortlist from "./AddFromShortlist";
import { useSelector, useDispatch } from "react-redux";
import { getFormValues, reset } from "redux-form";
import { Column, Row } from "../grid";
import RestoreVenue from "./RestoreVenue";

export const AddVenue = ({
                           handleSubmit,
                           handleAddVenuesToRFP,
                           submitting,
                           formId = "addVenuesToRFP",
                           change,
                           rfpId,
                           errorMessage,
                         }) => {
  const [shortlistNames, setShortlistNames] = useState([]);
  const [shortlistIds, setShortlistIds] = useState([]);
  const values = useSelector(getFormValues(formId));
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(reset(formId));
    };
  }, []);
  return (
    <React.Fragment>
      <hr />
      <Row>
        <Column w="1/2">
          <div className={"flex flex-wrap flex-col mb-3 bg-white p-5"}>
            <h3 className={"mb-4"}>Need to add another venue? </h3>
            {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            <AddFromShortlist
              values={values}
              setShortlistNames={setShortlistNames}
              setShortlistIds={setShortlistIds}
              change={change}
              form={formId}
            />
            <form onSubmit={handleSubmit(handleAddVenuesToRFP)}>
              <React.Fragment>
                <div className="flex-row w-full md:w-1/2">
                  <LiveSearch
                    formId="addVenuesToRFP"
                    name="venue"
                    label="Search for a venue"
                    multiResultsClass="bg-grey h-full mb-1"
                    fieldClassName="max-w-full mb-1"
                    multiSearchClass="max-w-full mb-1"
                    wrapperClassName="flex flex-col"
                    auth
                    multi
                    url={`${window.API}/venues/quick`}
                    getText={item =>
                      `${item.display_name} (id: ${item.id})`
                    }
                  />
                  <div className="text-right">
                    <ButtonPrimary type="submit">
                      {submitting ? <Loading inline /> : "Add Venues"}
                    </ButtonPrimary>
                  </div>
                </div>
              </React.Fragment>
            </form>
          </div>

        </Column>
        <Column w="1/2">
          <div className={"flex flex-wrap flex-col mb-3 bg-white p-5"}>

            <h3 className={"mb-4"}>Restore a removed venue</h3>
            <RestoreVenue rfpId={rfpId} />

          </div>
        </Column>
      </Row>


    </React.Fragment>
  );
};
