import orderBy from "lodash/orderBy";

function userStateFromUser(user) {
  return {
    id: user.id,
    first_name: user.first_name,
    surname: user.surname,
    email: user.email,
    user_type: user.user_type,
    industry: user.industry_sector,
    company: user.company_name,
    company_id: user.company_id,
    is_sso_user: user.is_sso_user,
    corporate_account: user.company ? user.company.corporate_account : null,
    agency: user.agency ? user.agency : null,
    companyObject: user.company ? user.company : null,
    town: user.town,
    phone: user.phone,
    profileImagePath: user.profile_image_path,
    profileImageUrl: user.profile_image_url,
    hasBillingDetails: !!user.stripeDetails,
    email_verified: !!user.email_verified_at,
    has_corporate_access: !!user.has_corporate_access,
    analytics_group: user.analytics_group
      ? user.analytics_group.name
      : "undefined",
  };
}

function hotelBrandsStateFromUser(user) {
  const { hotel_brands: hotelBrands } = user;

  return Array.isArray(hotelBrands)
    ? hotelBrands.map(({ id, name }) => ({ id, name })) // Only keep the id and name
    : [];
}

function stateFromUser(user) {
  const roles = user.user_roles;
  const permissions = user.user_permissions;
  const shortlists = user.shortlists.filter(s => !s.search_raw);
  const savedSearches = user.shortlists.filter(s => s.search_raw);
  const notes = user.notes || [];
  const analyticsGroup = user.analytics_group || "undefined";
  const agency = user.agency || [];
  const companyObject = user.company || [];
  const corporateAccount = user.corporate_account || [];
  return {
    user: userStateFromUser(user),
    role: roles.join(":"),
    permission: permissions.join(":"),
    roles,
    permissions,
    shortlists,
    savedSearches,
    agency,
    companyObject,
    corporateAccount,
    hotelBrands: hotelBrandsStateFromUser(user),
    notes,
    analyticsGroup,
    refreshingUser: false
  };
}

function sortVenues(venues) {
  return Array.isArray(venues)
    ? orderBy(venues, venue => venue.name.toLowerCase(), "asc")
    : venues;
}

const reducer = (state = {}, action) => {
  switch (action.type) {
    case "LOGIN": {
      const { user, token, venues } = action.payload;

      return {
        ...state,
        ...stateFromUser(user),
        venues: sortVenues(venues),

        token,
      };
    }

    case "REFRESH_USER": {
      return { ...state, refreshingUser: true };
    }

    case "UPDATE_USER": {
      const { user } = action.payload;
      return { ...state, ...stateFromUser(user) };
    }

    case "UPDATE_USER_VENUES": {
      const { venues } = action.payload;
      return { ...state, venues: sortVenues(venues) };
    }
    case "UPDATE_USER_COMPANY": {
      const { company } = action.payload;
      return { ...state, company: company, companyObject: company };
    }
    case "DELETE_SHORTLIST_OR_SEARCH": {
      const data = [...state[action.payload.type]];
      const index = data.findIndex(s => s.id === action.payload.id);
      data.splice(index, 1);
      return {
        ...state,
        [action.payload.type]: data,
      };
    }

    case "ADD_SHORTLIST_OR_SEARCH": {
      return {
        ...state,
        [action.payload.type]: [
          ...state[action.payload.type],
          action.payload.data,
        ],
      };
    }

    case "UPDATE_SHORTLIST": {
      return {
        ...state,
        shortlists: state.shortlists.map(s => {
          if (s.id !== action.payload.shortlistId) return s;
          return action.payload.shortlist;
        }),
      };
    }

    case "UPDATE_NOTES": {
      return {
        ...state,
        notes: action.payload,
      };
    }

    default:
      return state;
  }
};

const login = ({ token, role, venues, user }) => ({
  type: "LOGIN",
  payload: {
    token,
    role,
    venues,
    user,
  },
});

const refreshUserDispatch = () => ({
  type: "REFRESH_USER"
});

const updateUser = ({ user }) => ({
  type: "UPDATE_USER",
  payload: {
    user,
  },
});

const updateUserVenues = ({ venues }) => ({
  type: "UPDATE_USER_VENUES",
  payload: {
    venues,
  },
});

const updateUserCompany = ({ company }) => ({
  type: "UPDATE_USER_COMPANY",
  payload: {
    company,
  },
});


const logout = () => ({
  type: "LOGOUT",
});

const addShortlist = data => ({
  type: "ADD_SHORTLIST_OR_SEARCH",
  payload: {
    type: "shortlists",
    data,
  },
});

const updateShortlist = (shortlistId, shortlist) => ({
  type: "UPDATE_SHORTLIST",
  payload: {
    shortlistId,
    shortlist,
  },
});

const addSearch = data => ({
  type: "ADD_SHORTLIST_OR_SEARCH",
  payload: {
    type: "savedSearches",
    data,
  },
});

const deleteShortlist = id => ({
  type: "DELETE_SHORTLIST_OR_SEARCH",
  payload: {
    type: "shortlists",
    id,
  },
});

const deleteSearch = id => ({
  type: "DELETE_SHORTLIST_OR_SEARCH",
  payload: {
    type: "savedSearches",
    id,
  },
});

const updateNotes = notes => ({
  type: "UPDATE_NOTES",
  payload: notes,
});

const user = state => state.auth.user;
const userAnalyticsGroup = state =>
  state.auth.user && state.auth.user.analytics_group
    ? state.auth.user.analytics_group.name
    : "undefined";
const userId = state => (state.auth.user ? state.auth.user.id : undefined);
const userName = state =>
  state.auth.user
    ? `${state.auth.user.first_name} ${state.auth.user.surname}`
    : undefined;
const userFirstName = state =>
    state.auth.user
        ? `${state.auth.user.first_name}`
        : undefined;
const isLoggedIn = state => !!state.auth.token;
const isAgent = state =>
  state.auth.user && state.auth.user.user_type
    ? state.auth.user.user_type.name === "Agency"
    : false;
const isSuperAgent = state =>
  !!state.auth && state.auth.agency && state.auth.agency.is_super_agent
const token = state => state.auth.token;
const role = state => state.auth.role;
const venues = state => state.auth.venues;
const company = state => state.auth.company;

// const isFromSuperAgency = state => state.auth && state.auth.agency && state.auth.agency.is_super_agent;

const permissions = state => state.auth.permissions;
const hasVenues = state =>
  !!!!state.auth && !!state.auth.venues && !!state.auth.venues.length;
const isAdmin = state =>
  !!state.auth.role && !!~state.auth.role.indexOf("super-admin");
const isEdgeAdmin = state =>
    !!state.auth.role && !!~state.auth.role.indexOf("edge-admin");
const isRolePlatformAdmin = state =>
    !!state.auth.role && (!!~state.auth.role.indexOf("edge-admin") || ~state.auth.role.indexOf("super-admin"));
const isAdminSupportOrFinance = state =>
    !!state.auth.role && (!!~state.auth.role.indexOf("edge-admin") || ~state.auth.role.indexOf("super-admin") || ~state.auth.role.indexOf("edge-support") || ~state.auth.role.indexOf("edge-finance"));
const isAgencyAdmin = state =>
  !!state.auth.role && !!~state.auth.role.indexOf("agency_administrator");
const isAgencyUser = state =>
    !!state.auth.role && !!~state.auth.role.indexOf("agency_user");
const isVenueAdmin = state =>
  Array.isArray(state.auth.venues) && !!state.auth.venues.length;
const isAgencyApproved = state => (state.auth && state.auth.agency) ?
  Boolean(state.auth.agency.is_approved) :
  (state.agency && Boolean(state.agency.is_approved));
const isInternal = state =>
  !!state.auth.role &&
  (!!~state.auth.role.indexOf("super-admin") ||
    !!~state.auth.role.indexOf("edge-support") ||
    !!~state.auth.role.indexOf("edge-finance") ||
    !!~state.auth.role.indexOf("edge-admin"));
const hasAgencyRoles = state =>
    !!state.auth.role &&
    (!!~state.auth.role.indexOf("agency_user") ||
        !!~state.auth.role.indexOf("agency_administrator"));
const isRoleTrinityEdgeRFP = state =>
    !!state.auth.role &&
    (!!~state.auth.role.indexOf("trinity-edge-rfp"));
// const isRoleTrinity = state =>
//     !!state.auth.role &&
//     (!!~state.auth.role.indexOf("trinity"));
// const paidVenues = state =>
//   Array.isArray(state.auth.venues)
//     ? state.auth.venues.filter(v => v.temp_permission === "paid")
//     : [];
// const premiumVenues = state =>
//   Array.isArray(state.auth.venues)
//     ? state.auth.venues.filter(v => v.temp_permission === "premium")
//     : [];
const shortlists = state => state.auth.shortlists || [];
const savedSearches = state => state.auth.savedSearches || [];
const userType = state =>
  (state.auth.user &&
    state.auth.user.user_type &&
    state.auth.user.user_type.name) ||
  "";
const userTown = state => (state.auth.user && state.auth.user.town) || "";
const userCompany = state => (state.auth.user && state.auth.user.company) || "";
const userCompanyObject = state => (state.auth.company && state.auth.company) || "";
const userAgency = state => (state.auth.user && state.auth.user.agency  && state.auth.user.agency.name) || "";
const userAgencyObject = state => (state.auth.user && state.auth.user.agency) || "";
const userIndustry = state =>
  (state.auth.user && state.auth.user.industry) || "";
const isAgency = state => userType(state) === "Agency";
const isIndividual = state => userType(state) === "Individual";
const isVenue = state => userType(state) === "Venue";
const isCorporate = state => userType(state) === "Corporate" && state.auth.user.has_corporate_access;
const isHotel = state => userType(state) === "Hotel";
const isAgencyOrHotel = state => isAgency(state) || isHotel(state);

const venueNotes = id => state =>
  state.auth.notes ? state.auth.notes.filter(note => note.venue_id === id) : [];
const notes = state => state.auth.notes || [];
const userEmail = state => (state.auth.user ? state.auth.user.email : null);

export {
  reducer,
  // action creeators
  login,
  logout,
  addShortlist,
  updateShortlist,
  addSearch,
  deleteSearch,
  deleteShortlist,
  updateNotes,
  updateUser,
  updateUserVenues,
  // selectors
  user,
  userId,
  userName,
  userEmail,
  isLoggedIn,
  token,
  role,
  isAdmin,
  isEdgeAdmin,
  isAgent,
  isSuperAgent,
  isAgencyAdmin,
  isInternal,
  isVenueAdmin,
  isRoleTrinityEdgeRFP,
  // isRoleTrinity,
  venues,
  permissions,
  // paidVenues,
  // premiumVenues,
  savedSearches,
  shortlists,
  hasVenues,
  userType,
  userTown,
  userCompany,
  userIndustry,
  isAgency,
  isVenue,
  isCorporate,
  isHotel,
  isAgencyOrHotel,
  notes,
  venueNotes,
  userAnalyticsGroup,
  userFirstName,
  updateUserCompany,
  company,
  hasAgencyRoles,
  isAgencyApproved,
  isIndividual,
  refreshUserDispatch,
  userAgency,
  isAgencyUser,
  isRolePlatformAdmin,
  userAgencyObject,
  userCompanyObject,
  isAdminSupportOrFinance
};
