import * as ROUTES from "../../Router/corporate_routes";
import axios from "axios";
import {
  INVOICE_PDFS_RECEIVED_ERROR,
  INVOICE_PDFS_RECEIVED_REQUEST,
  INVOICE_PDFS_REQUEST,
  UNLOAD_INVOICE_PDFS,
} from "../reducers/types";

export const invoicePdfsReceivedRequest = data => ({
  type: INVOICE_PDFS_RECEIVED_REQUEST,
  data,
});

export const invoicePdfsRequest = () => ({
  type: INVOICE_PDFS_REQUEST,
});

export const invoicePdfsReceivedError = error => ({
  type: INVOICE_PDFS_RECEIVED_ERROR,
  error,
});

export const unloadInvoicePdfs = () => ({
  type: UNLOAD_INVOICE_PDFS,
});


export const fetchInvoicePdfs = (eventId, token) => {
  // console.clear()
  // console.log('fetchInvoicePdfs:', eventId)
  return (dispatch) => {
    dispatch(invoicePdfsRequest());
    return axios.get(window.API + ROUTES.API.BOOKED_EVENTS.INVOICES.ALL.replace(":event", eventId), {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => dispatch(invoicePdfsReceivedRequest(response)))
      .catch(error => dispatch(invoicePdfsReceivedError(error)));
  };
};