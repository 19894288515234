import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { resizeEvent } from "redux-window";
import { init } from "modules/analytics";
import store from "./store";
import "./index.css";
import Router from "./Router";
import SentryErrorBoundary, {
  init as sentryInit,
} from "Components/SentryErrorBoundary";
import { hotjar } from 'react-hotjar'

/* POLYFILL Number.toFixed() to stop it rounding .5's down rather than up */
//(function(prototype) {
//  var toFixed = prototype.toFixed
//
//  prototype.toFixed = function(fractionDigits) {
//    var split = this.toString().split('.')
//    var number = +(!split[1] ? split[0] : split.join('.') + '1')
//
//    return toFixed.call(number, fractionDigits)
//  }
//}(Number.prototype));

(function(prototype) {
  var toFixed = prototype.toFixed;

  prototype.toFixed = function(fractionDigits) {
    var split = this.toString().split('.');

    var number = Number(!split[1]
        ? split[0]
        : (split[1].length >= fractionDigits
            ? split.join('.') + '1'
            : split.join('.')));

    return toFixed.call(number, fractionDigits);
  }
}(Number.prototype));


init();
sentryInit();

window.API = process.env.REACT_APP_API;
window.NODE_AS = process.env.REACT_APP_NODE_AS;
window.STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;

window.REACT_APP_WORDPRESS_URL = process.env.REACT_APP_WORDPRESS_URL;

window.HOTJAR_ID = process.env.REACT_APP_HOTJAR_ID;
window.HOTJAR_VERSION = process.env.REACT_APP_HOTJAR_VERSION;

window.TAWK_TO_PROPERTY = process.env.REACT_APP_TAWK_TO_PROPERTY;
window.TAWK_TO_KEY = process.env.REACT_APP_TAWK_TO_KEY;

window.EDGE_VERSION = process.env.REACT_APP_EDGE_VERSION;

if (!window.API) {
  console.error("Set REACT_APP_API in .env");
}
if (!window.NODE_AS) {
  console.error("Set REACT_APP_NODE_AS in .env");
}

if (!window.STRIPE_KEY) {
  console.error("Set REACT_APP_STRIPE_KEY in .env");
} else {
  window.stripeInstance = window.Stripe(window.STRIPE_KEY);
}

if (window?.EDGE_VERSION !== 'dev' && !window.TAWK_TO_PROPERTY) {
  console.error("Set TAWK_TO_PROPERTY in .env")
}

if (window?.EDGE_VERSION !== 'dev' && !window.TAWK_TO_KEY) {
  console.error("Set TAWK_TO_KEY in .env")
}

window.IMG_URL = process.env.REACT_APP_URL; // "https://edgevenues.com";
window.BASE_URL = process.env.REACT_APP_URL; // "https://edgevenues.com";
window.CDN_URL = 'https://cdn.edgevenues.com'; // "https://edgevenues.com";
if (!window.IMG_URL) {
  console.error("Set REACT_APP_IMG_URL in .env");
}

window.WORDPRESS_URL = process.env.REACT_APP_WORDPRESS_URL;
if (!window.WORDPRESS_URL) {
  console.error("Set REACT_APP_WORDPRESS_URL in .env")
}

if (window?.EDGE_VERSION !== 'dev' && !window.HOTJAR_ID && !window.HOTJAR_VERSION) {
  console.error("Set REACT_APP_HOTJAR_ID and REACT_APP_HOTJAR_VERSION in .env")
} else {
  hotjar.initialize(window.HOTJAR_ID, window.HOTJAR_VERSION);
}


resizeEvent(store);

ReactDOM.render(
  <SentryErrorBoundary>
    <Provider store={store}>
      <Router />
    </Provider>
  </SentryErrorBoundary>,
  document.getElementById("root")
);
