import {
  min,
  maxLength,
  optional,
  required,
  bool,
  isDate,
  notInPast,
  isNotBefore,
  afterToday,
  float,
} from "modules/validation";

const notBeforeDateFrom = isNotBefore("date_from");

const stage1 = {
  event_name: [required(), maxLength(256)],
  accommodation_only: bool,
  number_of_delegates: min(1),
  maximum_number_of_delegates: optional(min(1)),
  date_from: [required(), isDate, notInPast, afterToday],
  date_to: [required(), isDate, notInPast, notBeforeDateFrom],
  room_style_id: required(),
  date_flexible: bool,
  date_flexible_notes: [],
};

const briefPreferences = {
  location: optional(maxLength(255)),
  event_type: optional(maxLength(255)),
  budget: [optional(float, min(0), maxLength(9))],
}

const stage2 = {
  bedrooms_required: bool,
  bedroom_dates: [required()],
  bedroom_additional_notes: [],
  quantity: min(0),
};

const stage3 = {
  event_timings: required(),
  syndicate_room_notes: [],
  exhibition_space_notes: [],
  private_dining_notes: [],
  back_projection_notes: [],
  any_equipment_notes: [],
  date_decision_date: [required(), isDate],
  is_edge_enquiry: bool,
  is_trinity_enquiry: bool,
  suppress_notifications: bool
};

const additionalDataCapture = {
  client_name: optional(maxLength(255)),
  company_id: required(),
  client_id: required(),
  share_with_venues: bool,
  estimated_value: [required(), float, min(0)],
  estimated_commission: [required(), float, min(0)],
}

const corporateDataCapture = {
  booking_desk_id: optional(maxLength(255)),
  meeting_host_id: required(),
  branch_id: required(),
  department_id: required(),
  booking_desk_user_id: optional(maxLength(255))
}

const canSubmit = ({
  event_name,
  number_of_delegates,
  date_from,
  date_to,
  date_flexible,
}) => {
  if (!event_name) {
    return false;
  }

  if (!number_of_delegates || !/^[0-9]+$/.test(number_of_delegates)) {
    return false;
  }

  if (!date_from || !date_to || typeof date_flexible !== "number") {
    return false;
  }

  return true;
};

export { canSubmit, stage1, briefPreferences, stage2, stage3, additionalDataCapture, corporateDataCapture };
