import axios from "axios";
import * as ROUTES from "../../Router/corporate_routes";
import handleErrors from "./utils";
import {
  ANNUAL_REPORT_REQUEST,
  ANNUAL_REPORT_REQUEST_ERROR,
  ANNUAL_REPORT_REQUEST_RECEIVED,
  ANNUAL_REPORT_UNLOAD,
  CASH_FLOW_REPORT_REQUEST,
  CASH_FLOW_REPORT_REQUEST_ERROR,
  CASH_FLOW_REPORT_REQUEST_RECEIVED,
  CASH_FLOW_REPORT_UNLOAD,
  ENQUIRY_LOG_REPORT_ERROR,
  ENQUIRY_LOG_REPORT_REQUEST,
  ENQUIRY_LOG_REPORT_REQUEST_RECEIVED,
  ENQUIRY_LOG_REPORT_UNLOAD,
  EXPORT_ENQUIRY_LOG_REPORT_REQUEST_RECEIVED,
  MONTHLY_EVENT_VALUES_REPORT_REQUEST,
  MONTHLY_EVENT_VALUES_REPORT_REQUEST_ERROR,
  MONTHLY_EVENT_VALUES_REPORT_REQUEST_RECEIVED,
  MONTHLY_EVENT_VALUES_REPORT_UNLOAD,
  SNAPSHOT_REPORT_REQUEST,
  SNAPSHOT_REPORT_REQUEST_ERROR,
  SNAPSHOT_REPORT_REQUEST_RECEIVED,
  SNAPSHOT_REPORT_UNLOAD,
  SPEND_REPORT_REQUEST,
  SPEND_REPORT_REQUEST_ERROR,
  SPEND_REPORT_REQUEST_RECEIVED,
  SPEND_REPORT_UNLOAD,
} from "../reducers/types";
import { removeEmptySearchParams } from "../../utils";

const CancelToken = axios.CancelToken;
let cancelCashFlowReport = () => {
};
let cancelAnnualReport = () => {
};
let cancelSnapshotReport = () => {
};
let cancelMonthlyEventValuesReport = () => {
};

export const monthlyEventValuesRequest = () => ({
  type: MONTHLY_EVENT_VALUES_REPORT_REQUEST,
});

export const monthlyEventValuesRequestReceived = data => ({
  type: MONTHLY_EVENT_VALUES_REPORT_REQUEST_RECEIVED,
  data,
});

export const monthlyEventValuesRequestError = error => ({
  type: MONTHLY_EVENT_VALUES_REPORT_REQUEST_ERROR,
  error,
});

export const monthlyEventValuesUnload = () => ({
  type: MONTHLY_EVENT_VALUES_REPORT_UNLOAD,
});

export const enquiryLogReportRequest = () => ({
  type: ENQUIRY_LOG_REPORT_REQUEST,
});

export const enquiryLogReportRequestReceived = data => ({
  type: ENQUIRY_LOG_REPORT_REQUEST_RECEIVED,
  data,
});

export const exportEnquiryLogReportRequestReceived = data => ({
  type: EXPORT_ENQUIRY_LOG_REPORT_REQUEST_RECEIVED,
  data,
});

export const enquiryLogReportRequestError = error => ({
  type: ENQUIRY_LOG_REPORT_ERROR,
  error,
});

export const enquiryLogReportUnload = () => ({
  type: ENQUIRY_LOG_REPORT_UNLOAD,
});

export const snapshotReportRequest = () => ({
  type: SNAPSHOT_REPORT_REQUEST,
});

export const snapshotReportRequestReceived = data => ({
  type: SNAPSHOT_REPORT_REQUEST_RECEIVED,
  data,
});

export const snapshotReportRequestError = error => ({
  type: SNAPSHOT_REPORT_REQUEST_ERROR,
  error,
});

export const snapshotReportUnload = () => ({
  type: SNAPSHOT_REPORT_UNLOAD,
});

export const spendReportRequest = () => ({
  type: SPEND_REPORT_REQUEST,
});

export const spendReportRequestReceived = data => ({
  type: SPEND_REPORT_REQUEST_RECEIVED,
  data,
});

export const spendReportRequestError = error => ({
  type: SPEND_REPORT_REQUEST_ERROR,
  error,
});

export const spendReportUnload = () => ({
  type: SPEND_REPORT_UNLOAD,
});

export const cashFlowReportRequest = () => ({
  type: CASH_FLOW_REPORT_REQUEST,
});
export const cashFlowReportRequestReceived = data => ({
  type: CASH_FLOW_REPORT_REQUEST_RECEIVED,
  data,
});

export const cashFlowReportRequestError = error => ({
  type: CASH_FLOW_REPORT_REQUEST_ERROR,
  error,
});

export const unloadCashFlowReport = () => ({
  type: CASH_FLOW_REPORT_UNLOAD,
});

export const annualReportRequest = () => ({
  type: ANNUAL_REPORT_REQUEST,
});

export const annualReportRequestReceived = data => ({
  type: ANNUAL_REPORT_REQUEST_RECEIVED,
  data,
});

export const annualReportRequestError = error => ({
  type: ANNUAL_REPORT_REQUEST_ERROR,
  error,
});

export const unloadAnnualReport = () => ({
  type: ANNUAL_REPORT_UNLOAD,
});


export const fetchEnquiryLogReport = (token, page = 1, queryParams = {}) => {
  if (!queryParams.page) {
    queryParams.page = page;
  }
  queryParams = new URLSearchParams(queryParams);
  removeEmptySearchParams(queryParams);
  let queryString = queryParams.toString();
  return (dispatch) => {
    dispatch(enquiryLogReportRequest());
    return axios.get(window.API + ROUTES.API.CORPORATE.REPORTS.ENQUIRY_LOG + "?" + queryString, {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => dispatch(enquiryLogReportRequestReceived(response.data)))
      .catch(error => {
        dispatch(enquiryLogReportRequestError(error.response));
        // handleErrors(error)
      });
  };
};

export const exportEnquiryLogReport = (account, token, queryParams = {}) => {
  if (queryParams.page) {
    delete queryParams.page;
  }
  queryParams = new URLSearchParams(queryParams);
  removeEmptySearchParams(queryParams);
  let queryString = queryParams.toString();
  return (dispatch) => {
    dispatch(enquiryLogReportRequest());
    return axios.get(window.API + ROUTES.API.CORPORATE.REPORTS.ENQUIRY_LOG_EXPORT.replace(":slug", account) + "?" + queryString, {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => dispatch(exportEnquiryLogReportRequestReceived(response)))
      .catch(error => dispatch(enquiryLogReportRequestError(error.response)));
  };
};

export const fetchSnapshotReport = (token, queryParams = {}) => {
  queryParams = new URLSearchParams(queryParams);
  removeEmptySearchParams(queryParams);
  let queryString = queryParams.toString();
  cancelSnapshotReport();
  return (dispatch) => {
    dispatch(snapshotReportRequest());
    return axios.get(window.API + ROUTES.API.CORPORATE.REPORTS.SNAPSHOT + "?" + queryString, {
      headers: { Authorization: `Bearer ${token}` },
      cancelToken: new CancelToken(function executor(c) {
        cancelSnapshotReport = c;
      }),
    }).then(response => dispatch(snapshotReportRequestReceived(response.data)))
      .catch(error => {
        if (!axios.isCancel(error)) {
          dispatch(snapshotReportRequestError(error));
          // handleErrors(error)
        }
      });
  };
};

export const fetchSpendReport = (token, queryParams = {}) => {
  queryParams = new URLSearchParams(queryParams);
  removeEmptySearchParams(queryParams);
  let queryString = queryParams.toString();
  return (dispatch) => {
    dispatch(spendReportRequest());
    return axios.get(window.API + ROUTES.API.CORPORATE.REPORTS.SPEND_REPORT + "?" + queryString, {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => dispatch(spendReportRequestReceived(response.data)))
      .catch(error => dispatch(spendReportRequestError(error.response)));
  };
};

export const fetchAnnualReport = (token, queryParams = {}) => {
  queryParams = new URLSearchParams(queryParams);
  removeEmptySearchParams(queryParams);
  let queryString = queryParams.toString();
  cancelAnnualReport();
  return (dispatch) => {
    dispatch(annualReportRequest());
    return axios.get(window.API + ROUTES.API.CORPORATE.REPORTS.ANNUAL_REPORT + "?" + queryString, {
      headers: { Authorization: `Bearer ${token}` },
      cancelToken: new CancelToken(function executor(c) {
        cancelAnnualReport = c;
      }),
    }).then(response => dispatch(annualReportRequestReceived(response.data)))
      .catch(error => dispatch(annualReportRequestError(error.response)));
  };
};

export const fetchMonthlyEventValuesReport = (account, token, queryParams = {}) => {
  queryParams = new URLSearchParams(queryParams);
  removeEmptySearchParams(queryParams);
  let queryString = queryParams.toString();
  cancelMonthlyEventValuesReport();
  return (dispatch) => {
    dispatch(monthlyEventValuesRequest());
    return axios.get(window.API + ROUTES.API.CORPORATE.REPORTS.MONTHLY_EVENT_VALUES_REPORT.replace(":slug", account) + "?" + queryString, {
      headers: { Authorization: `Bearer ${token}` },
      cancelToken: new CancelToken(function executor(c) {
        cancelMonthlyEventValuesReport = c;
      }),
    }).then(response => dispatch(monthlyEventValuesRequestReceived(response.data)))
      .catch(error => {
        if (!axios.isCancel(error)) {
          dispatch(monthlyEventValuesRequestError(error));
          handleErrors(error);
        }
      });
  };
};

export const fetchCashFlowReport = (token, queryParams = {}) => {
  queryParams = new URLSearchParams(queryParams);
  removeEmptySearchParams(queryParams);
  let queryString = queryParams.toString();
  cancelCashFlowReport();
  return (dispatch) => {
    dispatch(cashFlowReportRequest());
    return axios.get(window.API + ROUTES.API.AGENCY.REPORTS.CASH_FLOW_REPORT + "?" + queryString, {
      headers: { Authorization: `Bearer ${token}` },
      cancelToken: new CancelToken(function executor(c) {
        cancelCashFlowReport = c;
      }),
    }).then(response => dispatch(cashFlowReportRequestReceived(response.data)))
      .catch(error => {
        if (!axios.isCancel(error)) {
          dispatch(cashFlowReportRequestError(error));
          handleErrors(error);
        }
      });
  };
};