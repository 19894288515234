import React from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";
import { venues } from "modules/auth";
import Checkbox from "./Checkbox";
import { bool, arrayOf, number, string, shape } from "prop-types";
import { venueHasPermission } from "../../modules/permissions";

const validateVenueRequired = venues => {
  if (!Array.isArray(venues) || venues.length === 0) {
    return "At least one venue is required";
  }
};

const VenuesDisplay = ({ venues, isRequired }) => (
  <Field
    name="venues"
    label="Venues"
    component={Checkbox}
    options={venues.map(item => ({
      text: item.name,
      value: item.id,
    }))}
    validate={isRequired ? validateVenueRequired : undefined}
  />
);

VenuesDisplay.propTypes = {
  venues: arrayOf(
    shape({
      id: number.isRequired,
      name: string.isRequired,
    }),
  ).isRequired,
  isRequired: bool,
};

const VenuesFromState = connect((state, { permissionToCheck = null }) => {
  if (permissionToCheck === null) {
    return { venues: venues(state) };
  }
  return { venues: venues(state).filter(v => venueHasPermission(v, permissionToCheck)) };
})(VenuesDisplay);

// If venueOptions prop is provided then use that as the venues list
// otherwise get the venues list from the user's state
const Venues = ({
                  venueOptions = undefined,
                  isRequired = undefined,
                  ...rest
                }) => venueOptions ? (
  <VenuesDisplay venues={venueOptions} isRequired={isRequired} {...rest} />
) : (
  <VenuesFromState isRequired={isRequired} {...rest} />
);

export default Venues;
