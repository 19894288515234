import React, { useEffect, useState } from "react";
import AddVenueEnquiry from "../../Components/Rfp/AddVenueEnquiry";
import { Link, useHistory, useLocation } from "react-router-dom";
import VenueCard from "../../Components/Rfp/VenueCard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import Warning from "../../Components/Warning";
import { getWidth } from "redux-window";
import { connect, useSelector } from "react-redux";
import { Field, getFormValues, reduxForm } from "redux-form";
import { Toggle } from "../../Components/Form";
import { isCompanyUser, isSuperAgent } from "../../modules/permissions";
import { isAgency, isIndividual } from "../../modules/auth";
import { ENQUIRY_STATUS, ROCHE_THIRD_PARTY_TEXT } from "../../constants";
import { ButtonSpan } from "../../Components/Button";
import classnames from "classnames";

const Enquiry = ({
                   venues = [],
                   windowSize,
                   offerRfpXpress,
                   form,
                   isCompanyUser,
                   isIndividual,
                   enquiry,
                   addVenueToDraftEnquiry,
                   removeVenueFromEnquiry,
                   isExternalAgency,
                   setSubmitToAgent,
                   isAgency,
                   setFormDisabled,
                   formDisabled,
                   showFormToggle,
                 }) => {
  const { xs, sm, md } = windowSize;
  const values = useSelector(getFormValues(form));
  const [warningText, setWarningText] = useState("");
  const [chosenVenues, setChosenVenues] = useState([]);
  const [maxVenues, setMaxVenues] = useState(5);
  const [suggestVenues, setSuggestVenues] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (!isExternalAgency && venues && venues.length === 0) {
      setSuggestVenues(true);
      setSubmitToAgent(true);
    }
  }, []);

  useEffect(() => {
    if (!suggestVenues && chosenVenues.length > 0) {
      chosenVenues.map(venue => {
        addVenueToEnquiry(venue.id);
      });
    } else if (suggestVenues) {
      history.replace({
        search: "",
      });
    }
  }, [suggestVenues]);

  useEffect(() => {
    if (venues.length > 0) {
      setChosenVenues(venues);
      setSuggestVenues(false);
    } else if (!isExternalAgency) {
      setSuggestVenues(true);
    }
  }, [venues]);

  useEffect(() => {
    if (!isAgency) {
      if (maxVenues === 2 && venues.length >= 3) {
        setWarningText("You can have a maximum of 2 venues when submitting an RFP");
      } else if (venues.length >= 6) {
        setWarningText("You can have a maximum of 5 venues when submitting an RFP");
      } else {
        setWarningText("");
      }
    }
  }, [maxVenues, venues]);

  useEffect(() => {
    if (!isAgency) {
      if (Boolean(offerRfpXpress) && values && Boolean(values.rfp_xpress_service)) {
        setMaxVenues(2);
      } else {
        setMaxVenues(5);
      }
    }
  }, [offerRfpXpress, values]);

  const removeVenue = venueId => {
    if (enquiry) {
      removeVenueFromEnquiry(venueId);
    } else if (queryParams.has("venues") && queryParams.toString().includes(venueId)) {
      let venuesArray = queryParams.get("venues").split(",");
      let filteredVenues = venuesArray.filter(id => {
        return Number(id) !== Number(venueId);
      });
      queryParams.set("venues", filteredVenues.join());
      history.replace({
        search: queryParams.toString(),
      });
    }
  };

  const addVenueToEnquiry = venueId => {
    setSubmitToAgent(false);
    if (enquiry) {
      addVenueToDraftEnquiry(venueId);
    }
    let venuesArray = [];
    if (queryParams.has("venues")) {
      venuesArray = queryParams.get("venues").split(",");
      venuesArray.unshift(venueId);
      let filteredArray = [...new Set(venuesArray)].filter(v => v !== "");
      if (filteredArray.length === 1) {
        queryParams.set("venues", filteredArray[0]);
      } else {
        queryParams.set("venues", filteredArray.join());
      }
    } else {
      venuesArray.unshift(venueId);
      queryParams.set("venues", venuesArray[0]);
    }
    history.replace({
      search: queryParams.toString(),
    });
  };

  const slides = venues && venues.sort((a, b) => {
    let sortOrder = queryParams.has("venues") ? queryParams.get("venues").split(",") : [];
    return sortOrder.indexOf(String(a.id)) - sortOrder.indexOf(String(b.id));
  }).map(venue => {
    return (
      <SwiperSlide key={venue.id}>
        <VenueCard venue={venue} removeVenue={removeVenue}
                   canRemove={!enquiry || (enquiry && enquiry.enquiry_status.code === ENQUIRY_STATUS.DRAFT)} />
      </SwiperSlide>
    );
  });

  const toggleForm = () => {
    if (!showFormToggle) {
      return null;
    } else {
      return <div className="mb-5">
        <div className="px-3 py-6 my-5 block font-bold bg-lighter-blue">
          {ROCHE_THIRD_PARTY_TEXT}
          <div className="pt-4">
            <p>I confirm this booking is not for a Third Party Meeting</p>
            <div
              className="flex text-xs"
              style={{
                lineHeight: "26px",
                height: "28px",
              }}
            >
              <ButtonSpan
                className={classnames(
                  "inline-block flex items-center px-3 border border-grey-md border-r-0",
                  {
                    "opacity-50": formDisabled,
                    "bg-brand": !formDisabled,
                  },
                )}
                onClick={() => setFormDisabled(false)}
              >
                YES
              </ButtonSpan>
              <ButtonSpan
                className={classnames(
                  "inline-block flex items-center px-3 border border-grey-md",
                  {
                    "opacity-50": !formDisabled,
                    "bg-brand": formDisabled,
                  },
                )}
                onClick={() => setFormDisabled(true)}
              >
                NO
              </ButtonSpan>
            </div>
          </div>
        </div>
      </div>;
    }
  };

  return (
    <div className="mb-4">
      {toggleForm()}
      <div className="flex">
        <div
          onClick={() => setSuggestVenues(false)}
          className={` item pt-3 pb-2 px-2 w-1/2 cursor-pointer rounded-t-lg ${!suggestVenues ? "bg-off-white hover:text-black" : "hover:text-brand"}`}
        >
          <p className="font-bold text-center mb-0 text-sm uppercase">
            I have chosen venues for my event
          </p>
        </div>
        {!isExternalAgency ? (
          <div
            onClick={() => setSuggestVenues(true)}
            className={`item pt-3 pb-2 px-2 w-1/2 cursor-pointer rounded-t-lg ${suggestVenues ? "bg-off-white hover:text-black" : " hover:text-brand"}`}
          >
            <p className="font-bold text-center mb-0 text-sm uppercase">
              I want an agent to suggest suitable venues
            </p>
          </div>
        ) : null}
      </div>
      {!suggestVenues ? (
        <div className="py-6 px-3 bg-off-white">
          <div className="row mb-0">
            {!enquiry || (enquiry && enquiry.enquiry_status.code === ENQUIRY_STATUS.DRAFT) && (!isIndividual && !isCompanyUser) ? (
              <div className={"col lg:col-w-1/2"}>
                <AddVenueEnquiry addVenue={addVenueToEnquiry} disableAdd={warningText !== ""} />
              </div>
            ) : null}
            {offerRfpXpress && isAgency ? (
              <div className={"col lg:col-w-1/2"}>
                <Field
                  name="rfp_xpress_service"
                  label="Produce Budget Confirmation (Xpress)"
                  wrapperClassName="w-full mb-8 mr-2"
                  component={Toggle}
                  defaultValue={enquiry?.rfp_xpress_service}
                />
              </div>
            ) : null}
            {venues.length === 0 ? (
              <Warning className="my-4 w-full mx-3">
                <p className="text-md text-white mb-0">
                  <i className="fas text-2lg fa-exclamation-triangle mr-3" />
                  You need to select venues to request a proposal. You can use an{" "}
                  <Link to="/admin/my-edge/shortlists" className={"text-white font-bold hover:underline"}>existing
                    shortlist</Link> or{" "}
                  <Link to="/advanced-search" className={"text-white font-bold hover:underline"}>create one here</Link>
                </p>
              </Warning>
            ) : null}
            {offerRfpXpress ? (
              <div className="ml-3 mr-3 mt-0 mb-3 p-3 bg-lighter-blue w-full"><p className={"mb-0"}><i
                className="fas text-2lg fa-info-circle mr-3" />Please note: Xpress Enquiry service can only include 2
                venues
                (8 hour SLA, Mon-Fri 9-5pm)</p></div>
            ) : null}
          </div>
          {maxVenues && warningText !== "" ? (
            <Warning className="mb-4">
              <p className="text-md text-white mb-0">
                <i className="fas text-2lg fa-exclamation-triangle mr-3" />
                {warningText}</p>
            </Warning>
          ) : null}
          <div className="row">
            <div className="col mt-2">
              <Swiper
                spaceBetween={20}
                slidesPerView={(xs || sm) ? 1.25 : md ? 2.25 : 2.75}
                centerInsufficientSlides={false}
              >
                {slides}
              </Swiper>
            </div>
          </div>
        </div>
      ) : (
        <div className="p-6 bg-off-white">
          {offerRfpXpress && isAgency ? (
            <div className={"col lg:col-w-1/2"}>
              <Field
                name="rfp_xpress_service"
                label="Produce Budget Confirmation (Xpress)"
                wrapperClassName="w-full mb-8 mr-2"
                component={Toggle}
                defaultValue={enquiry?.rfp_xpress_service}
              />
            </div>
          ) : null}
          <div className="p-3 bg-lighter-blue w-full">
            <p className={"mb-0"}><i className="fas text-2lg fa-info-circle mr-3" />
              Once you've filled in the brief below and submitted it to an agent, your agency contact will review the
              brief and suggest up to 4
              suitable venues for you to approve to receive the brief.</p>
          </div>
          {offerRfpXpress ? (
            <div className="mt-2 p-3 bg-lighter-blue w-full"><p className={"mb-0"}><i
              className="fas text-2lg fa-info-circle mr-3" />Please note: Xpress Enquiry service will only include 1
              suggested
              venue (8 hour SLA, Mon-Fri 9-5pm)</p></div>
          ) : null}
        </div>
      )}
    </div>
  );
};


const mapStateToProps = state => {
  const width = getWidth(state);
  return {
    windowSize: {
      xs: width < 576,
      sm: width >= 576 && width < 768,
      md: width >= 768 && width < 1200,
    },
    isCompanyUser: isCompanyUser(state),
    isExternalAgency: isAgency(state) && !isSuperAgent(state.auth),
    isIndividual: isIndividual(state),
    isAgency: isAgency(state),
  };
};

export default reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(connect(mapStateToProps, null)(Enquiry));
