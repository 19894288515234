import React from "react";
import { arrayOf, string } from "prop-types";
import { connect } from "react-redux";
import { venueInteraction } from "modules/analytics";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { getWidth } from "redux-window";
import BackgroundImage from "../../Components/General/BackgroundImage";
import { isLoggedIn } from "../../modules/auth";
import RegisterBlurPanel from "../../Components/RegisterBlurPanel";

class MobileGallery extends React.Component {
  static propTypes = {
    images: arrayOf(string.isRequired),
  };

  getImages = () => {
    const { isOptedOut } = this.props;
    let arr = [...this.props.images];
    let images = arr;
    if (!this.props.isLoggedIn || isOptedOut) {
      images = arr.slice(0, 3);
    }
    return images.map((img, index) => {
      return {
        original: img,
        thumbnail: img,
        renderItem: () => (
          !this.props.isLoggedIn && index === 2 ? (
            <RegisterBlurPanel showContent={true} contentText={null} buttonText={"Login to view more"}>
              <BackgroundImage
                className="re-16/9 relative max-w-full bg-cover bg-center"
                imagePath={img}
                edits={{
                  "resize": {
                    "width": 973,
                    "height": 547,
                    "fit": "cover",
                  },
                }}
              />
            </RegisterBlurPanel>
          ) : (
            <BackgroundImage
              className="re-16/9 relative max-w-full bg-cover bg-center"
              imagePath={img}
              edits={{
                "resize": {
                  "width": 973,
                  "height": 547,
                  "fit": "cover",
                },
              }}
            />
          )
        ),
      };
    });
  };

  trackSlide = () => {
    this.props.venueInteraction({
      type: "view_gallery_image",
      id: this.props.venueId,
      impression_id: this.props.impression_id,
      data_score: this.props.data_score,
    });
  };

  render() {
    return (
      <div className="re-16/9 relative max-w-full overflow-hidden">
        {/*{covid? <span className={'ribbon-single z-10'}>Open</span> : null}*/}
        <div className="absolute pin-t pin-b pin-r pin-l">

          <ImageGallery
            showFullscreenButton={false}
            showPlayButton={false}
            items={this.getImages()}
            onSlide={this.trackSlide}
            lazyLoad
            showThumbnails={false}
          />
        </div>
        <h1
          className={`px-6 text-white text-shadow flex items-end pb-3 absolute pin-b z-10 text-3xl`}
        >
          {this.props.name}
        </h1>
      </div>
    );
  }
}

export default connect(
  state => ({
    isLoggedIn: isLoggedIn(state),
    xs: getWidth(state) < 576,
  }),
  { venueInteraction },
)(MobileGallery);
