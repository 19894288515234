import {LICENCE_TYPE_CODES, UK_COUNTRY_IDS} from "../constants";


export function getChoiceTitle(licenceTypeCode) {
    switch (licenceTypeCode) {
        case LICENCE_TYPE_CODES.BASIC_FEE_CODE:
            return 'Basic Fee';
        case LICENCE_TYPE_CODES.ENHANCED_FEE_CODE:
            return 'Upgrade to Enhanced Fee';
        case LICENCE_TYPE_CODES.OPT_OUT_LICENCE_CODE:
            return 'Opt Out';
        case LICENCE_TYPE_CODES.INTERNATIONAL_FEE_CODE:
            return 'International Fee';
        default:
            return ''
    }
}


export function isUKCountry(country_id) {
    return UK_COUNTRY_IDS.includes(country_id);
}