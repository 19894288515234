import React, {useEffect, useState} from "react";
import axios from "axios";
import {ButtonBrand, ButtonLink} from "../Components/Button";
import {Link} from 'react-router-dom';
import {LICENCE_TYPE_CODES} from "../constants";
import Loading from "../Components/Loading";
import UpgradeDiscountBanner from "../Components/venue/UpgradeDiscountBanner";
import {isUKCountry} from "../helpers/licencing";
import {LicenceAgreementModal} from "../Components/venue/LicenceAgreementModal";

function getSuccessMessage(licenceTypeCode) {
    switch (licenceTypeCode) {
        case LICENCE_TYPE_CODES.BASIC_FEE_CODE:
            return <>
                <h2>Well done! Your choice has been made.</h2>
                <p>Make EDGE Work for Your Venue</p>
                <p>What You’ll Receive</p>
                <ul className={'mb-2'}>
                    <li>Free to register your Venue</li>
                    <li>Unlimited Photos to showcase your venue to the event planner.</li>
                    <li>Analytics to track engagement and refine your strategy.</li>
                    <li>Exposure to EDGE Agents&apos; Closed User Group and Corporates for direct planner connections.</li>
                    <li>Virtual Tour Option: Add a virtual tour to enhance your profile.</li>
                    <li>RFP Support to make responding easy and efficient.</li>
                    <li>3rd Priority Placement in relevant search results.</li>
                </ul>
                <p>Next Steps for Success:</p>

                <ol className={'mb-2'}>
                    <li><strong>Log In to Your Dashboard:</strong> Check your analytics to monitor planner interactions.</li>
                    <li><strong>Optimise Your Profile:</strong> Use visuals and detailed descriptions to highlight your venue&apos;s unique appeal.</li>
                    <li><strong>Engage with RFPs:</strong> Respond quickly to secure bookings.</li>
                </ol>
                <p>If you have questions or need assistance, take a look at our Knowledge Hub or chat to our team who are here to support you every step of the way.</p>
                <p><strong>Thank you for being part of the EDGE Venues Community</strong></p>
            </>;
        case LICENCE_TYPE_CODES.ENHANCED_FEE_CODE:
            return <>
                <h2>Well done! Your choice has been made.</h2>
                <p>Make EDGE Work for Your Venue</p>
                <p>What You’ll Receive</p>
                <ul className={'mb-2'}>
                    <li>Free to register your venue</li>
                    <li>Unlimited Photos to showcase your venue to the event planner.</li>
                    <li>Analytics to track engagement and refine your strategy.</li>
                    <li>Exposure to EDGE Agents&apos; Closed User Group and Corporates for direct planner connections.</li>
                    <li>Virtual Tour Option: Add a virtual tour to enhance your profile.</li>
                    <li>RFP Support to make responding easy and efficient.</li>
                    <li>Unlimited Marketing - for your special offers, blogs, and press releases</li>
                    <li>2nd Priority Placement in relevant search results.</li>
                </ul>
                <p>Next Steps for Success:</p>

                <ol className={'mb-2'}>
                    <li><strong>Log In to Your Dashboard:</strong> Check your analytics to monitor planner interactions.</li>
                    <li><strong>Optimise Your Profile:</strong> Use visuals and detailed descriptions to highlight your venue&apos;s unique appeal.</li>
                    <li><strong>Engage with RFPs:</strong> Respond quickly to secure bookings.</li>
                </ol>
                <p>If you have questions or need assistance, take a look at our Knowledge Hub or chat to our team who are here to support you every step of the way.</p>
                <p><strong>Thank you for being part of the EDGE Venues Community</strong></p>
            </>;
        case LICENCE_TYPE_CODES.OPT_OUT_LICENCE_CODE:
            return <>
                <h2>Well done! Your choice has been made.</h2>
                <p>Make EDGE Work for Your Venue</p>
                <p>What You’ll Receive</p>
                <ul className={'mb-2'}>
                    <li>Free to register</li>
                    <li>Simple and effective RFP tool</li>
                    <li>4 B&amp;W Images</li>
                </ul>
                <p>Next Steps for Success:</p>

                <ol className={'mb-2'}>
                    <li><strong>Log In to Your Dashboard.</strong></li>
                    <li><strong>Optimise Your Profile:</strong> Use visuals and detailed descriptions to highlight your
                        venue&apos;s unique appeal.
                    </li>
                    <li><strong>Engage with RFPs:</strong> Respond quickly to secure bookings.</li>
                    <li><strong>Want more?</strong> Then take a look at our enhanced options.</li>
                </ol>
                <p>If you have questions or need assistance, take a look at our Knowledge Hub or chat to our team who are here to support you every step of the way.</p>
                <p><strong>Thank you for being part of the EDGE Venues Community</strong></p>

            </>;
        case LICENCE_TYPE_CODES.INTERNATIONAL_FEE_CODE:
            return <>
                <h2>Well done! Your choice has been made.</h2>
                <p>Make EDGE Work for Your Venue</p>
                <p>What You’ll Receive</p>
                <ul className={'mb-2'}>
                    <li>Free to register your venue</li>
                    <li>Unlimited Photos to showcase your venue to the event planner.</li>
                    <li>Analytics to track engagement and refine your strategy.</li>
                    <li>Exposure to EDGE Agents&apos; Closed User Group for direct planner connections.</li>
                    <li>Virtual Tour Option: Add a virtual tour to enhance your profile.</li>
                    <li>RFP Support to make responding easy and efficient.</li>
                    <li>Unlimited Marketing - for your special offers, blogs, and press releases</li>
                    <li>3rd Priority Placement in relevant search results.</li>
                    <li>Link to destination searches</li>
                    <li>Destination Stats</li>
                    <li>Feature on destination page</li>
                </ul>
                <p>Next Steps for Success:</p>

                <ol className={'mb-2'}>
                    <li><strong>Log In to Your Dashboard:</strong> Check your analytics to monitor planner interactions.</li>
                    <li><strong>Optimise Your Profile:</strong> Use visuals and detailed descriptions to highlight your venue&apos;s
                        unique appeal.
                    </li>
                    <li><strong>Engage with RFPs:</strong> Respond quickly to secure bookings.</li>
                </ol>
                <p>If you have questions or need assistance, take a look at our Knowledge Hub or chat to our team who
                    are here to support you every step of the way.</p>
                <p><strong>Thank you for being part of the EDGE Venues Community</strong></p>
            </>;
        case LICENCE_TYPE_CODES.PAID_LICENCE_CODE:
            return <>
                <h2>Well done! Your payment and subscription was successful.</h2>
                <p>Thank you for choosing to partner with EDGE Venues! Your licence unlocks premium tools designed to help your venue stand out and secure more bookings.</p>
                <p>Here’s What You’ve Unlocked:</p>
                <ul className={'mb-2'}>
                    <li><strong>No platform fees</strong></li>
                    <li><strong>Priority Placement:</strong> Be the first venue planners see in searches.</li>
                    <li><strong>Enhanced Analytics:</strong> Gain insights into planner engagement and optimise your strategy.</li>
                    <li><strong>Unlimited Photos & Marketing Tools:</strong> Showcase your venue like never before.</li>
                </ul>
                <p>Next Steps for Success:</p>

                <ol className={'mb-2'}>
                    <li><strong>Log In to Your Dashboard:</strong> Check your analytics to monitor planner interactions.
                    </li>
                    <li><strong>Optimise Your Profile:</strong> Use visuals and detailed descriptions to highlight your
                        venue&apos;s
                        unique appeal.
                    </li>
                    <li><strong>Engage with RFPs:</strong> Respond quickly to secure bookings.</li>
                    <li><strong>Onboarding:</strong> A member of the onboarding team will be in touch to take you through the platform.</li>
                </ol>
                <p>If you have questions or need assistance, take a look at our Knowledge Hub or chat to our team who
                    are here to support you every step of the way.</p>
                <p><strong>Thank you for being part of the EDGE Venues Community</strong></p>
            </>;
        default:
            return ''
    }
}

const ManageVenueLicence = ({match, img = "/img/landing/conference-banner.jpg"}) => {
    const [loading, setLoading] = useState(false);
    const [addingLicence, setAddingLicence] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedLicenceType, setSelectedLicenceType] = useState(null);
    const [licenceAdded, setLicenceAdded] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [venueData, setVenueData] = useState(false);
    const [licenceTypes, setLicenceTypes] = useState([]);
    useEffect(() => {
        setLoading(true);
        // replace with axios request
        axios.get(`${window.API}/venues/bh/${match.params.venuePublicHash}`).then(response => {
            if (response && response.status === 200 && response.data) {
                setVenueData(response.data);
            }
        }).catch(() => {
            setLoading(false);
            setErrorMessage("We're sorry that link hasn't worked - if you have any questions, please contact EDGE on 01780 484051 or email info@edgevenues.com quoting ERR246.");
        });

        axios.get(`${window.API}/licence-type`).then(response => {
            if (response && response.status === 200 && response?.data?.data) {
                setLicenceTypes(response.data.data);
            }
        }).catch(() => {
            setLoading(false);
        });

    }, []);
    useEffect(() => {
        if (venueData !== false && licenceTypes.length > 0) {
            setLoading(false);
        }
    }, [licenceTypes, venueData]);


    if (venueData && !venueData?.country_id && errorMessage === '' && !loading) {
        setErrorMessage("There is a problem with your venue country - please contact EDGE to resolve before proceeding.");
    }

    const selectOption = (licenceTypeCode) => {
        const licenceType = licenceTypes.find(type => type.code === licenceTypeCode);
        setShowModal(true);
        setSelectedLicenceType(licenceType);
    };
    const onProceed = () => {
        setAddingLicence(true);
        setShowModal(false);
        axios.post(`${window.API}/venues/bh/${match.params.venuePublicHash}/${selectedLicenceType.code}`).then(response => {
            setAddingLicence(false);
            if (response && response.status === 200 && response.data) {
                setVenueData(response.data);
                setLicenceAdded(true);
            } else {
                setErrorMessage(response.message);
            }
        }).catch(response => {
            setAddingLicence(false);
            if (response?.response?.status === 403) {
                setErrorMessage("Your venue already has a licence or platform fee agreement, so you don't need to do anything further. If you have any questions, please contact EDGE on 01780 484051 or email info@edgevenues.com");
            } else {
                setErrorMessage(response.message);
            }

        });

    }
    const title = 'Choose your options';
    const subtitle = 'You are managing the venue ';
    const heroBody = 'Scale up your business with EDGE Venues Today';
    const licenceOptions = [{
        licenceTypeCode: LICENCE_TYPE_CODES.BASIC_FEE_CODE, icon: "handshake", // title: getChoiceTitle(LICENCE_TYPE_CODES.BASIC_FEE_CODE),
        content: (<div>
            <ul>
                <li>Free venue promotion (fee only on results)</li>
                <li>Unlimited Colour Pics – to show off your venue</li>
                <li>Analytics – to report on performance</li>
                <li>3rd Priority in search</li>
                <li>Agents closed user group – to build relationships</li>
                <li>Virtual tour option – to enhance your listing</li>
                <li>RFP Support – when you need it</li>
            </ul>
        </div>)

    }, {
        licenceTypeCode: LICENCE_TYPE_CODES.INTERNATIONAL_FEE_CODE, // title: getChoiceTitle(LICENCE_TYPE_CODES.INTERNATIONAL_FEE_CODE),
        icon: "globe", content: (<div>
            <ul>
                <li>Free venue promotion (fee only on results)</li>
                <li>Unlimited Colour Pics – to show off your venue</li>
                <li>Analytics – to report on performance</li>
                <li>3rd Priority in search</li>
                <li>Agents closed user group – to build relationships</li>
                <li>Virtual tour option -to enhance your listing</li>
                <li>RFP Support – when you need it</li>
                <li>Link to destination searches</li>
                <li>Destination Stats</li>
                <li>Feature on destination page</li>
            </ul>
        </div>)

    }, {
        licenceTypeCode: LICENCE_TYPE_CODES.ENHANCED_FEE_CODE, // title: getChoiceTitle(LICENCE_TYPE_CODES.ENHANCED_FEE_CODE),
        icon: "gem", content: (<div>
            <ul>
                <li>Free enhance venue promotion (fee only on results)</li>
                <li>Unlimited Colour Pics – to show off your venue</li>
                <li>Analytics – to report on performance</li>
                <li>2nd Priority in search</li>
                <li>Agents closed user group and corporate buyers – to build relationships</li>
                <li>Virtual tour option – to enhance your listing</li>
                <li>Unlimited Marketing – for your special offers, blogs, and press releases</li>
                <li>RFP Support – when you need it</li>
            </ul>
        </div>)

    }, {
        licenceTypeCode: LICENCE_TYPE_CODES.PAID_LICENCE_CODE, // title: "Upgrade to a licence",
        icon: "credit-card", content: (<div>
            <ul>
                <li>No platform fee</li>
                <li>Priority Venue Promotion – best match best place</li>
                <li>Fast and Effective RFP and support</li>
                <li>Unlimited opportunities with news blogs and free entry to EDGE Events</li>
                <li>Unlimited colour images/virtual tour link.</li>
                <li>Promote your Special offers</li>
                <li>Access to engage with our Agents closed user group and corporate buyers</li>
                <li>Enhanced analytics and reporting</li>
                <li>Dedicated account manager</li>
                <li>Business Insights Report</li>
            </ul>
        </div>)
    }];

    const currentLicenceTypeCode = venueData?.active_licence_type_code && venueData.active_licence_type_code;
    const validLicenceTypes = licenceTypes.filter(licenceType => !licenceType.hide_to_public && (!venueData?.country_id || ((licenceType.valid_for_uk && isUKCountry(venueData.country_id)) || (licenceType.valid_for_international && !isUKCountry(venueData.country_id)))));

    return (<div className="container">
        <div
            className="p-3 md:p-8 text-white md:flex"
            style={{
                backgroundImage: `url(${img})`,
            }}
        >
            <div className="mx-auto content-center text-center">
                <div className="p-4 md:p-0">
                    <h1 className="my-5">{venueData ? title : (loading ? 'Loading your venue' : '')}</h1>
                    {venueData && subtitle && <h2 className="mb-10">{venueData ? subtitle + ' ' + venueData.name : ''}</h2>}
                    {venueData && heroBody && <p className="font-bold text-xl">{heroBody}</p>}
                </div>
            </div>
        </div>
        {licenceAdded ? <div
            className="pt-4"
        >
            <div className="mx-auto content-center text-center">
                <div className="w-full bg-brand p-4 text-white">

                    <p className={'mb-0 font-bold'}><i className="fas text-2lg fa-check mr-3 "/>
                        We've recorded your platform fee preferences. Thank you for updating your venue profile.
                    </p>
                </div>
            </div>
            <div className={'py-8'}>{getSuccessMessage(selectedLicenceType.code)}</div>
        </div> : null}
        {errorMessage !== "" ? <div
            className="pt-4"
        >
            <div className="mx-auto content-center text-center">
                <div className="w-full bg-blue-light p-4 text-black">

                    <p className={'mb-0 font-bold'}><i className="fas text-2lg fa-exclamation-triangle mr-3"/>
                        {errorMessage}
                    </p>

                </div>
            </div>
        </div> : null}

        {errorMessage === "" && addingLicence ? <div className={' text-center my-4'}>
            <h3 className={'text-brand'}>Activating Licence</h3>
            <Loading classes={'my-4'}/>
        </div> : null}

        {!licenceAdded && !(currentLicenceTypeCode === LICENCE_TYPE_CODES.PAID_LICENCE_CODE) &&
          <UpgradeDiscountBanner includeMargin={true}/>
        }

        {errorMessage === "" && !addingLicence && !licenceAdded && (venueData && venueData.country_id) ? <>
            <div className="row mt-8">
                <div className="flex flex-wrap flex-grow">
                    {validLicenceTypes.map((licenceType, i) => {
                        const option = licenceOptions.find(o => o.licenceTypeCode === licenceType.code);
                        const isCurrentLicenceType = currentLicenceTypeCode === option.licenceTypeCode;
                        return (<div className={`col col-w-full md:col-w-1/2 lg:col-w-1/${validLicenceTypes.length} mb-6 justify-center`} key={i}>
                            <div className={`bg-white h-full py-12 px-4 flex flex-col ${isCurrentLicenceType ? 'border-4' : 'border'}`}>
                              <span className="text-center text-5xl">
                                {typeof option.icon === "string" ? (<i className={`fal fa-${option.icon} block mb-4`}></i>) : (option.icon)}
                              </span>
                                <h3 className="text-center mb-5">{licenceType.name}</h3>
                                <div className={'mb-5'}>
                                    {option.content}
                                </div>
                                {option?.link ?
                                    <ButtonBrand
                                        Element={Link}
                                        to={isCurrentLicenceType ? false : option.link.replace(':slug', venueData.slug)}
                                        disabled={isCurrentLicenceType}
                                        classes="w-full mt-auto">{isCurrentLicenceType ? 'Your current licence' : 'Choose'}</ButtonBrand>
                                    :
                                    <ButtonBrand classes={'w-full mt-auto '}
                                                 disabled={isCurrentLicenceType || currentLicenceTypeCode === LICENCE_TYPE_CODES.PAID_LICENCE_CODE}
                                                 onClick={() => {
                                                     selectOption(licenceType.code)
                                                 }}
                                    >{isCurrentLicenceType ? 'Your current licence' : 'Choose'}</ButtonBrand>
                                }
                            </div>
                        </div>)
                    })}
                </div>
            </div>
            {currentLicenceTypeCode === LICENCE_TYPE_CODES.BASIC_FEE_CODE && <div className={'mb-2'}>
                <p className={'text-left'}><strong>Opt Out:</strong>&nbsp;
                    By opting out you do not have access to any venue promotion tools and are limited to 4 black and white photos. Check out our
                    basic fee option for access to more features - only pay the platform fee when you confirm a booking.&nbsp;
                    {currentLicenceTypeCode === LICENCE_TYPE_CODES.OPT_OUT_LICENCE_CODE ? ' You are currently opted out.' : <ButtonLink classes={'ml-1 cursor-pointer'}
                                                                                                                                        disabled={currentLicenceTypeCode === LICENCE_TYPE_CODES.PAID_LICENCE_CODE || currentLicenceTypeCode === LICENCE_TYPE_CODES.OPT_OUT_LICENCE_CODE}
                                                                                                                                        onClick={() => selectOption(LICENCE_TYPE_CODES.OPT_OUT_LICENCE_CODE)}
                    >Opt me out anyway</ButtonLink>}
                </p>
            </div>}
        </> : null}

        {showModal ? <LicenceAgreementModal
          licenceType={selectedLicenceType}
          onProceed={onProceed}
          onClose={() => setShowModal(false)}
          venueData={venueData}
        /> : null}
    </div>)
}


export default ManageVenueLicence;