import { calculateCommissionFromUnit, gatherTotals, getVatRateById } from "../Helpers/calculateTotals";
import { Field, formValueSelector } from "redux-form";
import { withRouter } from "react-router-dom";
import { getHiddenRows } from "../Helpers/getFormState";
import { isAgent, isVenueAdmin } from "../../../../../modules/auth";
import { connect } from "react-redux";
import { flagChanges } from "../Helpers/formHelper";
import React, { useEffect, useState } from "react";
import ChecksheetBreakdown from "./ChecksheetBreakdown";
import { ENQUIRY_VENUE_DATA_STATUS } from "../../../../../modules/rfp/enquiryVenueDataStatus";

function getEquipmentRemoveKey(id) {
  return `remove[equip_${id}]`;
}

let EquipmentBreakdown = (
  {
    equipment,
    commissionItems,
    hiddenRows,
    currencyFormatter,
    status,
    renderCheckbox,
    setEquipmentCommissionTotal,
    setEquipmentCommissionTotalGBP,
    setEquipmentTotal,
    setEquipmentValueChanged,
    initialValues,
    vatRates,
    showCommission,
    currentVatRateForCommission,
    currency = { currency_code: "GBP" },
  }) => {
  let totals = {};
  let commissionTotals = {};
  let commissionTotalsGBP = {};

  const [grandTotal, setGrandTotal] = useState(0);
  const [, setGrandCommissionTotal] = useState(0);
  const [grandTotalGBP, setGrandTotalGBP] = useState(0);
  const [, setGrandCommissionTotalGBP] = useState(0);

  useEffect(() => {
    gatherTotals(totals, setGrandTotal, setEquipmentTotal);
    gatherTotals(commissionTotals, setGrandCommissionTotal, setEquipmentCommissionTotal);
    gatherTotals(commissionTotalsGBP, setGrandCommissionTotalGBP, setEquipmentCommissionTotalGBP);
  }, [totals]);

  const onChange = (event, newValue, previousValue, name) => {
    flagChanges(initialValues, null, hiddenRows, newValue, name, setEquipmentValueChanged);

  };
  if (!equipment) {
    return null;
  }

  return (
    <ChecksheetBreakdown
      currency={currency}
      headers={(
        <React.Fragment>
          <th className={"p-2 pl-0 text-left uppercase text-xs min-14 w-auto"}>Description</th>
          <th className={"p-2 pl-0 text-left uppercase text-xs min-2 w-auto"}>Quantity</th>
          <th className={"p-2 pl-0 text-left uppercase text-xs min-4 w-auto"}>Unit Price</th>
          <th />
        </React.Fragment>
      )}
      spaces={4}
      totalAmount={currencyFormatter.format(grandTotal)}
      showCommission={showCommission}
    >
      {equipment.map(({
                        room_equipment,
                        cost,
                        equipment_name,
                        quantity,
                        id,
                        room,
                      }) => {
          let vatRate = getVatRateById(vatRates, cost.price_vat_rate_id).number;
          let finalTotal = Number(cost.price_net) + Number(cost.price_vat);
          let vatTotal = cost.price_vat;
          if (typeof hiddenRows[getEquipmentRemoveKey(id)] === "undefined") {
            totals[`equip_${id}`] = parseFloat(finalTotal);
          }
          let commission = commissionItems ? commissionItems.find(item => parseInt(item.step_id) === 4) : 0;

          let commissionRate = commission ? commission.rate : 0;
          let commissionAppliedOn = commission ? commission.applied_on : 0;
          let commissionIncludeVat = commission ? commission.include_vat : 0;
          let commissionTotal = 0;
          let commissionTotalGBP = 0;
          if (typeof hiddenRows[getEquipmentRemoveKey(id)] === "undefined") {
            commissionTotal = commission ? calculateCommissionFromUnit(commissionIncludeVat, Number(cost.price_net), Number(cost.price_vat), currentVatRateForCommission, 1, commissionRate, commissionAppliedOn, `equip_${id}`, commissionTotals) : 0;
            commissionTotalGBP = commission ? calculateCommissionFromUnit(commissionIncludeVat, Number(cost.price_net_gbp), Number(cost.price_net_gbp), currentVatRateForCommission, 1, commissionRate, commissionAppliedOn, `equip_${id}`, commissionTotalsGBP) : 0;
          }
          return (
            <tr
              key={`equipment_${id}`}
              className={hiddenRows[`${getEquipmentRemoveKey(id)}`] !== "undefined" && hiddenRows[`${getEquipmentRemoveKey(id)}`] === true ? "row-fadeout" : ""}>
              <td>{`${room_equipment.equipment_name === "Other" ? equipment_name : room_equipment.equipment_name} (${room.name})`}</td>
              <td>{quantity}</td>
              <td>{cost.unit_price ? currencyFormatter.format(cost.unit_price) : currencyFormatter.format(cost.price_net)}</td>
              <td />
              <td className={"p-2 text-right"}>{cost.price_net ? currencyFormatter.format(cost.price_net) : "N/A"}</td>
              <td className={"p-2 text-right"}>{`${vatRate}%`}</td>
              <td className={"p-2 text-right"}>{!cost.price_net ? "N/A" : currencyFormatter.format(vatTotal)}</td>
              {showCommission ?
                <td className={"p-2 text-right"}>
                  {`${commissionRate}%`}
                </td>
                :
                null
              }
              {showCommission ?
                <td className={"p-2 text-right"}>
                  {currencyFormatter.format(commissionTotal)}
                </td>
                :
                null
              }
              <td className={"p-2 text-right"}>{!cost.price_net ? "N/A" : currencyFormatter.format(finalTotal)}</td>

              <td className={"p-2 text-left w-8"}>
                {!(Number(status) === Number(ENQUIRY_VENUE_DATA_STATUS.VENUE_CONFIRMED) &&
                  (Number(status) === Number(ENQUIRY_VENUE_DATA_STATUS.USER_AMENDS) ||
                    status > ENQUIRY_VENUE_DATA_STATUS.VENUE_AMENDS)
                ) && (
                  <Field
                    name={getEquipmentRemoveKey(id)}
                    component={renderCheckbox}
                    onChange={onChange}
                  />
                )}
              </td>
            </tr>

          );
        },
      )}
    </ChecksheetBreakdown>
  );
};

const selector = formValueSelector("confirmBooking");

export default withRouter(
  connect((state, props) => ({
      hiddenRows: getHiddenRows(props.equipment, getEquipmentRemoveKey, state, selector),
      isVenue: isVenueAdmin(state),
      isAgency: isAgent(state),
    }),
  )(EquipmentBreakdown),
);

