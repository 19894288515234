import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { isRoleEdgeAdmin } from "../../../../modules/permissions";
import {
  fetchCashFlowReport,
  unloadCashFlowReport,
} from "../../../../redux/actions";
import { token } from "../../../../modules/auth";
import CashFlowReportTable from "../../../../Components/corporate/reports/CashFlowReportTable";
import { ButtonPrimary } from "../../../../Components/Button";
import Loading from "../../../../Components/Loading";
import * as ROUTES from "../../../../Router/corporate_routes";
import { removeEmptySearchParams } from "../../../../utils";
import { saveData } from "../../../../services/downloadFile";
import CashFlowReportFilters from "../../../../Components/reports/agency/CashFlowReportFilters";

const CashFlowReportContainer = ({
  token,
  fetchCashFlowReport,
  isFetchingCashFlowReport,
  cashFlowReport,
  unloadCashFlowReport,
}) => {
  const currentYear = new Date().getFullYear();
  const [isExporting, setIsExporting] = useState(false);

  const [queryParams, setQueryParams] = useState({
    calendarYear: currentYear,
    reportType: "calendarYear",
  });

  useEffect(() => {
    return () => {
      unloadCashFlowReport();
    };
  }, []);

  const updateQueryParams = params => {
    setQueryParams(prevParams => ({
      ...prevParams,
      ...params,
    }));
  };

  const runReport = useCallback(() => {
    if (!queryParams.calendarYear) {
      queryParams.calendarYear = currentYear;
    }
    return fetchCashFlowReport(token, queryParams);
  }, [token, queryParams]);

  const runCashFlowReportExport = useCallback(async () => {
    setIsExporting(true);
    try {
      if (!queryParams.calendarYear) {
        queryParams.calendarYear = currentYear;
      }
      let queryFilters = new URLSearchParams(queryParams);
      removeEmptySearchParams(queryFilters);
      let queryString = queryFilters.toString();
      let response = await axios.get(
        window.API +
          ROUTES.API.AGENCY.REPORTS.CASH_FLOW_REPORT_EXPORT +
          "?" +
          queryString,
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      saveData("csv", response.data, `cash-flow-report.csv`);
    } catch (e) {
      console.error(e);
    }
    setIsExporting(false);
  }, [token, queryParams]);

  const resetFilters = () => {
    unloadCashFlowReport();
    setQueryParams({
      calendarYear: currentYear,
      reportType: "calendarYear",
    });
    window.localStorage.removeItem("companyParams");
  };

  return (
    <div className="min-w-full">
      <h1 className="my-4">Cash flow report</h1>
      <CashFlowReportFilters
        resetFilters={resetFilters}
        runReport={runReport}
        shouldDisableSubmit={false}
        queryParams={queryParams}
        setQueryParams={updateQueryParams}
      />

      <div
        className={`flex flex-col lg:flex-wrap lg:flex-row align-bottom p-3 mb-3 lg:mb-0 lg:items-end bg-white`}
      >
        {isFetchingCashFlowReport ? (
          <div className={"row"}>
            <Loading />
          </div>
        ) : (
          <CashFlowReportTable cashFlowReport={cashFlowReport} />
        )}
        {!isFetchingCashFlowReport && (
          <div className={"row mb-2"}>
            <div className={"col flex"}>
              <ButtonPrimary
                classes={"mt-4"}
                onClick={() => runCashFlowReportExport()}
                disabled={isExporting}
              >
                Export to CSV
              </ButtonPrimary>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state.cashFlowReport,
    isRoleEdgeAdmin: isRoleEdgeAdmin(state.auth),
    token: token(state),
  };
};

const mapDispatchToProps = {
  fetchCashFlowReport,
  unloadCashFlowReport,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CashFlowReportContainer);
