import React, { useEffect } from "react";
import RegistrationLandingPage from "Components/RegistrationLandingPage";
import { agencyUnload, fetchAgencyBySlug } from "../redux/actions";
import { connect } from "react-redux";
import Loading from "../Components/Loading";
import NotFound from "./NotFound";

const CorporateEventBuyers = ({ match, fetchAgencyBySlug, agency, isFetchingAgency, agencyUnload }) => {
  useEffect(() => {
    fetchAgencyBySlug(match.params.agency);
    return () => agencyUnload();
  }, []);
  if (isFetchingAgency) {
    return <Loading />;
  }
  if (!agency ) {
    return <NotFound />;
  }
  return (
    <RegistrationLandingPage
      img="/img/landing/conference-banner.jpg"
      title={agency ? `Register as a company with ${agency.name}` : "Register as a corporate today for the best access to great venues."}
      heroBody="EDGE Venues offers a comprehensive platform featuring thousands of global venues, simplifying your search and booking process for exceptional meetings and corporate events."
      agency={agency}
      bannerFeatures={[]}
      type="corporate"
      successUrl="/corporate-registration-complete"
      formColor="#0077b5"
      bodyFeatures={[
        {
          icon: "globe-americas",
          title: "A Huge Database of Superb Venues at Your Fingertips",
          content: (
            <div>
              <p>
                We take pride in our extensive knowledge of venues, having built a large database of high-quality
                options to meet the needs of event and meeting planners. With years of experience in event planning, we
                understand exactly what you&#39;re looking for. Whether you need a unique venue in London or a training room
                in Manchester, we&#39;ve got it covered. Our expertise extends globally, with over 300 destinations in 45
                countries. Our database is constantly growing, so you’re sure to find the perfect venue for your next
                event.
              </p>
            </div>
          ),
        },
        {
          icon: "search",
          title: "Simple and Effective Search Functionality",
          content: (
            <div>
              <p>
                Simplicity is key. With two different search functions, you can quickly and easily find the venue you&#39;re
                looking for. Our &#39;simple&#39; search lets you view venues by destination, while the &#39;advanced&#39; search allows
                you to narrow down your options by filling in a few more details, saving you time later on.
              </p>
            </div>
          ),
        },
        {
          icon: (
            <div className="block mb-4">
              <i className="fal fa-building"></i>
            </div>
          ),
          title: "Venue comparison",
          content: (
            <div>
              <p>
                Can&apos;t decide which venue to choose? Our side-by-side comparison tool helps you compare your top
                venues and select the best one for your event. You can compare factors like meeting room sizes, number
                of bedrooms, and proximity to airports, making it easy to make informed decisions.
              </p>
            </div>
          ),
        },
        {
          icon: "heart",
          title: "Shortlist, Save, and Share",
          content: (
            <div>
              <p>
                Not ready to book yet? EDGE Venues lets you create shortlists, save your searches, and share them with
                colleagues. As a subscriber, you can revisit your saved searches at any time and pick up right where you
                left off.
              </p>
            </div>
          ),
        },
        {
          icon: "envelope-open-text",
          title: "Fast Enquiry Process",
          content: (
            <div>
              <p>
                We go beyond just helping you search for venues. Once you&apos;ve found the perfect options, our enquiry
                process allows you to express interest in multiple venues with just a few clicks. Venues will respond
                directly with availability and pricing details, helping you move forward with your event planning
                quickly and easily.
              </p>
            </div>
          ),
        },
        {
          icon: "newspaper",
          title: "News, Offers, and Insights",
          content: (
            <div>
              <p>
                Stay updated with the latest industry news through EDGE Venues. Our editors continuously share stories
                on new venue openings, refurbishments, event trends, and innovative concepts. Plus, we offer exclusive
                deals to help you find the best value for your event.
              </p>
            </div>
          ),
        },
      ]}
      splitFeatureTitle="Find out about becoming a Corporate EDGE partner today"
      splitFeatureList={[
        "Huge database of great quality venues",
        "Strategic meetings management",
        "Manage cost, risk and compliance",
        "Shortlist, save and share",
        "Agile, creative and dynamic",
        "News, offers and insights",
      ]}
    />
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    ...state.agency,
  };
};

const mapDispatchToProps = {
  fetchAgencyBySlug,
  agencyUnload,
};

export default connect(mapStateToProps, mapDispatchToProps)(CorporateEventBuyers);
