import React, { Fragment } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { processFormValuesPatch, renderFormInput } from "../../modules/formTools";
import { Textarea } from "../Form";
import Loading from "../Loading";
import { ButtonPrimary } from "../Button";

let CorporateBookingDeskForm = (props) => {
  const { handleSubmit, bookingDesk, submitting, initialValues } = props;
  const onSubmit = formValues => {
    return processFormValuesPatch(props, formValues, initialValues);
  };
  return <Fragment>
    <h3 className={"uppercase mb-4"}>
      Booking Desk: <span className={"text-brand"}>{bookingDesk.name}</span>
    </h3>
    <form className="border-none pb-2" onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className={"col col-w-full md:col-w-1/4"}>
          <Field
            name="prefix"
            label="RFP ID Prefix"
            component={renderFormInput}
          />
        </div>
        <div className={"col col-w-full md:col-w-3/4"}>
          <Field
            name="email_notification_override"
            label="Agency Email Notification Override - instead of Default set under the Agency Profile"
            component={renderFormInput}
          />
          <Field
            name="client_facing_email_notification_override"
            label="Client Facing Email Notification Override"
            component={renderFormInput}
          />
          <Field
            name="venue_facing_email_notification_override"
            label="Venue Facing Email Notification Override"
            component={renderFormInput}
          />
        </div>
      </div>
      <div className="row">
        <div className={"col col-w-full md:col-w-3/4"}>
          <Field
            name="legal_text"
            label="Legal Text"
            component={Textarea}
          />
        </div>
      </div>
      <div className="row">
        <div className={"col col-w-full mb-4"}>
          <ButtonPrimary type="submit" disabled={!props.dirty}>
            {submitting ? <Loading inline /> : ("Save")}
          </ButtonPrimary>
        </div>
      </div>
    </form>
  </Fragment>;
};

CorporateBookingDeskForm = reduxForm({
  enableReinitialize: true,
})(CorporateBookingDeskForm);

const mapStateToProps = (state, props) => {
  return {
    form: props.form,
    initialValues: {
      ...props.bookingDesk,
    },
  };
};

export default connect(mapStateToProps, null)(CorporateBookingDeskForm);

