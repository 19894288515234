import React from "react";
import {Link, Redirect} from "react-router-dom";
import {Helmet} from "react-helmet";
import axios from "axios";
import Loading from "./Loading";
import {isInternal, userType} from "../modules/auth";
import {canViewPost} from "modules/cms";
import {connect} from "react-redux";
import {replaceCommonEncodings} from "../modules/string-tools";
import pipe from "lodash/fp/pipe";
import get from "lodash/get";
import {getFeaturedMedia, getPostImg} from "../modules/cms/utils";
import {generalInteraction} from "../modules/analytics";

const cmsBaseUrl = "https://cms.edgevenues.com/wp-json/wp/v2";

const removeBrParagraphs = html => html.replace(/<p><br\/?><\/p>/g, "");

const tidyHtml = pipe(removeBrParagraphs);

class CmsPage extends React.Component {
  state = {
    page: undefined,
    loading: true,
    error: false,
  };

  loadCmsContent = () => {
    const {id, postId, slug} = this.props;

    this.setState({
      loading: true,
    });

    const url = id
      ? `${cmsBaseUrl}/pages/${id}?_embed`
      : postId
        ? `${cmsBaseUrl}/posts/${postId}?_embed`
        : slug
          ? `${cmsBaseUrl}/posts/?slug=${slug}&_embed`
          : "";

    return axios
      .get(url)
      .then(({data}) => {
        const page = Array.isArray(data) ? data[0] : data;

        this.setState({
          page,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          error: true,
        });
      });
  };

  componentDidMount() {
    const {page} = this.props;
    if (page) {
      this.setState({
        page,
        loading: false,
      });
    } else {
      this.loadCmsContent();
    }
  }

  renderHeader(post) {
    const url = document.location.href;

    const featuredMedia = getFeaturedMedia(post);

    const mediaDetails = featuredMedia && {
      width: get(featuredMedia, "media_details.width"),
      height: get(featuredMedia, "media_details.height"),
      url: getPostImg(post),
    };

    const title = post.title.rendered + " | Edge Venues";
    const description = post.acf && post.acf.a_strapline;

    return (
      <Helmet>
        <title>{replaceCommonEncodings(title)}</title>
        {mediaDetails && (
          <meta property="og:image" content={mediaDetails.url}/>
        )}
        {mediaDetails && (
          <meta property="og:image:secure_url" content={mediaDetails.url}/>
        )}
        {mediaDetails && (
          <meta property="og:image:width" content={mediaDetails.width}/>
        )}
        {mediaDetails && (
          <meta property="og:image:height" content={mediaDetails.height}/>
        )}
        <meta property="og:locale" content="en_GB"/>
        <meta property="og:type" content="article"/>
        <meta property="og:title" content={title}/>
        {description && (
          <meta property="og:description" content={description}/>
        )}
        {description && <meta property="description" content={description}/>}
        <meta property="og:url" content={url}/>
        <meta property="og:site_name" content="Edge Venues"/>
        <meta
          property="og:updated_time"
          content={post.modified_gmt + "+00:00"}
        />
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:title" content={title}/>
        {description && (
          <meta name="twitter:description" content={description}/>
        )}

        {mediaDetails && (
          <meta name="twitter:image" content={mediaDetails.url}/>
        )}
        {mediaDetails && (
          <meta name="twitter:image:width" content={mediaDetails.width}/>
        )}
        {mediaDetails && (
          <meta name="twitter:image:height" content={mediaDetails.height}/>
        )}
      </Helmet>
    );
  }

  render() {
    const {userType, isInternal, alwaysVisible = false} = this.props;
    const {loading, error, page} = this.state;
    if (loading) {
      return <Loading large/>;
    }

    if (error) {
      return <div>Error loading content</div>;
    }

    if (!page) {
      return "";
    }

    if (!alwaysVisible && !canViewPost(page, userType, isInternal)) {
      return <Redirect to="/login"/>;
    }

    const topImage = page.acf && page.acf.top_image;

    const venueSlug = page.acf && page.acf.venue_slug;

    return (
      <div>
        {this.renderHeader(page)}
        <article className="mx-auto lg:w-3/4">
          {topImage ? (
            <div
              className="container h-72 p-0 relative bg-center bg-cover overflow-hidden"
              style={{
                background: `url(${topImage}) no-repeat center center / cover`,
                maxHeight: "40vw",
              }}
            />
          ) : null}
          {venueSlug && (
            <div className="text-right">
              <Link
                className="inline-block tracking-loose font-semibold font-heading px-4 border uppercase no-underline text-center mt-5 -mb-2 text-white border-brand bg-brand"
                to={`/venue/${venueSlug}`}
              >
                Go to Venue
              </Link>
            </div>
          )}
          <h1
            className="my-6"
            dangerouslySetInnerHTML={{__html: page.title.rendered}}
          />
          <div
            className="from-cms clearfix"
            dangerouslySetInnerHTML={{
              __html: tidyHtml(page.content.rendered),
            }}
          />
          {venueSlug && (
            <div className="text-right">
              <Link
                className="inline-block tracking-loose font-semibold font-heading px-4 border uppercase no-underline text-center mt-5 text-white border-brand bg-brand"
                to={`/venue/${venueSlug}`}
              >
                Go to Venue
              </Link>
            </div>
          )}
        </article>
      </div>
    );
  }
}

export default connect(state => ({
  userType: userType(state),
  isInternal: isInternal(state),
}), {generalInteraction})(CmsPage);
