import React from "react";
import Modal from "Components/Modal";
import { ButtonSpan } from "Components/Button";
import { LabelledText } from "ui";
import { currencyFormatter } from "../../utils";

const gbpFormatter = currencyFormatter("GBP");

const outputFigure = (isGBP, formatter, value, src_cur_value) => {
  if (isGBP) {
    return formatter.format(Number(value).toFixed(2));
  }
  return (
    <span
      style={{ cursor: "help" }}
      title={"Approx " + gbpFormatter.format(Number(value).toFixed(2))}
    >
      {formatter.format(Number(src_cur_value).toFixed(2))}
    </span>
  );
};

const CommissionPriceBreakdownModal = ({
  commissionItems,
  summaryItems,
  onClose,
  venueId,
  currency = { currency_code: "GBP" },
}) => {
  const isGBP = currency.currency_code === "GBP";
  return (
    <Modal>
      <div className="modal">
        <div
          className="modal-inner"
          style={{
            width: 1200,
            maxWidth: "90%",
          }}
        >
          <ButtonSpan
            className="m-3 absolute pin-t pin-r z-10"
            onClick={onClose}
          >
            <i className="fas fa-times-circle fa-2x ml-3" />
          </ButtonSpan>
          <div>
            <h2 className="mb-3 text-3xl">Commission Breakdown</h2>
            {commissionItems
              .sort(function (commissionItemA, commissionItemB) {
                if (
                  commissionItemA.step.id !== 9 &&
                  commissionItemB.step.id !== 9
                ) {
                  return commissionItemA.step.id - commissionItemB.step.id;
                }
                if (commissionItemA.step.id === 9) {
                  return 1;
                }
                if (commissionItemB.step.id === 9) {
                  return -1;
                }
              })
              .map(
                item => (
                  // item.step_id !== 9 ? (
                  <div
                    className="mb-5 pt-3 border-t border-black"
                    key={item.step.name + venueId}
                  >
                    <h3 className="mb-3">{item.step.name}</h3>
                    <div className="flex flex-wrap">
                      <LabelledText
                        Wrapper="div"
                        className="mr-8 text-right"
                        label="Total Cost Before Commission"
                        text={currencyFormatter(currency.currency_code).format(
                          item.step.id === 10
                            ? item.total_cost_before_commission
                            : summaryItems.find(
                                summaryItem =>
                                  summaryItem.step_id === item.step.id
                              ).total_inc_vat
                        )}
                      />
                      {item.step_id !== 9 ? (
                        <LabelledText
                          Wrapper="div"
                          className="mr-8"
                          label="Rate"
                          text={item.rate}
                        />
                      ) : null}
                      {item.step_id !== 9 ? (
                        <LabelledText
                          Wrapper="div"
                          className="mr-8"
                          label="Applied on"
                          text={item.applied_on}
                        />
                      ) : null}
                      {item.step_id !== 9 ? (
                        <LabelledText
                          Wrapper="div"
                          className="mr-8"
                          label={
                            isGBP === "GBP" ? `Include VAT` : `Include Tax`
                          }
                          text={
                            !isGBP ? (
                              <span title={"Never on international"}>*No*</span>
                            ) : item.include_vat ? (
                              "Yes"
                            ) : (
                              "No"
                            )
                          }
                        />
                      ) : null}
                      <LabelledText
                        Wrapper="div"
                        className="mr-8 text-right"
                        label="Total Commission Net"
                        text={outputFigure(
                          isGBP,
                          currencyFormatter(currency.currency_code),
                          item.total_commission_net,
                          item.total_commission_net_src_cur
                        )}
                      />
                      <LabelledText
                        Wrapper="div"
                        className="mr-8 text-right"
                        label="Total Commission Vat"
                        text={outputFigure(
                          isGBP,
                          currencyFormatter(currency.currency_code),
                          item.total_commission_vat,
                          item.total_commission_vat_src_cur
                        )}
                      />
                      <LabelledText
                        Wrapper="div"
                        className="mr-8 text-right"
                        label="Total Commission"
                        text={outputFigure(
                          isGBP,
                          currencyFormatter(currency.currency_code),
                          item.total_commission,
                          item.total_commission_src_cur
                        )}
                      />
                    </div>
                  </div>
                )
                // ) : (
                //   <div
                //     className="mb-5 pt-3 border-t border-black"
                //     key={item.step.name + venueId}
                //   >
                //     <h3 className="mb-3">{item.step.name}</h3>
                //     <div className="flex flex-wrap">
                //       <LabelledText
                //         Wrapper="div"
                //         className="mr-8 text-right"
                //         label="Total Cost Before Commission"
                //         text={currencyFormatter(currency.currency_code).format(
                //           summaryItems.find(
                //             summaryItem => summaryItem.step_id === item.step.id
                //           ).total_inc_vat
                //         )}
                //       />
                //       <LabelledText
                //         Wrapper="div"
                //         className="mr-8 text-right"
                //         label="Total Commission Net"
                //         text={currencyFormatter(currency.currency_code).format(
                //           item.total_commission_net
                //         )}
                //       />
                //       <LabelledText
                //         Wrapper="div"
                //         className="mr-8 text-right"
                //         label="Total Commission Vat"
                //         text={currencyFormatter(currency.currency_code).format(
                //           item.total_commission_vat
                //         )}
                //       />
                //       <LabelledText
                //         Wrapper="div"
                //         className="mr-8 text-right"
                //         label="Total Commission"
                //         text={currencyFormatter(currency.currency_code).format(
                //           item.total_commission
                //         )}
                //       />
                //
                //       {/*<LabelledText*/}
                //       {/*    Wrapper="div"*/}
                //       {/*    className="mr-8 text-right"*/}
                //       {/*    label="Total Commission (GBP)"*/}
                //       {/*    text={currencyFormatter("GBP").format(item.total_commission_gbp)}*/}
                //       {/*/>*/}
                //     </div>
                //   </div>
                // )
              )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CommissionPriceBreakdownModal;
