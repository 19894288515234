import React, {Component} from "react";
import {
    createCompanyBranch,
    deleteCompany,
    deleteCompanyBranch,
    deleteCompanyUser,
    disableCompanyUserByToken,
    editCompany,
    editCompanyBranch,
    editCompanyUser,
    giveCompanyUserCorporateAccess,
    giveCompanyUserRoles,
    revokeCompanyUserCorporateAccess,
    revokeCompanyUserRoles,
    updateCorporateRolesForUser,
} from "../../../redux/actions";
import {connect} from "react-redux";
import {token} from "../../../modules/auth";
import CompanyForm from "../../../Components/corporate/CompanyForm";
import {isDirty} from "redux-form/immutable";
import {ButtonPrimary, ButtonSpan} from "../../../Components/Button";
import * as Sentry from "@sentry/browser";
import Loading from "../../../Components/Loading";
import {canManageCompanyUsers, isAgencyAdmin, isCompanyAdmin, isRoleEdgeAdmin, isRolePlatformAdmin} from "../../../modules/permissions";
import {LiveSearchSimple} from "../../../Components/Form/LiveSearch/LiveSearchSimple";
import UserInviteForm from "../../../Components/corporate/UserInviteForm";
import {deleteInvite, resendInvite} from "../../../redux/actions/corporateInvites";
import MultiSelect from "../../../ui/MultiSelect";
import startCase from "lodash/startCase";
import CompanyView from "../../../Components/corporate/CompanyView";
import DropzoneArea from "../../../Components/Form/File/DropzoneArea";
import {uploadCompanyUserlist} from "../../../services/companyService/uploadUserDocument";
import CompanyBranchForm from "../../../Components/corporate/CompanyBranchForm";
import CompanyBranchView from "../../../Components/corporate/CompanyBranchView";
import {getSuperAgencyPill, nameFormatter, sortName} from "../../../utils";
import * as ROUTES from "../../../Router/corporate_routes";
import {handleDownload} from "../../../services/downloadFile";
import {USER_TOKEN_DISABLED} from "../../../constants";
import Hubspot from "./Company/Hubspot";

const mapStateToProps = state => {
    return {
        ...state.company,
        token: token(state),
        isRoleEdgeAdmin: isRoleEdgeAdmin(state.auth),
        isRolePlatformAdmin: isRolePlatformAdmin(state.auth),
        isAgencyAdmin: isAgencyAdmin(state.auth),
        isCompanyAdmin: isCompanyAdmin(state.auth),
        canManageCompanyUsers: canManageCompanyUsers(state.auth),
        isDirty: isDirty("companyForm")(state),
        loggedInUser: state.auth.user,
    };
};

const allOption = {
    label: "Select all",
    value: "*",
};

const mapDispatchToProps = ({
    editCompany,
    deleteCompany,
    editCompanyUser,
    deleteCompanyUser,
    giveCompanyUserRoles,
    giveCompanyUserCorporateAccess,
    revokeCompanyUserRoles,
    revokeCompanyUserCorporateAccess,
    updateCorporateRolesForUser,
    resendInvite,
    deleteInvite,
    editCompanyBranch,
    createCompanyBranch,
    deleteCompanyBranch,
    disableCompanyUserByToken,
});

class CompanyEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {},
            roles: {},
            agency: {},
            corporateUserRoles: [],
            userDocument: null,
            disableButton: false,
            userCounts: null,
            addNewCompanyBranch: false,
            uploadingUsers: false,
            success: false,
            error: false,
            errorMessage: ''
        };
    }

    setUser = user => {
        this.setState({
            user: {
                ...user,
            },
        });
    };
    setAgency = agency => {
        this.setState({
            agency: {
                ...agency,
            },
        });
    };
    setUploadingUsers = (uploading = false) => {
        this.setState({
            uploadingUsers: uploading,
        });
    };

    //We work out corporate roles after we receive company from container, so we need to update the corporateUserRoles when it comes in otherwise you have to refresh to view users.
    //Might be a bit crap, if better way then let me know and ill refactor. - JR
    static getDerivedStateFromProps(props, current_state) {
        if (current_state.corporateUserRoles !== props.currentUserRoles) {
            return {
                corporateUserRoles: props.currentUserRoles,
            };
        }
        return null;
    }

    setCorporateUserRoles = (userId, corporateUserRoles) => {
        let corporateRoleIndex = this.state.corporateUserRoles.findIndex(cur => cur[0]?.user === userId);

        let corporateRoles = this.state.corporateUserRoles;
        corporateRoles[corporateRoleIndex] = [{
            user: userId,
            roles: corporateUserRoles,
        }];
        this.setState({
            companyUserRoles: corporateRoles,
        });
    };
    onSubmit = (formValues) => {
        if (this.props.isDirty) {
            return this.props.editCompany(this.props.company.id, formValues, this.props.token).then(
                (response) => {
                    this.setState({
                        success: true,
                        error: false,

                    })
                }
            ).catch((error) => {
                this.setState({
                    error: true,
                    errorMessage: error?.response?.data?.status === 'error' ? error.response.data.message : ''
                })
            });
        }
    };

    onSubmitCompanyBranchEdit = formValues => {
        let id = formValues.get("id");
        formValues.delete("id");
        return this.props.editCompanyBranch(id, this.props.company.id, formValues, this.props.token);
    };

    onDeleteCompanyBranch = id => {
        if (window.confirm("Are you sure you want to deactivate this branch?")) {
            return this.props.deleteCompanyBranch(id, this.props.company.id, this.props.token);
        }
    };

    onSubmitCompanyBranchCreate = formValues => {
        return this.props.createCompanyBranch(this.props.company.id, formValues, this.props.token);
    };

    deleteUser = (email) => {
        if (window.confirm("Are you sure you want to remove this user from the company?")) {
            let formValues = {"company": 0, "_method": "PATCH"};
            return this.props.deleteCompanyUser(email, formValues, this.props.token);
        }
    };

    deactivateCompany = () => {
        if (window.confirm("Are you sure you want to deactivate this Company? " +
            "Users associated with this Company will not be able to log in.")) {
            const formValues = {
                "_method": "PATCH",
                "is_active": false,
            };
            return this.props.editCompany(this.props.company.id, formValues, this.props.token);
        }
    };

    activateCompany = () => {
        if (window.confirm("Are you sure you want to activate this Company? " +
            "Users associated with this Company be able to log in again.")) {
            const formValues = {
                "_method": "PATCH",
                "is_active": true,
            };
            return this.props.editCompany(this.props.company.id, formValues, this.props.token);
        }
    };

    assignUser = () => {
        const user_name = this.state.user.first_name + " " + this.state.user.surname;
        const formValues = {
            "_method": "PATCH",
            "company": this.props.company.id,
        };
        if (window.confirm("Are you sure you want to assign " + user_name + " to this Company?")) {
            return this.props.editCompanyUser(this.state.user.email, formValues, this.props.token);
        }
    };

    exportUserLoginTokens = () => {
        let url = window.API + ROUTES.API.COMPANY.USER.EXPORT_TOKENS.replace(":id", this.props.company.id);
        if (window.confirm("Export user tokens?")) {
            handleDownload(this.props.company.code + "_user_login_tokens.csv", url, "csv", this.props.token);
        }
    };

    assignAgency = () => {
        const formValues = {
            "_method": "PATCH",
            "agency_id": this.state.agency.id,
        };
        if (window.confirm("Are you sure you want to assign this Company to " + this.state.agency.name)) {
            return this.props.editCompany(this.props.company.id, formValues, this.props.token);
        }
    };

    userRoles = (user) => {
        return user.user_roles.map((item => this.props.roles.filter(obj => {
            return obj.name === item;
        })));
    };

    giveCompanyAdmin = (user) => {
        let formValues = {
            "roles": ["company_administrator"],
            "email": user.email,
        };
        return this.props.giveCompanyUserRoles(formValues, this.props.token);
    };

    giveCorporateAccess = (user) => {
        let formValues = {
            "corporate_access": true,
            "email": user.email,
        };
        return this.props.giveCompanyUserCorporateAccess(formValues, this.props.token);
    };

    removeCompanyAdmin = (user) => {
        let formValues = {
            "roles": ["company_administrator"],
            "email": user.email,
        };
        return this.props.revokeCompanyUserRoles(formValues, this.props.token);
    };

    removeCorporateAccess = (user) => {
        let formValues = {
            "corporate_access": false,
            "email": user.email,
        };
        return this.props.revokeCompanyUserCorporateAccess(formValues, this.props.token);
    };

    disableUserToken = user => {
        let formValues = {
            "token": user.login_token,
        };
        let name = nameFormatter(user, false);
        if (window.confirm(`Are you sure you would like to disable ${name} token?`))
            return this.props.disableCompanyUserByToken(formValues, this.props.token);
    };

    resendUserInvite = invite_code => {
        return this.props.resendInvite(invite_code, this.props.token).then(() => {
            this.setState({disableButton: !this.state.disableButton});
            setTimeout(
                () => this.setState({disableButton: !this.state.disableButton}),
                5000,
            );
        });
    };

    deleteUserInvite = (invite_token) => {
        if (window.confirm("Are you sure you want to delete this user invite for your company?")) {
            return this.props.deleteInvite(invite_token, this.props.token).then(() => {
                window.location.reload();
            });
        }
    };

    uploadUsersDocument = (file) => {
        this.setState({
            userDocument: file,
        });
    };
    uploadUserList = () => {
        this.setUploadingUsers(true);
        const formData = new FormData();
        formData.append("user_list", this.state.userDocument[0]);
        uploadCompanyUserlist(formData, this.props.company.id, this.props.token).then((response) => {
            this.setUploadingUsers(false);
            if (typeof response.result !== "undefined") {
                this.setState({
                    userCounts: response.result,
                });
            }
        });
    };


    renderUsersList() {
        const {
            updateCorporateRolesForUser,
            isCompanyAdmin,
            isRoleEdgeAdmin,
            isAgencyAdmin,
            loggedInUser,
            company,
        } = this.props;

        const handleStatusChange = (user, e) => {
            this.setCorporateUserRoles(user.id, e);
            updateCorporateRolesForUser(user.id, [e.value], this.props.token);
        };

        try {
            return company.users.sort((a, b) => sortName(a, b)).map((user) => {
                return (
                    <tr key={user.id}>
                        <td>
                            <div className="flex items-center">
                                <div className="text-sm p-3 font-medium text-gray-900">
                                    <ButtonSpan className="text-warning" onClick={() => this.deleteUser(user.email)}>
                                        <i className="fas fa-trash"/>
                                    </ButtonSpan>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="flex items-center">
                                <div className="p-3 font-bold text-gray-900">
                                    {user.first_name ?? "-"} {user.surname ?? "-"}
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="flex items-center">
                                <div className="text-sm p-3 font-medium text-gray-900">
                                    {user.email ?? "-"}
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className={"flex items-center" + company.corporate_account ? "flex-col" : ""}>
                                <div className="flex items-center">
                                    {this.userRoles(user)
                                        .filter(obj => obj[0] && obj[0].human_readable_name.toLocaleLowerCase().includes("company"))
                                        .map(obj => {
                                            return obj[0] ? obj[0].human_readable_name : "None";
                                        }).join(", ")}
                                </div>
                                {company.corporate_account ?
                                    <div className={"pt-2"}>
                                        <MultiSelect
                                            options={this.props.corporateRoles.map((role) => {
                                                return {
                                                    label: startCase(role.name),
                                                    value: role.name,
                                                };
                                            })}
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            onChange={(e) => handleStatusChange(user, e)}
                                            value={this.state.corporateUserRoles.find((cur) => cur[0].user === user.id)[0]?.roles}
                                        />
                                    </div>
                                    :
                                    null}
                            </div>
                        </td>
                        <td>
                            {/* if the company has an sso method of token and users token is disabled output disabled text */}
                            {company.sso_method === "token" && user.login_token === USER_TOKEN_DISABLED ?
                                <div className="flex flex-col items-center">
                                    <strong>Token Disabled</strong>
                                </div>
                                : user.email !== loggedInUser.email ?
                                    <div className="flex flex-col items-center">
                                        {(isRoleEdgeAdmin || isAgencyAdmin || isCompanyAdmin) && user.user_roles.includes("company_administrator") ? (
                                            <ButtonPrimary
                                                onClick={() => this.removeCompanyAdmin(user)}
                                                classes="bg-dark-red mb-2 text-sm w-full" small>
                                                Revoke Company Admin
                                            </ButtonPrimary>
                                        ) : (
                                            <ButtonPrimary onClick={() => this.giveCompanyAdmin(user)} classes=" mb-2 text-sm w-full" small>
                                                Give Company Admin
                                            </ButtonPrimary>
                                        )}
                                        {company.corporate_account ? ((isRoleEdgeAdmin || isAgencyAdmin || isCompanyAdmin) && user.has_corporate_access ? (
                                            <ButtonPrimary
                                                onClick={() => this.removeCorporateAccess(user)}
                                                classes="bg-dark-red text-sm w-full" small>
                                                Revoke Corporate Access
                                            </ButtonPrimary>
                                        ) : (
                                            <ButtonPrimary
                                                onClick={() => this.giveCorporateAccess(user)}
                                                classes=" mb-2 text-sm w-full"
                                                small>
                                                Give Corporate EDGE Access
                                            </ButtonPrimary>
                                        )) : null}
                                        {isRoleEdgeAdmin && user.login_token !== null && user.login_token !== USER_TOKEN_DISABLED ? (
                                            <ButtonPrimary
                                                classes="mt-2 text-sm w-full" small
                                                onClick={() => this.disableUserToken(user)}>
                                                Disable User Token
                                            </ButtonPrimary>
                                        ) : null}
                                    </div>
                                    : null}

                        </td>
                    </tr>
                );
            });
        } catch (e) {
            Sentry.captureException(e);
        }
    }

    renderInvitedUsersList() {
        try {
            return this.props.userInvites.sort((a, b) => sortName(a, b)).map((user) => {
                return (
                    <tr key={user.id}>
                        <td>
                            <div className="flex items-center">
                                <div className="text-sm p-3 font-medium text-gray-900">
                                    <ButtonSpan className="text-warning" onClick={() => this.deleteUserInvite(user.invite_code)}>
                                        <i className="fas fa-trash"/>
                                    </ButtonSpan>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="flex items-center">
                                <div className="p-3 font-bold text-gray-900">
                                    {user.first_name} {user.surname}
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="flex items-center">
                                <div className="text-sm p-3 font-medium text-gray-900">
                                    {user.email}
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="flex items-center text-dark-red font-bold">Pending...</div>
                        </td>
                        <td>
                            <div className="flex items-center">
                                <ButtonPrimary
                                    small
                                    classes="w-full text-sm"
                                    onClick={() => this.resendUserInvite(user.invite_code)}
                                    disabled={this.state.disableButton}>
                                    Resend Invite
                                </ButtonPrimary>
                            </div>
                        </td>
                    </tr>
                );
            });
        } catch (e) {
            Sentry.captureException(e);
        }
    }

    render() {
        const {error, errorMessage} = this.state;

        const {
            company,
            countries,
            accessDenied,
            isFetchingCompany,
            industries,
            canManageCompanyUsers,
            isCompanyAdmin,
            isRoleEdgeAdmin,
            isAgencyAdmin,
            companyBranches,
            isFetchingCompanyBranches,
            agencyBookingDesks,
            isRolePlatformAdmin
        } = this.props;
        if (isFetchingCompany) {
            return (
                <div>
                    <Loading/>
                </div>
            );
        }
        if (accessDenied) {
            return (
                <div className={"bg-white p-5 mt-10"}>
                    <h2 className={"mb-4 md:mt-4 md:mb-6 md:flex md:flex-row md:justify-between md:items-center uppercase"}>
                        You don't have access to this Company
                    </h2>
                </div>
            );
        }
        return (
            <>
                <h1 className={"my-5"}>Manage
                    Company: {company.name}</h1>
                <div className={'mb-5'}>
                    {company.agency ? getSuperAgencyPill({agency: company.agency}) : null}
                </div>
                <div className={"bg-white p-5 mb-8"}>

                    {isCompanyAdmin ? (
                        <CompanyView company={company} industries={industries}/>
                    ) : (
                        <CompanyForm
                            company={company}
                            onSubmit={this.onSubmit}
                            countries={countries}
                            industries={industries}
                            isCompanyAdmin={isCompanyAdmin}
                            isRoleEdgeAdmin={isRoleEdgeAdmin}
                            isAgencyAdmin={isAgencyAdmin}
                            bookingDesks={agencyBookingDesks[company?.agency?.name]}
                            submitError={error}
                            errorMessage={errorMessage}
                        />
                    )}
                    {company.users && canManageCompanyUsers && (
                        <div className="pt-4 user-management">
                            <h3 className={"uppercase pt-5 border-t border-grey my-5"}>Manage Users</h3>
                            <table className="edge-table edge-table-company-users">
                                <thead>
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">
                                        Actions
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">User</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">Email</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide">Roles</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wide"/>
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                {this.renderUsersList()}
                                {this.renderInvitedUsersList()}
                                </tbody>
                            </table>
                        </div>
                    )}
                    {isRoleEdgeAdmin && company.sso_method === "token" ? (
                        <ButtonPrimary
                            classes="mt-4"
                            onClick={this.exportUserLoginTokens}>
                            Export User Login Tokens
                        </ButtonPrimary>
                    ) : null}
                    {(isRoleEdgeAdmin) && (
                        <div className="py-10">
                            <h3 className={"uppercase pt-5 border-t border-grey my-5"}>Assign an existing user</h3>
                            <LiveSearchSimple
                                auth
                                label="Search Users"
                                getText={result => result.email}
                                url={`${window.API}/users?corporate=1`}
                                onChange={this.setUser}
                                query="company"
                            />
                            {(this.state.user) && (
                                <ButtonPrimary
                                    onClick={this.assignUser}>
                                    Assign user to Company
                                </ButtonPrimary>
                            )}
                        </div>
                    )}
                    {company && company.sso_method === null && (isRoleEdgeAdmin || isAgencyAdmin || isCompanyAdmin) && (
                        <div className="py-10">
                            <UserInviteForm type={"company"} company={company}/>
                        </div>
                    )}
                    {isRoleEdgeAdmin && (
                        <div className="py-10">
                            <h3 className={"uppercase pt-5 border-t border-grey my-5"}>Assign company to a different agency</h3>
                            <LiveSearchSimple
                                label="Search For An Existing Agency"
                                name="agency_name"
                                getText={result => result.name}
                                url={`${window.API}/search/agency`}
                                onChange={this.setAgency}
                                query="agency"
                            />
                            {(this.state.agency) && (
                                <ButtonPrimary
                                    onClick={this.assignAgency}>
                                    Assign Company to Agency
                                </ButtonPrimary>
                            )}
                        </div>
                    )}
                    {isRoleEdgeAdmin && (
                        <div className="py-10">
                            <h3 className={"uppercase pt-5 border-t border-grey my-5"}>Upload Company User List</h3>

                            <div className={"pb-5"}>
                                <a href={"/files/sample-user-import-sheet.csv"}>
                                    <ButtonPrimary classes=" mb-2 text-sm" small>
                                        Download Sample Spreadsheet
                                    </ButtonPrimary>
                                </a>
                                <p>For meeting hosts, ensure that sendPasswordEmail is “0” and isCorporate = “0” </p>
                            </div>
                            <DropzoneArea
                                type="csv"
                                minWidth={"200px"}
                                minHeight={"100px"}
                                onDrop={this.uploadUsersDocument}
                                uploading={false}
                            />
                            <div className={"flex flex-row"}>
                                <p>{this.state.userDocument?.[0]?.name}</p>
                                <React.Fragment>

                                </React.Fragment>

                            </div>
                            {(this.state.agency && this.state.userDocument) && (
                                <ButtonPrimary
                                    onClick={this.uploadUserList}
                                    disabled={this.state.uploadingUsers}
                                >
                                    {this.state.uploadingUsers ? <Loading/> : "Create Users"}
                                </ButtonPrimary>
                            )}
                            {
                                this.state.userCounts ?
                                    <div className={"pt-5"}>
                                        <p>Created: {this.state.userCounts.usersCreated}</p>
                                        <p>Updated: {this.state.userCounts.usersUpdated}</p>
                                        <p>Failed: {this.state.userCounts.usersFailed}</p>
                                    </div>
                                    : null
                            }
                        </div>
                    )}

                    {isAgencyAdmin || isRoleEdgeAdmin ? (
                        <div className="py-10">
                            <h3 className={"uppercase pt-5 border-t border-grey my-5"}>Manage Branches</h3>
                            <div className={"row"}>
                                {isFetchingCompanyBranches ? <Loading/> : (
                                    companyBranches && companyBranches.length > 0 ? (
                                        companyBranches.sort((a, b) => new Date(a.deleted_at) - new Date(b.deleted_at)).map((companyBranch, id) => {
                                            return (
                                                <div key={id} className={"w-full bg-light-grey my-2 px-10 py-4"}>
                                                    {companyBranch.deleted_at ? (
                                                            <CompanyBranchView
                                                                companyBranch={companyBranch}
                                                            />
                                                        ) :
                                                        <CompanyBranchForm
                                                            form={`companyBranchForm${id}`}
                                                            companyBranch={companyBranch}
                                                            countries={countries}
                                                            onSubmit={this.onSubmitCompanyBranchEdit}
                                                            onDeactivateBranch={this.onDeleteCompanyBranch}
                                                        />
                                                    }
                                                </div>
                                            );
                                        })
                                    ) : null
                                )}
                                {this.state.addNewCompanyBranch ? (
                                    <div className={"w-full bg-light-grey my-2 px-10 py-4"}>
                                        <CompanyBranchForm
                                            form={"companyBranchForm"}
                                            countries={countries}
                                            onSubmit={this.onSubmitCompanyBranchCreate}
                                            showDelete={false}
                                        />
                                    </div>
                                ) : null}
                                <div className="col">
                                    <ButtonPrimary
                                        type="button"
                                        onClick={() => this.setState({addNewCompanyBranch: !this.state.addNewCompanyBranch})}>
                                        {this.state.addNewCompanyBranch ? "Cancel" : "Add Branch"}
                                    </ButtonPrimary>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {isRolePlatformAdmin ?
                        <div className="py-10">
                            <h3 className={"uppercase pt-5 border-t border-grey my-5"}>Manage Hubspot</h3>
                            <div className={'col-w-full md:col-w-1/3'}><Hubspot/></div>
                        </div> : null}

                    {((isRoleEdgeAdmin || isAgencyAdmin) && this.props.company.is_active === 1) && (
                        <ButtonPrimary classes={"bg-dark-red"} onClick={this.deactivateCompany}>
                            <i className="fas fa-trash mr-3"/> Deactivate Company
                        </ButtonPrimary>
                    )}
                    {((isRoleEdgeAdmin || isAgencyAdmin) && this.props.company.is_active === 0) && (
                        <ButtonPrimary onClick={this.activateCompany}>
                            <i className="fas fa-check-circle mr-3"/> Activate Company
                        </ButtonPrimary>
                    )}
                </div>
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyEdit);
