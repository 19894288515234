import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import MultiSelect from "../../../ui/MultiSelect";
import { Label, Select } from "../../../ui";
import {
  hasAgencyRoles,
  isRolePlatformAdmin,
  isSuperAgent,
  token,
} from "../../../modules/auth";
import { agencyObject, isRoleEdgeAdmin } from "../../../modules/permissions";
import { ToggleReportType } from "../ToggleReportType";
import {
  fetchAgenciesForSelect,
  fetchCompanies,
  fetchCompaniesForEvents,
} from "../../../redux/actions";
import { getYears } from "../../../utils";
import { ButtonGrey, ButtonPrimary } from "../../Button";
import { alphabetiseByName } from "../../../modules/util";

const currentYear = new Date().getFullYear();

// 2018 is initial commit - current year + 5
const yearOptions = getYears(2018, currentYear + 5).map(year => ({
  text: year.toString(),
  value: year,
}));

function CashFlowReportFilters({
  fetchCompanies,
  fetchCompaniesForEvents,
  fetchAgenciesForSelect,
  agenciesForSelect,
  companies,
  selectedAgencies, // superagent header picker agencies
  isEdgeAdmin,
  isSuperAgent,
  isAgencyUser,
  token,
  isPlatformAdmin,
  runReport,
  resetFilters,
  setQueryParams,
  usersAgency,
}) {
  const [selectedAgencyOptions, setSelectedAgencyOptions] = useState([]);
  const [selectedCompanyOptions, setSelectedCompanyOptions] = useState([]);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [reportType, setReportType] = useState("calendarYear");

  // convert super agent header selected agency scope into agency id list.
  const superAgentSelectedAgencyIds = useMemo(
    () => selectedAgencies.map(a => a.id) || [],
    [selectedAgencies]
  );

  const reset = () => {
    resetFilters();
    setSelectedAgencyOptions([]);
    setSelectedCompanyOptions([]);
    setSelectedYear(currentYear);
    setReportType("calendarYear");
    if (superAgentSelectedAgencyIds?.length > 0) {
      setQueryParams({
        agency: superAgentSelectedAgencyIds,
      });
    }
  };

  // load any agencies that need displaying in selects
  useEffect(() => {
    if (isEdgeAdmin || isPlatformAdmin) {
      fetchAgenciesForSelect(token);
    }
    if (isAgencyUser) {
      fetchCompaniesForEvents(token);
    }
  }, [isEdgeAdmin, isPlatformAdmin, isAgencyUser]);

  // load all relevant companies if any agencies are selected/inherent
  useEffect(() => {
    if (selectedAgencyOptions.length || superAgentSelectedAgencyIds.length) {
      if (isAgencyUser) {
        if (superAgentSelectedAgencyIds?.length > 0) {
          setQueryParams({
            agency: superAgentSelectedAgencyIds,
          });
        }
        fetchCompaniesForEvents(token);
      } else {
        fetchCompanies(token, null, {}, true); //{ agencyId: typeof agency === "object" ? agency.id : agency }
      }
    }
  }, [superAgentSelectedAgencyIds, selectedAgencyOptions, isAgencyUser]);

  // if there are any agencies to display, alphabetise them
  const agencyOptions = useMemo(
    () =>
      agenciesForSelect.length ? agenciesForSelect.sort(alphabetiseByName) : [],
    [agenciesForSelect]
  );

  // if there are any companies to display - filter them by any relevant selected agencies (+ companies with no agency)
  const selectedAgencyOptionIds = useMemo(() => {
    // for non super(/parent) agency, agency users, ensure their own agency is always the only one
    if (!isSuperAgent && isAgencyUser) {
      return [usersAgency.id];
    }
    return selectedAgencyOptions.map(o => o.value);
  }, [selectedAgencyOptions, isSuperAgent, isAgencyUser]);

  const companyOptions = useMemo(() => {
    return companies?.length
      ? companies.sort(alphabetiseByName).filter(company => {
          const companyAgencyId =
            company.agency?.id ?? company?.agency_id ?? null;
          return (
            (isEdgeAdmin && companyAgencyId === null) ||
            selectedAgencyOptionIds.includes(companyAgencyId) ||
            superAgentSelectedAgencyIds.includes(companyAgencyId)
          );
        })
      : [];
  }, [
    companies,
    selectedAgencyOptionIds,
    superAgentSelectedAgencyIds,
    isEdgeAdmin,
  ]);

  const agenciesSelectedCount =
    selectedAgencyOptions.length + superAgentSelectedAgencyIds.length;
  const multipleAgenciesSelected = agenciesSelectedCount > 1;

  // if not only one agency is selected
  if (agenciesSelectedCount !== 1 && reportType !== "calendarYear") {
    // report type must be calendarYear
    if (reportType !== "calendarYear") {
      setReportType("calendarYear");
      setQueryParams({ reportType: "calendarYear" });
    }
  }

  return (
    <div>
      <div className="flex flex-col lg:flex-wrap lg:flex-row justify-start align-bottom pt-3 bg-white lg:items-end">
        {(isEdgeAdmin || isPlatformAdmin) && agenciesForSelect ? (
          <div className={"col col-w-full md:col-w-1/4 mb-6"}>
            <Label name={"agencies"} text={"Filter by agency"} />
            <MultiSelect
              name={"agencies"}
              isMulti
              isClearable
              closeMenuOnSelect={false}
              options={agencyOptions.map(agency => ({
                label: agency.name,
                value: agency.id,
              }))}
              onChange={selectedOptions => {
                setQueryParams({
                  agency: selectedOptions?.map(o => o.value) ?? [],
                });
                setSelectedAgencyOptions(selectedOptions ?? []);
              }}
              value={selectedAgencyOptions}
            />
          </div>
        ) : null}
        {selectedAgencyOptions.length === 1 || selectedAgencies.length === 1 ? (
          <div className={"col col-w-full md:col-w-1/6 mb-6"}>
            <Label name={"companies"} text={"Report on"} />
            <ToggleReportType
              reportType={reportType}
              financialYearOnClick={() => {
                setQueryParams({ reportType: "financial" });
                setReportType("financial");
              }}
              calendarYearOnClick={() => {
                setQueryParams({ reportType: "calendarYear" });
                setReportType("calendarYear");
              }}
            />
          </div>
        ) : null}
        {yearOptions?.length ? (
          <div className={"col col-w-full md:col-w-1/10 mb-6"}>
            <Select
              name="calendarYear"
              label="Year"
              component={Select}
              options={yearOptions}
              wrapperClassName="mb-0"
              input={{
                onChange: e => {
                  setQueryParams({ calendarYear: e.currentTarget.value });
                  setSelectedYear(e.currentTarget.value);
                },
                value: selectedYear,
              }}
              meta={{}}
            />
          </div>
        ) : null}

        {companyOptions.length ? (
          <div className={"col col-w-full md:col-w-1/4 mb-6"}>
            <Label name={"companies"} text={"Filter by company"} />
            <MultiSelect
              name={"companies"}
              isMulti
              closeMenuOnSelect={false}
              options={companyOptions.map(company => ({
                label:
                  company.name +
                  (multipleAgenciesSelected && company.agency?.name
                    ? " (" + company.agency?.name + ")"
                    : ""),
                value: company.id,
              }))}
              onChange={selectedOptions => {
                setQueryParams({
                  company: selectedOptions?.map(o => o.value) ?? [],
                });
                setSelectedCompanyOptions(selectedOptions ?? []);
              }}
              value={selectedCompanyOptions}
            />
          </div>
        ) : null}
      </div>
      <div className="flex flex-col lg:flex-wrap lg:flex-row justify-end align-bottom p-3 bg-white lg:items-end">
        <div className="flex mb-2">
          <ButtonPrimary
            // disabled={shouldDisableSubmit}
            onClick={runReport}
          >
            {/*{isLoading && <Loading/>}*/}
            Run Report
          </ButtonPrimary>
          <ButtonGrey
            onClick={() => {
              reset();
            }}
            classes={"ml-2"}
          >
            Reset
          </ButtonGrey>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  let companies = state.companyList.companiesForEvents
    ? state.companyList.companiesForEvents.data
    : [];
  let isEdgeAdmin = isRoleEdgeAdmin(state.auth);
  if (isEdgeAdmin) {
    companies = state.companyList.companies ? state.companyList.companies : [];
  }
  return {
    isSuperAgent: isSuperAgent(state),
    isAgencyUser: hasAgencyRoles(state),
    usersAgency: agencyObject(state.auth),
    companies: companies || [],
    ...state.agenciesForSelect,
    ...state.selectedAgencies, // from top level selector
    ...state.agencyList,
    isEdgeAdmin,
    token: token(state),
    isPlatformAdmin: isRolePlatformAdmin(state),
  };
};

const mapDispatchToProps = {
  fetchCompanies,
  fetchCompaniesForEvents,
  fetchAgenciesForSelect,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CashFlowReportFilters);
