// we're thunking here so we don't have to pass the whole form values state around the entirity of AS
// (avoiding re-renders on every input change)

import axios from "axios";
import { getFormValues } from "redux-form";
import queryString from "query-string";
import { omit } from "lodash";

let source;

export default (history, location, localStateSetter, v) => (d, getState) => {

  if (source) {
    source.cancel("Operation canceled due to a new request.");
  }

  source = axios.CancelToken.source();

  localStateSetter.loading();
  const values = { ...getFormValues("location-search")(getState()) };
  values.location_type = "Map";
  const type = values.venueType_id;
  delete values.venueType_id;
  if (type) {
    values.types = [type];
  }
  // If setOther = category is set then override the type with the category
  if(typeof values.category !== 'undefined' && values.category)
  {
    values.types=[values.category]
    delete values.category;
  }

  const params = {
    ...values,
    ...v,
  };

  Object.keys(params).forEach(v => {
    if (params[v] === "") delete params[v];
  });

  if (params.uuid) {
    delete params.uuid;
  }

  axios
    .get(`${window.NODE_AS}/`, {
      params,
      cancelToken: source.token,
    })
    .then(r => {
      localStateSetter.results(r.data.data, r.data.count);
      history.push(
        `/venues-in-${location}?results=${r.data.count}&${queryString.stringify(
          omit(params, ["placeArea"]),
        )}`,
      );
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        console.log("Request canceled", err.message);
      } else {
        localStateSetter.error();
      }
    });
};
