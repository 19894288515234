import axios from "axios";
import * as ROUTES from '../Router/public_routes';

export const INDUSTRY_LIST_RECEIVED = 'INDUSTRY_LIST_RECEIVED';
export const INDUSTRY_LIST_REQUEST = 'INDUSTRY_LIST_REQUEST';
export const INDUSTRY_LIST_ERROR = 'INDUSTRY_LIST_ERROR';

export const fetchIndustries = () => {
  return (dispatch) => {
    dispatch(industryListRequest());
    return axios.get(window.API + ROUTES.API.INDUSTRY_SECTOR.LIST, {})
      .then(response => dispatch(industryListReceivedRequest(response.data)))
      .catch(error => console.log('fetchIndustries', error));
  }
}

export const industryListRequest = () => ({
  type: INDUSTRY_LIST_REQUEST
});

export const industryListReceivedRequest = (data) => ({
  type: INDUSTRY_LIST_RECEIVED,
  data
});

export const industryListReceivedError = (error) => ({
  type: INDUSTRY_LIST_ERROR,
  error
});

const initState = {
  industries: null,
  error: false,
};

export const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'INDUSTRY_LIST_RECEIVED':
      return {
        ...state,
        industries: action.data.data
      };

    case 'INDUSTRY_LIST_ERROR':
      return {
        ...state,
        error: true
      };
    default:
      return state;
  }
};