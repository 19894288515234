import React from "react";
import { string, bool, arrayOf, shape, number } from "prop-types";
import { Field } from "redux-form";
import {
  Input,
  File,
  Wysiwyg,
  Venues,
  Textarea,
  DateRange,
  Error,
} from "Components/Form";
import { maxLength, required } from "modules/validation";

const v = {
  title: [required(), maxLength(100)],
  content: [required(), maxLength(3000)],
  image_id: [required()],
};

export default class ArticleFields extends React.Component {
  static propTypes = {
    venuePermissionToCheck: string,
    formId: string.isRequired,
    showBookingRange: bool,
    venueOptions: arrayOf(
      shape({
        id: number.isRequired,
        name: string.isRequired,
        permissions: arrayOf(string)
      })
    ),
  };

  render() {
    return (
      <React.Fragment>
        <Field name="title" label="Title" component={Input} size="lg" />
        <Venues
          permissionToCheck={this.props.venuePermissionToCheck}
          isRequired
          venueOptions={this.props.venueOptions}
        />
        <DateRange
          formId={this.props.formId}
          label="Valid Date Range"
          startName="valid_from"
          endName="valid_to"
        />
        {this.props.showBookingRange && (
          <DateRange
            formId={this.props.formId}
            label="Valid Booking Date Range"
            startName="valid_booking_from"
            endName="valid_booking_to"
          />
        )}
        <Field name="content" label="Content" component={Wysiwyg} />
        <Field
          name="terms_and_conditions"
          label="Terms &amp; Conditions"
          component={Textarea}
          rows={7}
        />
        <div className="md:flex">
          <Field
            name="phone_number"
            label="Phone"
            size="md"
            component={Input}
          />
          <Field
            name="email"
            type="email"
            label="Email"
            size="md"
            component={Input}
          />
        </div>
        <File
          type="image"
          path={`${window.API}/cms/images`}
          objectName="image"
          idName="image_id"
          formId={this.props.formId}
        />
        <Field
          name="image_id"
          validate={v.image_id}
          component={({ meta }) => {
            return (
              <>
                <Error
                  touched={meta.touched}
                  warning={meta.warning}
                  error={meta.error}
                />
                {meta.touched && meta.error && (
                  <>
                    <br />
                    <br />
                  </>
                )}
              </>
            );
          }}
        />
        <File
          label="Attachments"
          type="document"
          array
          path={`${window.API}/cms/files`}
          readFrom="files"
          formId={this.props.formId}
        />
      </React.Fragment>
    );
  }
}
