import { isRolePlatformAdmin, venues as authVenues } from "../../auth";
import { venueHasPermission } from "../../permissions";
import { PERMISSIONS } from "../../../constants";

export default state => {
  const vs = authVenues(state);
  if (vs === "*") {
    return vs;
  } else {
    return vs
      .filter(v => isRolePlatformAdmin(state) || venueHasPermission(v, PERMISSIONS.CAN_BE_INCLUDED_IN_SITE_ANALYTICS))
      .map(v => ({
        text: v.name,
        value: v.id,
      }));
  }
};
