import React, { useEffect, useState } from "react";
import { getVenueLicenceSettings } from "../../services/venueLicenceSettingsService";

const UpgradeDiscountBanner = ({ includeMargin = false }) => {
  const [bannerOverrideTitle, setBannerOverrideTitle] = useState();
  const [discountCodeInstructions, setDiscountCodeInstructions] = useState();

  useEffect(() => {
    const fetchSettings = async () => {
      const { result } = await getVenueLicenceSettings();
      let overrideTitle = result.find(value => value.key === "venue_licence_banner_override_title");
      let discountCodeAndInstructions = result.find(value => value.key === "venue_licence_discount_code_and_instructions");
      if (overrideTitle) {
        setBannerOverrideTitle(overrideTitle.value);
      }
      if (discountCodeAndInstructions) {
        setDiscountCodeInstructions(discountCodeAndInstructions.value);
      }
    };

    void fetchSettings();
  }, []);

  if (!bannerOverrideTitle || !discountCodeInstructions) {
    return null;
  }
  return bannerOverrideTitle && (
    <div className={`w-full p-4 block bg-blue text-white ${includeMargin ? "mt-8" : ""}`}>
      <h4>{bannerOverrideTitle}</h4>
      <span>{discountCodeInstructions}</span>
    </div>
  );
};

export default UpgradeDiscountBanner;
