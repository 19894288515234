import React from "react";

const gbpFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "GBP",
  // minimumFractionDigits: 0,
  // maximumFractionDigits: 0,
});

const outputFigure = (isGBP, formatter, value, src_cur_value) => {
  if (isGBP) {
    return formatter.format(Number(value).toFixed(2));
  }
  return (
    <span
      style={{ cursor: "help" }}
      title={"Approx " + gbpFormatter.format(Number(value).toFixed(2))}
    >
      {formatter.format(Number(src_cur_value).toFixed(2))}
    </span>
  );
};

const CommissionBreakdown = props => {
  const { currency = { currency_code: "GBP" } } = props;
  // let fetchCommissionValue = stepId => {
  //   switch (stepId) {
  //     case 2:
  //       return props.conferencePackagingCommissionTotal;
  //     case 3:
  //       return props.meetingRoomsCommissionTotal;
  //     case 4:
  //       return props.equipmentCommissionTotal;
  //     case 5:
  //       return props.supplementaryItemCommissionTotal;
  //     case 6:
  //       return props.accommodationCommissionTotal;
  //     case 10:
  //       return props.additionalCostingsCommissionTotal;
  //     default:
  //       break;
  //   }
  // };

  // let fetchTotalCommissionValue = () => {
  //   return (
  //     Number(props.conferencePackagingCommissionTotal.toFixed(2)) +
  //     Number(props.supplementaryItemCommissionTotal.toFixed(2)) +
  //     Number(props.equipmentCommissionTotal.toFixed(2)) +
  //     Number(props.meetingRoomsCommissionTotal.toFixed(2)) +
  //     Number(props.accommodationCommissionTotal.toFixed(2)) +
  //     Number(props.additionalCostingsCommissionTotal.toFixed(2))
  //   );
  // };

  // let fetchTotalCommissionValueGBP = () => {
  //   return (
  //     Number(props.conferencePackagingCommissionTotalGBP.toFixed(2)) +
  //     Number(props.supplementaryItemCommissionTotalGBP.toFixed(2)) +
  //     Number(props.equipmentCommissionTotalGBP.toFixed(2)) +
  //     Number(props.meetingRoomsCommissionTotalGBP.toFixed(2)) +
  //     Number(props.accommodationCommissionTotalGBP.toFixed(2)) +
  //     Number(props.additionalCostingsCommissionTotalGBP.toFixed(2))
  //   );
  // };

  // let final_commission_net = 0;
  // let final_commission_vat = 0;

  const isGBP = currency.currency_code === "GBP";

  return (
    <React.Fragment>
      <table
        className={
          "table-with-border table-responsive mb-2 table-bold-last-row"
        }
      >
        <thead>
          <tr>
            <td className={"uppercase font-heading text-xs p-2 pl-0 "}>Item</td>
            <td className={"uppercase font-heading text-xs p-2 text-right"}>
              Rate
            </td>
            <td className={"uppercase font-heading text-xs p-2 text-center"}>
              {isGBP ? `Plus VAT` : `Plus Tax`}
            </td>
            <td className={"uppercase font-heading text-xs p-2 text-center"}>
              Applied On
            </td>
            <td className={"uppercase font-heading text-xs p-2 text-right"}>
              Total Net
            </td>
            <td className={"uppercase font-heading text-xs p-2 text-right"}>
              Total Vat
            </td>
            <td className={"uppercase font-heading text-xs p-2 text-right"}>
              Total
            </td>
          </tr>
        </thead>
        <tbody>
          {props.commissionItems &&
            props.commissionItems
              .sort(function (commissionItemA, commissionItemB) {
                if (
                  commissionItemA.step.id !== 9 &&
                  commissionItemB.step.id !== 9
                ) {
                  return commissionItemA.step.id - commissionItemB.step.id;
                }
                if (commissionItemA.step.id === 9) {
                  return 1;
                }
                if (commissionItemB.step.id === 9) {
                  return -1;
                }
              })
              .map(
                ({
                  id,
                  applied_on,
                  include_vat,
                  rate,
                  step,

                  total_commission,
                  total_commission_net,
                  total_commission_vat,

                  total_commission_src_cur,
                  total_commission_net_src_cur,
                  total_commission_vat_src_cur,
                }) => {
                  // if (!issGBP) {
                  //     total_commission = total_commission_src_cur;
                  //     total_commission_net = total_commission_net_src_cur;
                  //     total_commision_vat = total_commission_vat_src_cur;
                  // }
                  // if (step.id !== 9) {
                  //   final_commission_net =
                  //     Number(final_commission_net) +
                  //     (include_vat
                  //       ? Number(fetchCommissionValue(step.id) / 1.2)
                  //       : fetchCommissionValue(step.id));
                  //   final_commission_vat =
                  //     Number(final_commission_vat) +
                  //     (include_vat
                  //       ? Number(
                  //           fetchCommissionValue(step.id) -
                  //             fetchCommissionValue(step.id) / 1.2
                  //         )
                  //       : 0);
                  // }

                  return (
                    <tr key={id}>
                      <td
                        className={`${
                          step.id === 9 ? "font-bold p-2 pl-0" : "p-2 pl-0"
                        }`}
                      >
                        {step.id === 9
                          ? "Total Commission Payable"
                          : step.id === 4
                          ? "Equipment"
                          : step.name}
                      </td>
                      <td className={"p-2 text-right"}>
                        {rate ? `${rate}%` : ""}
                      </td>
                      <td className={"p-2 text-center"}>
                        {step.id === 9 ? null : !isGBP ? (
                          <span title={"Never on international"}>*No*</span>
                        ) : include_vat ? (
                          "Yes"
                        ) : (
                          "No"
                        )}
                      </td>
                      <td className={"p-2 text-center"}>{applied_on}</td>
                      <td
                        className={`${
                          step.id === 9
                            ? "font-bold p-2 text-right"
                            : "p-2 text-right"
                        }`}
                      >
                        {outputFigure(
                          isGBP,
                          props.currencyFormatter,
                          total_commission_net,
                          total_commission_net_src_cur
                        )}
                      </td>
                      <td
                        className={`${
                          step.id === 9
                            ? "font-bold p-2 text-right"
                            : "p-2 text-right"
                        }`}
                      >
                        {outputFigure(
                          isGBP,
                          props.currencyFormatter,
                          total_commission_vat,
                          total_commission_vat_src_cur
                        )}
                      </td>
                      <td
                        className={`${
                          step.id === 9
                            ? "font-bold p-2 text-right"
                            : "p-2 text-right"
                        }`}
                      >
                        {outputFigure(
                          isGBP,
                          props.currencyFormatter,
                          total_commission,
                          total_commission_src_cur
                        )}
                      </td>
                    </tr>
                  );
                }
              )}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default CommissionBreakdown;
